import { Modal, ModalProps, Portal } from "@shopify/polaris";
interface ModalWrapperProps extends ModalProps {
  modalindex: number;
}

export function ModalWrapper(props: ModalWrapperProps) {
  const element = Modal(props);

  const zindex1 = 520 + (2 * props.modalindex) + 1;
  const zindex2 = 520 + (2 * props.modalindex) + 2;

  // this doozy wraps the portal children in a div with the z-index set 
  // per model so the backdrop of one overlays the modals below it
  if (element) {
    const { type: Type, props: { children, ...props }, key, ref } = element as ceElement;
    return <Type key={key} ref={ref} {...props}>
      {children.map((e: ceElement) => {
        if (e && e.type === Portal) {
          const { type: Type, props: { children, ...props }, key, ref } = e;
          return (
            <Type key={key} ref={ref} {...props}>
              <div style={{
                "--p-z-index-10": zindex1,
                "--p-z-index-11": zindex2
              } as any}>
                {children}
              </div>
            </Type>
          );
        } else {
          return e;
        }
      })}
    </Type>;

  } else {
    return null;
  }
}
type ceElement = { type: any; props: any; key: any; ref: any; };
