import { Injectable, Injector, NgZone } from '@angular/core';
import { Params, Router } from '@angular/router';
import {
  Branch,
  Customer,
  DataQueryGraph,
  FileUpload,
  is,
  Modes,
  ok,
  okNull,
  PaymentLedgerKeys,
  Prisma,
  Roles,
  schema,
  SPPI,
  SPPTypeTree,
  SRData,
  StringPathProxy as SPP,
  Transaction,
  truthy,
  ValueTree,
  ZoneGuard,
} from 'common';
import { UIService } from "./ui.service";
import { TABLE_NAMES, TYPE_NAMES, } from "common";
import { GroupDataDialog, GroupDialog } from './question-dialog';
import {
  FGCR,
  FileUploadState,
  QuestionCalendar,
  QuestionFileUpload,
  QuestionGroup,
  QuestionInputNumber,
  QuestionRender,
  QuestionSelect,
  QuestionSelectLabel,
  QuestionSimple,
  QuestionTable,
} from './question-base';
import { of } from 'rxjs';
import { DataListColumn, DataListIdFieldColumn } from './question-classes';
import { Validators } from '@angular/forms';
import { createElement, useMemo } from 'react';
import { useAngular, ReactInjectable, ReactInjectableSymbol, globalMessage, ModalHostEvents, emitGlobalRefresh } from 'react-utils';
import { TableRowDispatch } from "../tables/TableRowRedux";
import { TableArrays, TableViewClass } from '../tables/table-views';
import { ConfirmationService, MessageService } from 'react-utils';
import { createTransactionNotes } from './utils';
import { DataService } from './data.service';
import { dollarsToCents } from '../pages/page-table-list';
import { showTxnInfoModal } from './showTxnInfoModal';
// import { globalMessage } from '..';

export interface ClickEventId {
  action: "edit" | "delete";
  table: string;
  id: string;
  replaceUrl?: boolean;
}
export interface ClickEventParams {
  action: "list" | "add";
  table: string;
  params: Params;
  replaceUrl?: boolean;
}

export const QuestionHasManyEvent = [...[
  "PaymentLine", "InvoiceLine", "Transaction", "Rental",
  "OwnerLedger", "BranchLedger", "CentralLedger", "CustomerLedger",
  // "CustomerOtherContacts",
] as const] satisfies TABLE_NAMES[];

export const LedgerTables = [...["CustomerLedger", "CentralLedger", "DivisionLedger", "BranchLedger", "SalesTaxLedger", "OwnerLedger"] as const];

export const DISABLE_HYBRID_CUSTOMER_PAGE = false;

export type ClickEvent = ClickEventParams | ClickEventId;

type FQS = FormsQuestionService;

// export function useFormsQuestionClass() {
//   const { injector } = useAngular();
//   useMemo(() => new FormsQuestionClass(injector, injector.get(UIService)), [injector]);
// }
/** This class needs to be stateless because it is instantiated in more than one place */
@ReactInjectable()
@Injectable()
export class FormsQuestionService {

  stack = new Error();
  async checks() {
    // nothing here right now but we could use this later
    return true;
  }

  public ms: MessageService;
  public cs: ConfirmationService;
  public data: DataService;
  public router: Router;

  /** UIService depends on FormsQuestionService, so break the circular here. */
  #ui: UIService;
  get ui() {
    if (!this.#ui) this.#ui = this.injector.get(UIService);
    return this.#ui;
  }

  constructor(private injector: Injector) {
    this.router = injector.get(Router);
    this.data = injector.get(DataService);
    this.cs = injector.get(ConfirmationService);
    this.ms = injector.get(MessageService);
  }

  // subs = new Subscription();
  // OnDestroy() {
  //   this.subs.unsubscribe();
  //   this.subs = new Subscription();
  // }

  showList(table: TABLE_NAMES, params: Params, replaceUrl?: boolean) {
    this.onClickEvent({ action: "list", table, params, replaceUrl });
  }
  showAdd(table: TABLE_NAMES, params: Params, replaceUrl?: boolean) {
    this.onClickEvent({ action: "add", table, params, replaceUrl });
  }
  showEdit(table: TABLE_NAMES, id: string, replaceUrl?: boolean) {
    this.onClickEvent({ action: "edit", table, id, replaceUrl });
  }
  showDelete(table: TABLE_NAMES, id: string, replaceUrl?: boolean) {
    this.onClickEvent({ action: "delete", table, id, replaceUrl });
  }

  get onDialog() { return this.ui.onDialog; }
  // get onSave() { return this.ui.onSave; }

  // get showSave() { return this.ui.showSave; }
  // set showSave(v) { this.ui.showSave = v; }
  // get showSaveChange() { return this.ui.showSaveChange; }

  // get title() { return this.ui.title; }
  // set title(v) { this.ui.title = v; }
  // get titleChange() { return this.ui.titleChange; }


  async onClickEvent(e: ClickEvent) {
    console.error("onClickEvent", e);
    const { data } = this;
    const showVoidRestore = this.router.url.startsWith("/Customer/edit");

    const showTxnModal = async (where: Prisma.TransactionWhereInput) =>
      await showTxnInfoModal({ data, showVoidRestore, where });

    // eslint-disable-next-line no-debugger
    if (e.action === "add" && e.params) debugger; //we aren't supporting params anymore


    if (!is<TABLE_NAMES>(e.table, !!schema.tables[e.table])) return;
    else if (e.table === "InvoiceLine" && e.action === "edit") {
      return await showTxnModal({ invoiceLine: { id: e.id } });
    } else if (e.table === "PaymentLine" && e.action === "edit") {
      return await showTxnModal({ paymentLine: { id: e.id } });
    } else if (e.table === "CustomerLedger" && e.action === "edit") {
      return await showTxnModal({ customerLedgerLine: { id: e.id } });
    } else if (e.table === "BranchLedger" && e.action === "edit") {
      return await showTxnModal({ branchLedgerLine: { id: e.id } });
    } else if (e.table === "OwnerLedger" && e.action === "edit") {
      return await showTxnModal({ ownerLedgerLine: { id: e.id } });
    } else if (e.table === "CentralLedger" && e.action === "edit") {
      return await showTxnModal({ centralLedgerLine: { id: e.id } });
    } else if (e.table === "DivisionLedger" && e.action === "edit") {
      return await showTxnModal({ divisionLedgerLine: { id: e.id } });
    } else if (e.table === "SalesTaxLedger" && e.action === "edit") {
      // intentionally empty
    } else if (e.table === "Rental" && e.action === "edit") {
      const rental = await this.data.singleDataQuery(
        this.data.proxy.rental.findUnique({
          select: { customer: { select: { id: true } } },
          where: { id: e.id },
        })
      );
      if (rental?.customer?.id) {
        this.router.navigateByUrl(`Customer/edit/${rental.customer.id}`, { replaceUrl: !!e.replaceUrl });
      }
    } else switch (e.action) {
      case "list": this.router.navigateByUrl(`${e.table}/list`, { replaceUrl: !!e.replaceUrl }); break;
      case "add": this.router.navigateByUrl(`${e.table}/add`, { replaceUrl: !!e.replaceUrl }); break;
      case "edit": this.router.navigateByUrl(`${e.table}/edit/${e.id}`, { replaceUrl: !!e.replaceUrl }); break;
    }
    return undefined;
  }


  /** Finds a user and emits the id via onSaveSuccess. This does not tie into globalRefresh, so onSaveSuccess must be subscribed to. */
  async onClickFindUser() {

    const linesEmail = [
      "First direct the user to sign in using their Google or Microsoft account.",
      "Once they sign in, their email address will be displayed on their screen.",
      "Type their email address in the box below and press Next.",
    ];
    const linesCubes = [
      "Select the user's @getcubesstorage.com email address from the dropdown list and press Next.",
      "If the user is not in this list, then they have already been assigned a branch and should be in the user list."
    ]
    const users = await this.data.server.serverGetMicrosoftUserList({});

    users.sort((a, b) => a.mail && b.mail ? a.mail.localeCompare(b.mail) : a.mail ? 1 : -1);
    const tabEmail = "Email Address";
    const tabCubes = "Cubes Office User";

    const dialog = new GroupDialog(
      this.data,
      "User", "UPDATE",
      () => new QuestionGroup({
        __typename: "User",
        noHiddenAnimation: true,
        controls: {
          tab: new QuestionSelect({
            display: "buttons",
            options: of([tabEmail, tabCubes].map(value => ({ value }))),
            optionLabels: this.ui.makeBasicColumns([{ key: "value" }]),
            optionValue: "value",
            onlyfor: [],
            required: true,
          }),
          ...linesEmail.reduce((n, e, i) => (n[i] = new QuestionSimple("RawText", {
            calculate: () => e, clientSideOnly: true, onlyfor: [], fieldClass: " ",
          }), n), {} as any),
          ...linesCubes.reduce((n, e, i) => (n[i + linesEmail.length] = new QuestionSimple("RawText", {
            calculate: () => e, clientSideOnly: true, onlyfor: [], fieldClass: " ",
          }), n), {} as any),
          Email: new QuestionSimple("InputText", {
            title: "Confirm Email",
          }),
          Account: this.ui.QuestionSelectDropdown2D(["Email", "Name"], users.filter(e => e.displayName !== "getcubestorage.com").map(e => [e.mail, e.displayName]), {
            title: "Select Account",
          })
        }
      }),
      async () => { dialog.subs.unsubscribe(); },
      async function onLoad() {
        this.group?.controls.tab.form.valueChanges.subscribe((e: typeof tabCubes | typeof tabEmail) => {
          ok(this.group);
          this.group.controls.Email.hidden = e !== tabEmail;
          this.group.controls.Account.hidden = e !== tabCubes;
          linesEmail.forEach((_, i) => {
            ok(this.group);
            this.group.controls[i].hidden = e !== tabEmail;
          });
          linesCubes.forEach((_, i) => {
            ok(this.group);
            this.group.controls[i + linesEmail.length].hidden = e !== tabCubes;
          });
        });
        this.group?.controls.tab.form.setValue(tabEmail);
        return true;
      },
      async function onSave() {
        try {
          this.loading = true;
          const account = this.group?.controls.Account.form.value;
          const email = this.group?.controls.Email.form.value;
          if (email) {
            const user = await this.data.singleDataQuery(this.data.proxy.user.findUnique({
              where: { email },
              select: { id: true }
            }));
            if (!user) {
              alert("Email not found. Please check the address and try again.");
            } else {
              dialog.onSaveSuccess.emit({ table: "User", id: user.id });
            }
          } else if (account) {
            const user = await this.data.singleDataQuery(this.data.proxy.user.upsert({
              create: { email: account },
              update: {},
              where: { email: account },
              select: { id: true }
            }));
            if (!user) {
              alert("An error occurred importing the user.");
            } else {
              dialog.onSaveSuccess.emit({ table: "User", id: user.id });
            }
          } else {
            alert("Please enter an email or select a user")
          }
        } finally {
          this.loading = false;
        }
      },
    );
    dialog.okLabel = "Next";
    dialog.title = "Approve Branch User";
    this.onDialog.emit(dialog);
    await dialog.pageSetup(false);
    return dialog;
  }

  async onClickEditUser(userID: string) {
    const dialog = this.createGroupDataDialog("User", "UPDATE", () => {
      const group = this.ui.schema.UserUPDATE("UPDATE");
      if (this.data.status.isAdmin) {
        group.buttons.push({
          title: "Login As User",
          onlyClean: true,
          onClick: () => {
            ok(dialog.hasFresh());
            localStorage.setItem("cubes-creds-override", JSON.stringify({
              awsID: dialog.fresh.awsID,
              role: "web_user",
            }));
            location.reload();
          },
        });
      }

      group.subs.add(group.form.valueChanges.subscribe((e) => {
        ok(is<QuestionTable<any>>(group.controls.Branches, true));
        const { rows } = group.controls.Branches;
        if (rows.filter(e => !e.__groupdelete__).length !== 1) {
          group.controls.Branches.errortext = "User must have exactly one branch";
        } else {
          group.controls.Branches.errortext = undefined;
        }
      }));
      return group;
    });
    dialog.id = userID;
    dialog.showOkCancel = true;
    await dialog.pageSetup(false);
    console.log(dialog);
    dialog.onSaveSuccess.subscribe(async () => {
      await dialog.data.server.serverSyncUserPermissions({ userID });
      dialog.subs.unsubscribe();
      emitGlobalRefresh();
    });
  }

  async onClickEditUserOld(userID: string) {

    const dialog = new GroupDataDialog(
      this.data,
      "BranchUser", "UPDATE",
      () => {
        const group = this.ui.schema.BranchUser("UPDATE");
        group.controls.branch.title = "Branch";
        group.controls.branch.required = true;
        group.controls.user.title = "User";
        group.controls.user.preventUpdate = true;
        group.controls.user.readonly = true;

        return group;
      },
      async () => { dialog.subs.unsubscribe(); },
    );

    dialog.okLabel = "Ok";
    dialog.title = "Set User Branch";
    this.onDialog.emit(dialog);
    const { branchID, id } = await this.data.singleDataQuery(this.data.proxy.branchUser.findFirstOrThrow({ where: { userID }, select: { id: true, branchID: true } })) ?? {};
    ok(id);
    await dialog.pageSetup(false);
    if (!dialog.group) return;
    const setVal = (control: QuestionSelect<any, any>, id: string) => {
      control.form.setValue(control.optionsOuterTree[id]);
    }

    setVal(dialog.group.controls.user as any, userID);
    dialog.group.controls.user.readonly = true;
    if (branchID) setVal(dialog.group.controls.branch as any, branchID);
    dialog.group.controls.branch.required = true;
    dialog.onSaveSuccess.subscribe(async () => {
      await this.data.server.serverSyncUserPermissions({ userID }).catch(e => {
        console.error(e);
        this.toast("warn", "Error syncing user permissions.", "");
      });
      this.showEdit("User", id);
    })
  }



  async deleteTableItem(table: TABLE_NAMES, id: string | undefined) {

    if (!schema.tables[table])
      return this.ms.add({ severity: 'error', summary: "Failed", detail: `${table} is not a table.` });
    if (!id)
      return this.ms.add({ severity: 'error', summary: "Failed", detail: `ID missing, unable to delete record` });

    await this.deleteItemConfirmation(async () => {
      const req = { table, action: "delete" as const, arg: { where: { id } } };
      const [{ success }] = await this.data.singleDataQuery(req);
      this.toastDeletion(success);
      return success;
    });

  }


  /** we have to use a callback so we don't forget to check the result */
  deleteItemConfirmation<T>(onConfirm: () => Promise<T>): Promise<T | null> {
    return this.messageConfirmation('Delete Confirmation', 'Are you sure you want to delete this row?', onConfirm);
  }

  toastDeletion(success: boolean) {
    if (success) {
      this.toast('success', "Deleted", `Record deleted.`);
    } else {
      this.toast('error', "Failed", `Failed to delete record.`);
    }
  }


  toast(severity: 'success' | 'info' | 'warn' | 'error', summary: string, detail: string) {
    this.ms.add({ severity, summary, detail });
  }
  /** Resolves whether accept or reject is called. If accepted, returns the result of onConfirm; if rejected, returns null. */
  messageConfirmation<T>(header: string, message: string, onConfirm: () => Promise<T>) {
    return new Promise<T | null>((resolve, reject) => {
      console.log(header, message, this.cs);
      this.cs.confirm({
        message,
        header,
        // icon: 'pi pi-info-circle',
        accept: () => {
          Promise.resolve()
            .then(() => onConfirm())
            .then(resolve, reject);
        },
        reject: () => {
          resolve(null);
        }
      })

    });
  }
  /** Creates a basic GroupDialog with a DataQueryGraph request to load data */
  createBasicGroupDialog<N extends TYPE_NAMES, M extends Modes, G extends QuestionGroup<N, FGCR>>(
    type: N,
    mode: M,
    id: string,
    group: () => G,
    onSaveValue: (this: GroupDialog<N, M, G>, value: Record<keyof G["controls"], any>) => Promise<void>,
    emitDialog: boolean = true,
  ) {
    const dialog = new GroupDialog<N, M, G>(
      this.data,
      type, mode, group,
      async () => { dialog.subs.unsubscribe(); },
      async function getData(this: GroupDialog<N, M, G>) {
        okNull(this.group);
        const data = new DataQueryGraph(type, id, this.data.userRole);
        this.group.onLoadHook(data);
        await this.data.dataGraphQuery(data, "requests").catch(this.catchError);
        return true;
      },
      onSaveValue
    );

    if (emitDialog) this.onDialog.emit(dialog);
    return dialog;
  }

  createGroupDataDialog<T extends TABLE_NAMES, M extends Modes, G extends QuestionGroup<T, FGCR>>(table: T, mode: M, group: (() => G)) {
    const dialog = new GroupDataDialog(
      this.data,
      table, mode, group,
      async () => { dialog.subs.unsubscribe(); }
    );
    this.onDialog.emit(dialog);
    return dialog;
  }

  async onClickLedgerLine(where: Prisma.TransactionWhereInput, showVoidRestore: boolean) {
    ok
    return await showTxnInfoModal({
      data: this.data,
      where,
      showVoidRestore
    });
  }


  async onClickRecordPayout(table: string, id: string) {
    const prompt = async <T>(title: string, message: string, onConfirm: () => Promise<T>): Promise<T | null> => {
      return await this.messageConfirmation(title, message, async () => {
        return await onConfirm();
      });
    };

    if (typeof id !== "string") throw new Error("id not set");
    if (table !== "Branch" && table !== "Owner" && table !== "Division") return;
    const ledger = table;
    const otherID = id;
    type t1 = SPPTypeTree<Branch>
    const t1: t1 = {} as any;
    const arrayList =
      table === "Division" ? SPP<"Division">()(x => [x.Name.__]) :
        table === "Branch" ? SPP<"Branch">()(x => [x.DisplayName.__, x.division["Name"].__]) :
          table === "Owner" ? SPP<"Owner">()(x => [x.billing.Name.__]) :
            [];
    const dialog = this.createBasicGroupDialog(table, "CREATE", "", () => {
      // ledger, amount, date, otherID, PaymentStatus, Description
      const group = new QuestionGroup({
        __typename: ledger,
        controls: {
          otherID: this.ui.select<any, any>("CREATE", {
            "targetTable": table
          })({
            title: ledger,
            arrayList,
            required: true,
            default: otherID,
            readonly: true,
          }),
          date: new QuestionCalendar({
            showTime: false,
            showDate: true,
            format: "yyyy-MM-dd",
            required: true,
            title: "Date",
            default: "now",
          }),
          Description: new QuestionSimple("InputText", {
            title: "Description"
          }),
          amount: new QuestionInputNumber({
            inputMode: "currency",
            required: true,
            title: "Amount",
          }),
        }
      });

      group.controls.amount.validators.push(control =>
        (typeof control.value === "number" && control.value) ? null : { required: true }
      );

      return group;
    }, async function onSave(value) {

      const { amount, Description, date } = value;

      if (!amount) throw "Amount is required";

      console.log(amount);

      if (amount < 0) {
        if (!await prompt(
          "Are you sure you want to record a negative payout?",
          "Negative payouts are unusual. Are you sure you want to record a negative payout? "
          + "This means that the recipient sent you money instead of you sending them money.",
          () => Promise.resolve(true)
        )) return;
      }


      await this.data.server.serverRecordPayout({
        ledger,
        otherID,
        amount,
        date,
        Description,
        PaymentStatus: "Cleared"
      } satisfies SRData<"serverRecordPayout">)

      globalMessage.add({ severity: 'success', summary: "Payout Recorded", detail: "Payout recorded successfully!" });

      this.onSaveSuccess.emit({});
      this.onClose();
    });
    dialog.showDelete = false;
    dialog.title = `${ledger} Payout`;
    dialog.onSaveSuccess.subscribe(() => { emitGlobalRefresh(); });
    await dialog.pageSetup(false);
    okNull(dialog.group);
  }


  async onClickManageRentalPhotos(rentalID: string) {
    if (typeof rentalID !== "string") throw new Error("id not set");
    const state: FileUploadState = { files: [], names: [], thumbs: [] };
    const dialog = this.createBasicGroupDialog("FileUpload", "UPDATE", rentalID, () => {
      return new QuestionGroup({
        __typename: "FileUpload",
        controls: {
          Photos: this.QuestionRentalUploadPhotos({ rentalID, showGallery: true, state, onUploadComplete: async () => { dialog.pageSetup(true); } }),
        }
      });
    }, async function onSave() {
      this.subs.unsubscribe();
    }, true);
    // this is called by the Modal onClose handler
    dialog.onClose = async () => { };
    dialog.onClickCancel = async () => { dialog.subs.unsubscribe(); };
    dialog.showOkCancel = true;
    // dialog.onClickOk = async () => { dialog.subs.unsubscribe(); };
    // dialog.okLabel = "Upload";
    await dialog.pageSetup(false);
  }

  QuestionRentalUploadPhotos({
    rentalID,
    showGallery,
    showThumbs = true,
    state = { files: [], names: [], thumbs: [] },
    onUploadComplete
  }: {
    rentalID: string;
    showGallery?: boolean;
    showThumbs?: boolean;
    state?: FileUploadState;
    onUploadComplete?: () => Promise<void>;
  }): QuestionFileUpload {

    const control = new QuestionFileUpload({
      accept: "image/*",
      default: [],
      selectMultiple: true,
      onlyfor: [],
      showGallery,
      title: "Upload Photos",
      initState: state,
      onStateChange: (event) => {
        Object.assign(state, event);
        setTimeout(() => control.form.updateValueAndValidity());
      },
      registerUploads: async (uploads) => {
        return await this.data.server.serverRentalUploadPhotos({ rentalID, uploads });
      },
      completeUploads: async (uploads) => {
        const deletes = uploads.map((e, i) => !e.success && e.key).filter(truthy);
        if (deletes.length) await this.data.server3("serverRentalDeletePhotos")({ rentalID, deletes });

        state.files = state.files.filter((e, i) => !uploads[i].success);
        state.names = state.names.filter((e, i) => !uploads[i].success);
        state.thumbs = state.thumbs.filter((e, i) => !uploads[i].success);

        const uploaded = uploads.filter(e => e.success).length;
        let detail = `${uploaded} photo${uploaded === 1 ? '' : 's'} uploaded`;
        if (state.files.length)
          detail += `, ${state.files.length} left.`;
        else
          detail += ".";
        this.ms.add({ severity: 'success', summary: "Upload succeeded", detail });
        setTimeout(() => control.form.updateValueAndValidity());
        onUploadComplete?.();
      },
      deleteFromGallery: async (row: ValueTree<FileUpload>) => {
        if (!row.key) return false;
        await this.data.server.serverRentalDeletePhotos({ rentalID, deletes: [row.key] });
        onUploadComplete?.();
        // const res = await this.data.singleDataQuery(
        //   this.data.proxy.rental.findUnique({
        //     where: { id: rentalID },
        //     select: { Photos: true },
        //   })
        // );
        // if (!res || !res.Photos) return false;
        // control.form.setValue(res.Photos);
        return true;
      },
      onLoadHook: async (tag) => {
        // if (!showGallery) return;
        // const res = await tag.addPromise(this.data.proxy.rental.findUnique({ where: { id: rentalID }, select: { Photos: true } }));
        // if (!res || !res.Photos) return;
        // control.form.setValue(res.Photos);
        // control.thumbs = this.data.server.serverRentalGetPhotos({ rentalID });
        if (showThumbs)
          control.form.valueChanges.subscribe((e) => {
            control.thumbs = e ? this.data.server.serverGetPhotos({ keys: e.map(e => e.key ?? null) }) : undefined;
          });
      },
    });
    control.validators.push(e => {
      if (control.form !== e) return null;
      if (state.files.length) return { not_uploaded: "There are still files to be uploaded" };
      return null;
    });
    return control;
  }


  async onClickRecievePayment(customerID: string) {
    if (typeof customerID !== "string") throw new Error("id not set");

    const dialog = this.createBasicGroupDialog("PaymentLine", "CREATE", "", () => {

      const group = new QuestionGroup({
        __typename: "PaymentLine",
        controls: {
          text1: new QuestionSimple("RawText", {
            onlyfor: [],
            default: "Record a payment to a branch recieved from a customer. "
              + "This will apply that payment directly to the branch's account, reducing the amount they are owed. "
              + "They are expected to have deposited this money directly into their own account. "
            // + "If this is a dealer branch, it will be deducted from the branch ledger. " 
            // + "If this is a central branch, it will be deducted from the division ledger. "
          }),
          customerID: this.ui.select<Customer, any>("CREATE", {
            "targetTable": "Customer"
          })({
            arrayList: SPP<Customer>()(x => [x.billing.Name.__, x.Email.__]),
            required: true,
            default: customerID,
            readonly: true,
            // helptext: "The customer this payment is being applied to.",
            title: "Customer",
          }),
          // this should only be used by people with proper access to the payment gateway. 
          // Accounts which record payments recieved by mail should not have access to this.
          ...this.data.status.isArlen ? {
            central: this.ui.QuestionEnum("CREATE", false, [
              { value: "no", order: 0, title: "Branch" },
              { value: "yes", order: 1, title: "Central" },
            ], {
              title: "Charge Against",
              default: "no",
              required: true,
              // helptext: "Allows admins to record payments for central",
              // onChange: (val: { central: "yes" | "no"; }) => {
              //   const central = val.central === "yes";
              //   group.controls.branchID.hidden = central;
              //   group.controls.branchID.onlyfor = central ? [] : undefined;
              // }
            }),
          } : {},
          branchID: this.ui.select<Branch, any>("CREATE", {
            "targetTable": "Branch"
          })({
            title: "Branch Recieving Payment",
            arrayList: SPP<Branch>()(x => [x.DisplayName.__, x.BranchType.__, x.division.Name.__]),
            required: true,
            default: this.data.status.branchID,
            readonly: this.data.status.branchType !== "CENTRAL",
            helptext: this.data.status.branchType !== "CENTRAL"
              ? "Your branch (shown here) is the one this will be applied to."
              : "CHOOSE CAREFULLY: The amount will be applied to this branch's account balance."
          }),
          amount: new QuestionInputNumber({
            title: "Amount",
            inputMode: "currency",
            // currency: 'USD',
            required: true,
            helptext: this.data.status.branchType !== "CENTRAL" ? "" :
              "Only use this to record payments recieved by a branch. Do not use it to correct system errors. "
          }),
        }
      });

      group.controls.amount.validators.push(Validators.min(0));
      group.form.valueChanges.subscribe((e) => {
        if (group.controls.central) {
          const central = e.central === "yes";
          group.controls.branchID.hidden = central;
          group.controls.branchID.onlyfor = central ? [] : undefined;
        }
      });

      return group;
    }, async function (value) {

      // the form outputs the value expected by the server, so just pass it on
      await this.data.server.serverRecieveCustomerPayment(value as any);

      globalMessage.add({ severity: 'success', summary: "Payment Recorded", detail: "Payment recorded successfully!" });

      this.onSaveSuccess.emit({});

      this.subs.unsubscribe();

    });
    await dialog.pageSetup(false);
    okNull(dialog.group);
    dialog.showDelete = false;
    dialog.onSaveSuccess.subscribe(() => { emitGlobalRefresh(); });
  }
}


export class RowEditAccessor<T = any> {

  public _editGroups = new Map<any, QuestionGroup<any, FGCR>>();
  // private rowType: TYPE_NAMES;
  constructor(
    public idcol: DataListIdFieldColumn,
    public userRole: Roles,
    public pageSetupGroup: (mode: Modes) => QuestionGroup<any, FGCR>,
    public setState: TableRowDispatch,
    public allowDelete: boolean,
  ) {

  }

  getGroup(row: T): QuestionGroup<any, FGCR> {
    const res = this._editGroups.get(row);
    okNull(res);
    return res;
  }
  hasGroup(row: T) {
    return this._editGroups.has(row);
  }
  async onRowEditInit(row: any) {
    okNull(row);
    const group = this.pageSetupGroup(row === false ? "CREATE" : "UPDATE");
    this._editGroups.set(row, group);
    return true;
  }
  async onRowEditSave(row: Record<string, any>) {
    throw new Error("onRowEditSave not implemented");
  }
  onRowEditCancel(row: T): void {
    const group = this.getGroup(row);
    group.subs.unsubscribe();
    this._editGroups.delete(row);
  }
  deleteRow(row: any) {
    throw new Error("deleteRow not implemented");
  }
}

// @ReactInjectable()
// @Injectable()
// export class FormsQuestionService extends FormsQuestionClass {
//   constructor(
//     injector: Injector,
//   ) {
//     const ui = injector.get(UIService);
//     super(injector);
//   }

// }

