import { useAngular, useAsyncEffect } from "react-utils";
import { AppError, AppService } from "./service-app";
import { useCallback, useState } from "react";
import { Modal } from "@shopify/polaris/index";
// import { useNavigate } from "react-router";
import { useNavigate } from "./service-app";
import { AuthService } from "./service-auth";
import { is, ok } from "common";


export default function VerifyEmail() {

  const { get } = useAngular();
  const app = get(AppService);
  const auth = get(AuthService);

  // ok(is<never>(app.status, true));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [complete, setComplete] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  useAsyncEffect(async () => {
    if (complete || !app.loginToken || !app.loginToken.key || !app.loginToken.token || !app.loginToken.sig) return;
    ok(is<{ key: string, token: string, sig: string }>(app.loginToken, true));
    const [success, error] = await app.postAppState("verify-email", app.loginToken).try();
    if (success) app.customerVerified = JSON.stringify(app.loginToken);
    app.loginToken = undefined;
    await app.updateStatus();
    setComplete(true);
    setLoading(false);
    setSuccess(!error);
    setMessage(error?.message);
    if (error instanceof AppError) {
      if (error.body === "WRONG_CUSTOMER_ID") {
        setMessage("This link is for a different account. PLease make sure you are logged in with the correct email address.");
      }
      if (error.body === "LINK_EXPIRED") {
        setMessage("This link has expired. You can request a new link if the email hasn't been verified.");
      }
    }
  }, undefined, undefined, [app.loginToken?.key, app.loginToken?.token, app.loginToken?.sig]);

  const onClose = useCallback(() => { navigate("/billing"); }, [navigate]);

  // const isStorageAgreementSigned = !!app.status?.StorageAgreementCompleted;


  return <>
    <Modal
      open={true}
      onClose={onClose}
      title="Verify Email"
      primaryAction={{ content: 'Ok', onAction: onClose, loading: !complete }}
    >
      <Modal.Section>
        {!complete ? <p>Please wait...</p> : null}
        {complete && !success ? <p>
          An error occurred trying to process your request.
          Refresh the page or try the link again.
        </p> : null}
        {complete && !success ? <p>{message}</p> : null}
        {complete && success ? <>
          <p>Your email has been verified.</p>
        </> : null}
      </Modal.Section>
    </Modal>
  </>

}