import { ok } from "./graphql-declarator";
import { ENUM_NAMES, FIELD_NAMES, SCALAR_NAMES, TABLE_NAMES, TYPE_NAMES, Attributes as RootAttributes, uniquePrisma } from "./cubes-schema";
import { EnumMember, EnumType, GraphRoot, IsArray, RecordMember, RecordType, ScalarType, Wrapping } from "./graphql-declarator";
import { toRecord } from "./cubes-utils";
type Attributes<T = any> = RootAttributes<T>;
// export interface _Attributes<T = any> {
//   index: { sortKeyFields: string[] }[]
//   mapsTo: { name: string }[]
//   /** Decorates the child field containing the parent record referred to by its sibling foriegn key field */
//   belongsTo: {
//     /** 
//      * the name of the foriegn key field, if specified manually. 
//      * If not specified, it is created automatically by appended 
//      * "ID" to the name of this field. 
//      */
//     root: string,
//     /** if root is specified, additional foriegn keys fields */
//     fields?: string[],
//     onUpdate: string,
//     onDelete: string,
//     isRelation: boolean
//   }[]
//   hasOne: { relationName: string }[]
//   hasMany: {
//     indexName: string,
//     /** name of the parent key field on this table (should not be an array) */
//     fields: string[],
//     /** name of the foriegn key field on the other table */
//     remote: string
//   }[]
//   field: RootAttributes["field"]
//   indexPrisma: RootAttributes["indexPrisma"]
//   /** allows a unique index on multiple fields */
//   uniquePrisma: { fields: string[] }[]
//   relationPrisma: ({
//     remote?: undefined,
//     fields: string[],
//     references: string[],
//     onUpdate: boolean,
//     onDelete: boolean
//   } | {
//     fields?: undefined,
//     references?: undefined,
//     remote: string
//   })[]
//   primaryKey: {
//     /** `@default(dbgenerated(${JSON.stringify(pk.default)}))` */
//     default: any
//   }[]
// }


export interface IScalarClass {
  name: SCALAR_NAMES;
  attributes: Attributes<"SCALAR">;
}
export class ScalarClass {
  static fromGQL(item: ScalarType<any>): IScalarClass {
    return {
      name: item.__name as SCALAR_NAMES,
      attributes: item.__typeAttributes() as Attributes,
    };
  }

  name: SCALAR_NAMES;
  attributes: Attributes<"SCALAR">;
  constructor(init: IScalarClass, private schema: DataSchema) {
    this.name = init.name;
    this.attributes = init.attributes;
    Object.defineProperty(this, 'schema', { enumerable: false });
  }
}

export interface IEnumClass {
  name: ENUM_NAMES;
  attributes: Attributes<"ENUM">;
  optionsArray: IEnumValueClass[];
}

export class EnumClass {
  static fromGQL(item: EnumType): IEnumClass {
    return {
      name: item.__name as ENUM_NAMES,
      attributes: item.__typeAttributes() as Attributes,
      optionsArray: item.__listMembers().map(([k, e], i) => EnumValueClass.fromGQL(k, e, i)),
    };
  }

  name: string;
  attributes: Attributes<"ENUM">;
  optionsArray: EnumValueClass[];
  options: Record<string, EnumValueClass>;

  constructor(init: IEnumClass, private schema: DataSchema) {
    this.name = init.name;
    this.attributes = init.attributes;
    this.optionsArray = init.optionsArray.map(e => new EnumValueClass(e, this.schema));
    if (this.optionsArray.length === 0) throw new Error(`Enum ${this.name} has no values`);
    this.options = toRecord("value", this.optionsArray) as any;
    Object.defineProperty(this, 'options', { enumerable: false });
    Object.defineProperty(this, 'schema', { enumerable: false });
  }
}

export interface IEnumValueClass {
  value: string
  order: number
  label?: string;
  attributes: Attributes<"ENUM_VALUE">;
}

export class EnumValueClass {
  static fromGQL(value: string, item: EnumMember, order: number): IEnumValueClass {
    const attributes = item.__fieldAttributes() as Attributes;
    return { value, order, attributes, };
  }

  value: string;
  order: number;
  attributes: Attributes<"ENUM_VALUE">;
  label?: string;
  constructor(init: IEnumValueClass, private schema: DataSchema) {
    this.value = init.value;
    this.order = init.order;
    this.attributes = init.attributes;
    this.label = this.attributes.field?.first()?.title;
    Object.defineProperty(this, 'label', { enumerable: false });
    Object.defineProperty(this, 'schema', { enumerable: false });
  }

  isValue<V extends FieldNames>(value: V): this is FieldClass<V> {
    return value === this.value as any;
  }
}

export type FieldNames = SCALAR_NAMES | ENUM_NAMES | TYPE_NAMES;
export type FieldTypes = "scalar" | "enum" | "record" | "table" | "model";

export type FieldTypeForName<N> =
  N extends SCALAR_NAMES ? "scalar" :
  N extends ENUM_NAMES ? "enum" :
  N extends TABLE_NAMES ? "table" :
  N extends Exclude<TYPE_NAMES, TABLE_NAMES> ? "record" :
  N extends TYPE_NAMES ? "model" :
  never;
export type FieldNameForType<T> =
  T extends "scalar" ? SCALAR_NAMES :
  T extends "enum" ? ENUM_NAMES :
  T extends "table" ? TABLE_NAMES :
  T extends "record" ? Exclude<TYPE_NAMES, TABLE_NAMES> :
  T extends "model" ? TYPE_NAMES :
  never;

export interface IModelClass<T extends TYPE_NAMES = TYPE_NAMES> {
  name: T;
  type: "record" | "table";
  attributes: Attributes<"OBJECT">;
  fieldsArray: IFieldClass[];
}

export class ModelClass<T extends TYPE_NAMES = TYPE_NAMES> {
  static fromGQL<T extends TYPE_NAMES = TYPE_NAMES>(item: RecordType): IModelClass<T> {
    return {
      name: item.__name as any,
      type: item.__type,
      attributes: item.__typeAttributes() as Attributes,
      fieldsArray: item.__listMembers().map(([key, item], order) => {
        return FieldClass.fromGQL(key, item, order);
      })
    };
  }
  name: T;
  type: "record" | "table";
  fields: Record<string, FieldClass>;
  attributes: Attributes<"OBJECT">;
  fieldsArray;
  constructor(init: IModelClass<T>, private schema: DataSchema) {
    this.name = init.name;
    this.type = init.type;
    this.attributes = init.attributes;
    this.fieldsArray = init.fieldsArray.map(e => new FieldClass(e, schema));
    this.fields = toRecord("key", this.fieldsArray);
    Object.defineProperty(this, 'fields', { enumerable: false });
    Object.defineProperty(this, 'schema', { enumerable: false });
  }

  isType<T extends "table" | "record" | "model">(type: T): this is ModelClass<FieldNameForType<T>> {
    if (type === "model") return true;
    return type === this.type as any;
  }

}

export interface IFieldClass<NAME extends FieldNames = FieldNames> {
  key: string
  order: number
  name: NAME
  type: FieldTypeForName<NAME>
  isArray: boolean
  isRequired: boolean
  attributes: Attributes
  fieldOptions:
  NAME extends "ScalarJSON" ? { type: keyof PrismaJson.AllTypes } :
  NAME extends "ScalarDateTime" ? { format: string } :
  NAME extends "ScalarDate" ? { format: string } :
  NAME extends ENUM_NAMES ? { $useDefault: boolean } :
  Record<string, any>
}


export class FieldClass<NAME extends FieldNames = FieldNames> {
  static fromGQL<NAME extends FieldNames = FieldNames>(
    key: string, item: RecordMember<any>, order: number
  ): IFieldClass<NAME> {
    const isArray = item instanceof IsArray;
    if (item instanceof IsArray) item = item.__wrap;
    return {
      key,
      order,
      name: item.__name as any,
      type: item.__type as any,
      isArray,
      isRequired: item.__required,
      attributes: item.__fieldAttributes() as Attributes,
      fieldOptions: Object.fromEntries(item.__listOptions()) as any,
    };
  }

  key
  order
  name
  type
  isArray
  isRequired
  attributes
  fieldOptions
  constructor(init: IFieldClass<NAME>, private schema: DataSchema) {
    this.key = init.key;
    this.order = init.order;
    this.name = init.name;
    this.type = init.type;
    this.isArray = init.isArray;
    this.isRequired = init.isRequired;
    this.attributes = init.attributes;
    this.fieldOptions = init.fieldOptions;

    if (this.isName("ScalarJSON")) {
      ok(this.fieldOptions.type, "ScalarJSON must have a type");
    }
    if (this.isName("ScalarDateTime")) {
      ok(this.fieldOptions.format, "ScalarDateTime must have a format");
    }
    if (this.isName("ScalarDate")) {
      ok(this.fieldOptions.format, "ScalarDate must have a format");
    }
    if (this.isType("enum")) {
      ok(typeof this.fieldOptions.$useDefault === "boolean", "Enum must have a $useDefault option");
    }

    Object.defineProperty(this, 'schema', { enumerable: false });
  }

  isName<N extends FieldNames>(name: N): this is FieldClass<N> {
    return name === this.name as any;
  }
  isType<T extends FieldTypes>(type: T): this is FieldClass<FieldNameForType<T>> {
    if (type === "model") return this.type === "record" || this.type === "table";
    return type === this.type as any;
  }
  get(): NAME extends TYPE_NAMES ? ModelClass<NAME> : NAME extends ENUM_NAMES ? EnumClass : unknown {
    switch (this.type) {
      case "table": return this.schema.tables[this.name] as any;
      case "record": return this.schema.records[this.name] as any;
      case "enum": return this.schema.enums[this.name as ENUM_NAMES] as any;
      case "scalar": return this.schema.scalars[this.name as SCALAR_NAMES] as any;
    }
    throw new Error("unknown type " + this.type);
  }

}

export class DataSchema {
  static fromGQL(root: GraphRoot) {
    const types = Object.values(root.types).map(e => ModelClass.fromGQL(e));
    const enums = Object.entries(root.enums).map(([k, e]) => EnumClass.fromGQL(e));
    const scals = Object.entries(root.scals).map(([k, e]) => ScalarClass.fromGQL(e));
    return new DataSchema(types, enums, scals);
  }
  public tables: Record<string, ModelClass<TABLE_NAMES>>
  public records: Record<string, ModelClass<Exclude<TYPE_NAMES, TABLE_NAMES>>>
  public enums: Record<ENUM_NAMES, EnumClass>
  public scalars: Record<SCALAR_NAMES, ScalarClass>
  constructor(
    types: IModelClass[],
    enums: IEnumClass[],
    scals: IScalarClass[]
  ) {
    const [records, tables] = types.partition(e => e.type === "table");
    this.tables = toRecord("name", tables.map(e => new ModelClass(e, this))) as any;
    this.records = toRecord("name", records.map(e => new ModelClass(e, this))) as any;
    this.enums = toRecord("name", enums.map(e => new EnumClass(e, this))) as any;
    this.scalars = toRecord("name", scals.map(e => new ScalarClass(e, this))) as any;
  }

  anyModel(type: TYPE_NAMES) {
    let item = this.tables[type] || this.records[type];
    if (!item) { debugger; throw new Error(); }
    return item;
  }
  typepath(name: string, path: string): ModelClass<any>[] {
    let target = this.tables[name] || this.records[name];
    return path.split("/").reduce((n, f, j, p) => {
      let last = n.last();
      if (!last) throw new Error("last not found");
      if (last instanceof Wrapping) last = last.__wrap;

      let field = last?.fields[f];

      if (!field) {
        debugger;
        throw new Error(`List item ${path} tried to descend into undefined ${f} after index ${j}`);
      } else if (field.isType("scalar")) {
        if (j < p.length - 1) throw (`List item ${path} tried to descend into Scalar type ${field.name} after index ${j}`);
      } else if (field.isType("record") || field.isType("table")) {
        n.push(this.tables[field.name] || this.records[field.name]);
      } else if (field.isType("enum")) {
        if (j < p.length - 1) throw (`List item ${path} tried to descend into Enum type ${field.name} after index ${j}`);
      } else {
        debugger;
        throw new Error(`List item ${path} tried to descend into unknown type ${field.name} after index ${j}`)
      }
      return n;
    }, [target] as ModelClass<any>[]);
  }
  typepaths(name: string, list: string[]): ModelClass<any>[][] {
    return list.map(e => this.typepath(name, e));
  }
  getpath(name: string, path: string): FieldClass {
    const key = path.split("/").last();
    const item = this.typepath(name, path).last();
    if (!key || !item) throw new Error("invalid path " + path);
    return item.fields[key];
  }
}



declare global {
  namespace NodeJS {
    interface ProcessEnv {
      SERVERSIDE: string;
      NEWSCHEMA: string;
    }
  }
}