import { Box, ButtonGroup, InlineError, ModalProps, Text, Toast } from "@shopify/polaris";
import { truthy } from "common";
import { Dialog, FormsQuestionService, ModelHostCounter } from "../utils";
// import { Confirmation, ConfirmationService, Message, MessageService } from "primeng/api";
import { useState } from "react";
import { ButtonAwait, useAngular, useObservable, useSubscribe, useSubscriptionClosed } from "react-utils";
import { map, NEVER, Subscription } from "rxjs";
import { QuestionForm } from "../components/QuestionForm";
import { DeleteIcon } from "@shopify/polaris-icons";
import { ModalWrapper } from "react-utils";

import {
  Message,
  MessageService,
  Confirmation,
  ConfirmationService,
  ModalHostEvents
} from "./services";

interface Confirmation2 extends Confirmation {
  [ModelHostCounter]: number;
  subs: Subscription;
}

interface Dialog2 extends Dialog<any, any, any> {
  [ModelHostCounter]: number;
  subs: Subscription;
}
interface Modal2 {
  [ModelHostCounter]: number;
  subs: Subscription;
  (): ModalProps;
}


export const counter = { current: 1 };
export function ModalHost() {
  const { fq, cs, ms } = useAngular().getAll({
    fq: FormsQuestionService,
    cs: ConfirmationService,
    ms: MessageService,
  });

  const [confirmArr, setConfirmArr] = useState<Confirmation2[]>([]);

  const [dialogArr, setDialogArr] = useState<Dialog2[]>([]);
  const [modalArr, setModalArr] = useState<Modal2[]>([]);

  useSubscribe(() => cs.requireConfirmationObserver.pipe(map((confirm: any) => {
    confirm[ModelHostCounter] = counter.current++;
    confirm.subs = new Subscription();
    return confirm as Confirmation2;
  })).subscribe(async (confirm) => {
    setConfirmArr(current => [...current, confirm]);
    confirm.subs.add(() => {
      setTimeout(() => {
        setConfirmArr(current => current.filter(d => d !== confirm));
      }, 500);
    });
  }), [cs.requireConfirmationObserver]);



  useSubscribe(() => fq.onDialog.pipe(map((dialog: any) => {
    dialog[ModelHostCounter] = counter.current++;
    return dialog as Dialog2;
  })).subscribe((dialog) => {
    setDialogArr(current => [...current, dialog]);
    dialog.subs.add(() => {
      setTimeout(() => {
        setDialogArr(current => current.filter(d => d !== dialog));
      }, 500);
    });
  }));

  useSubscribe(() => ModalHostEvents.pipe(map((modal: any) => {
    modal[ModelHostCounter] = counter.current++;
    modal.subs = new Subscription();
    return modal as Modal2;
  })).subscribe(modal => {
    setModalArr(current => [...current, modal]);
    modal.subs.add(() => {
      setTimeout(() => {
        setModalArr(current => current.filter(d => d !== modal));
      }, 500);
    });
  }));

  const [messageArr, setMessageArr] = useState<Message2[]>([]);
  useSubscribe(() => ms.messageObserver.pipe(map((e) => {
    const messages: Message[] = Array.isArray(e) ? e : [e];
    messages.forEach((message: any) => {
      // messages do not use the model index. we just set the z-index to 1000 in styles.
      // message[ModelHostCounter] = counter.current++;
      message.subs = new Subscription();
    });
    return messages as Message2[];
  })).subscribe(async (messages) => {
    setMessageArr(current => [...current, ...messages]);
    messages.forEach(message => {
      // alert(message.summary + ": " + message.detail);
      // console.log(message);
      message.subs.add(() => {
        setTimeout(() => {
          setMessageArr(current => current.filter(d => d !== message));
        }, 500);
      });
    })
  }), [ms.messageObserver]);

  // reset the counter everytime all models are cleared
  if (!confirmArr.length && !dialogArr.length && !modalArr.length && !messageArr.length)
    counter.current = 1;



  return (<>
    {modalArr.map((modal, i) => <ModalComp key={modal[ModelHostCounter]} modal={modal}></ModalComp>)}
    {dialogArr.map((dialog, i) => <DialogComp key={dialog[ModelHostCounter]} dialog={dialog} />)}
    {confirmArr.map((confirm, i) => <ConfirmComp key={confirm[ModelHostCounter]} confirm={confirm} />)}
    {messageArr.map((message, i) => <ToastComp key={message[ModelHostCounter]} message={message} />)}
  </>);

}



function ModalComp({ modal }: { modal: Modal2; }) {
  const { children, ...props } = modal();
  return <ModalWrapper modalindex={modal[ModelHostCounter]} {...props}>{children}</ModalWrapper>;
}

function ConfirmComp({ confirm }: { confirm: Confirmation2; }) {
  const closed = useSubscriptionClosed(confirm.subs);

  const { accept, acceptEvent, acceptIcon, acceptLabel, acceptVisible } = confirm;
  const { reject, rejectEvent, rejectIcon, rejectLabel, rejectVisible } = confirm;
  const { subs, allowFormClose, header, message, } = confirm;

  const acceptButton = {
    content: acceptLabel || "Yes",
    onAction: () => {
      subs.unsubscribe();
      accept?.();
      acceptEvent?.emit();
    },
    icon: acceptIcon,
  };

  const rejectButton = {
    content: rejectLabel || "No",
    onAction: () => {
      subs.unsubscribe();
      reject?.();
      rejectEvent?.emit();
    },
    icon: rejectIcon,
  };

  const buttons = [
    acceptVisible !== false && acceptButton,
    rejectVisible !== false && rejectButton
  ].filter(truthy);




  const onClose = allowFormClose ? () => {
    subs.unsubscribe();
    reject?.();
    rejectEvent?.emit();
  } : () => { }

  return (

    <ModalWrapper
      modalindex={confirm[ModelHostCounter]}
      key={"confirm" + confirm[ModelHostCounter]}
      open={!closed}
      onClose={onClose}
      title={header}
      limitHeight
      primaryAction={buttons[0]}
      secondaryActions={buttons.slice(1)}
    >
      <Box padding="400">
        <Text as="p">{message}</Text>
      </Box>
    </ModalWrapper>
  );
}

function DialogComp({ dialog }: { dialog: Dialog<any, any, any>; }) {
  const closed = useSubscriptionClosed(dialog.subs);
  const loading = useObservable(dialog.loadingChange, dialog.loading);
  const status = useObservable(dialog.group?.form.statusChanges || NEVER, dialog.group?.form.status);
  useObservable(dialog.onRefreshRender);
  const [okLoading, setOkLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  const okAction = {
    content: dialog.okLabel || "Ok",
    disabled: status !== "VALID",
    loading: okLoading,
    onAction: async () => {
      setOkLoading(true);
      try { await dialog.onClickOk(); }
      finally { setOkLoading(false); }
    }
  };

  const cancelAction = {
    content: dialog.cancelLabel || "Cancel",
    loading: cancelLoading,
    onAction: async () => {
      setCancelLoading(true);
      try { await dialog.onClickCancel(); }
      finally { setCancelLoading(false); }
    },

  };

  return (
    <ModalWrapper
      modalindex={dialog[ModelHostCounter] as number}
      open={!closed}
      onClose={() => { dialog.onClose(); }}
      title={dialog.title || dialog.group?.__typename || ""}
      limitHeight
      size={dialog.modalSize}
      loading={loading || !dialog.group}
      footer={
        <ButtonGroup>
          {dialog.showDelete && <ButtonAwait
            icon={DeleteIcon}
            variant="secondary"
            tone="critical"
            onClick={() => dialog.onClickDelete()}
          >
            {dialog.deleteLabel}
          </ButtonAwait>}
        </ButtonGroup>
      }
      primaryAction={dialog.showOkCancel ? okAction : cancelAction}
      secondaryActions={dialog.showOkCancel ? [cancelAction] : []}
    >
      <Box padding="300" maxWidth={dialog.maxWidth} minHeight={dialog.height}>
        {dialog.group && <QuestionForm group={dialog.group} mode={dialog.mode} />}
        {dialog.error && <InlineError fieldID={"modal-error-" + dialog[ModelHostCounter]} message={dialog.error} />}
      </Box>
    </ModalWrapper >
  );
}

interface Message2 extends Message {
  [ModelHostCounter]: number;
  subs: Subscription;
}

function ToastComp({ message }: { message: Message2; }) {
  return <Toast
    //@ts-expect-error
    modalindex={message[ModelHostCounter]}
    content={message.summary ?? ""}
    duration={5000}
    onDismiss={() => message.subs.unsubscribe()}
  />;
}