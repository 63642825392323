// import {
//   authPostgres, forms, model, fieldComposite, field, hasMany, index, manyToMany, lookup, hasOne, belongsTo, ConfirmType, prejoin, primaryKey, ledger,
// } from "./cubes-amazon-old";
import { ValueTree, PathString, GQLMember, EnumMember } from "./graphql-declarator";
import { EnumType, IsArray, RecordType, ScalarType, MemberKeys, truthy } from "./graphql-declarator";
import { ModelBooleanInput, ModelEnumInput, ModelNumberInput, ModelStringInput } from "./cubes-helpers";
import { GenericPathProxy, PermissionNames,  RealType, RealTypeTree, SPPF, SPPI, TypeTreeWithID, ViewListFunc } from "./cubes-utils";
import { childFilterArg, parentFilterArg } from "./cubes-schema-from-prisma";
import * as s from "./cubes-schema";
import { rls as rlsAttr, GeocodeAddress, Transaction, ID, String, select, rowlevelsecurity, uniquePrisma, privOpts, belongsToRoot, DirectiveInput, ScalarJSON, CubesDinero, ConfirmType, ScalarPhone, PostgresTablePermissions, PostgresUserRoles, QuickbooksChartOfAccountsCategory, ReferentialActions, ScalarDateTime, TABLE_NAMES, } from "./cubes-schema";
import { authPostgres, belongsTo, field, fieldComposite, forms, hasMany, hasOne, index, ledger, lookup, manyToMany, model, prejoin, primaryKey } from "./cubes-attributes";
import { plural, singular } from "pluralize";
import { root } from "./cubes-index";

/** Array of privelage scopes from most important to least important */
export const PrivScope = ["app", "web_admin", "web_user"] as const;
export type PrivScope = typeof PrivScope[number];

export function index_makeName(table: string, index: string) { return `Query${table}_${index}` as const; }


function priv(
  tableType: "server" | "ledger" | "record",
  normalDelete: boolean,
  readScope: "user" | "admin",
  writeScope: "user" | "admin"
): PermissionsOptions {
  return { tableType, normalDelete, readScope: `web_${readScope}`, writeScope: `web_${writeScope}` };
}

interface TableRLS {
  table: { perm: PermissionNames };
  group: { perm: PermissionNames, value: string };
  row: { perm: PermissionNames, value: string };
}


export class PermissionsBuilder {

  privs: Record<"app" | MemberKeys<PostgresUserRoles>, MemberKeys<PostgresTablePermissions>[]> = {
    app: [],
    web_admin: [],
    web_user: [],
    // web_central_admin: [],
    // web_central_user: [],
    // web_dealer_admin: [],
    // web_dealer_user: [],
  };
  // read!: Record<"app" | MemberKeys<PostgresUserRoles>, MemberKeys<PostgresTablePermissions>[]>;
  constructor(private opts: PermissionsOptions) {
    if (this.opts.tableType === "custom") {
      this.customType();
      return;
    }

    this.setRead();

    switch (this.opts.tableType) {
      case "ledger":
        this.privs.app.push("INSERT");
        if (this.opts.normalDelete) throw new Error("Normal delete is not supported for ledger type");
        break;
      case "record":
        this.privs.app.push("INSERT", "UPDATE");
        if (this.opts.normalDelete) this.privs.app.push("DELETE");
        break;
      case "server":
        this.setWrite();
        break;
      default:
        throw new Error("never");
    }

  }

  authPostgresAttrs() {
    return Object.entries(this.privs).map(([role, privelages]) => ({ role, privelages }));
  }

  customType() {

    const levels = ["none", "app", "web_admin", "web_user"] as const;
    const actions = ["SELECT", "INSERT", "UPDATE", "DELETE"] as const;
    levels.forEach(level => {
      if (level === "none") return;
      if (this.opts.tableType !== "custom") throw new Error("never");
      actions.forEach(action => {
        if (this.opts.tableType !== "custom") throw new Error("never");
        if (levels.indexOf(level) <= levels.indexOf(this.opts[action])) this.privs[level].push(action);
      })

    });
  }

  private setRead() {
    if (this.opts.tableType === "custom") throw new Error("setRead can only be called for server, record, ledger tableTypes");
    const arr = PrivScope;
    for (let i = 0; i < arr.length; i++) {
      const scope = arr[i];
      this.setReadScope(scope);
      // the PrivScope array is ordered from highest to lowest
      if (this.opts.readScope === scope) return;
    }
  }
  private setReadScope(scope: PrivScope) {
    this.setReadLevel(scope);
  }
  private setReadLevel(level: keyof PermissionsBuilder["privs"]) {
    this.privs[level].push("SELECT");
  }

  private setWrite() {
    if (this.opts.tableType !== "server")
      throw new Error("setWrite can only be called for server tableType because DELETE always gets set for the app user");
    for (let i = 0; i < PrivScope.length; i++) {
      const scope = PrivScope[i];
      this.setWriteScope(scope);
      // the PrivScope array is ordered from highest to lowest
      if (this.opts.writeScope === scope) return;
    }
  }
  private setWriteScope(scope: PrivScope) {
    if (this.opts.tableType !== "server")
      throw new Error("setWrite can only be called for server tableType because DELETE always gets set for the app user");
    this.setWriteLevel(scope);
  }
  private setWriteLevel(level: keyof PermissionsBuilder["privs"]) {
    if (this.opts.tableType !== "server")
      throw new Error("setWrite can only be called for server tableType because DELETE always gets set for the app user");
    this.privs[level].push("INSERT", "UPDATE");
    if (this.opts.normalDelete || level === "app") {
      this.privs[level].push("DELETE");
    }
  }

}

export class TableSecurity<H extends TableType> {
  /** This can't be accessed in the constructor */
  public table!: TABLE_NAMES;
  public rls: RowLevelSecurityOptions<H> | undefined;
  constructor(
    public priv: PermissionsOptions,
    // rls: RowLevelSecurityOptions<H> | undefined,
    public rls2?: (x: TypeTreeWithID<H>) => rlsAttr["attr"],
  ) {
    this.privs = new PermissionsBuilder(priv);
  }
  privs;
}



export const permissions = {
  rls_tables: {
    tableType: "server",
    normalDelete: true,
    readScope: "web_user",
    writeScope: "app",
  },
  user_table: {
    tableType: "server",
    normalDelete: false,
    readScope: "web_user",
    writeScope: "web_admin",
  },
  super_admin: {
    tableType: "server",
    normalDelete: true,
    readScope: "web_user",
    writeScope: "web_admin",
  },
  server_nodelete_user_admin: {
    tableType: "server",
    normalDelete: false,
    readScope: "web_user",
    writeScope: "web_admin",
  },
  branch_admin: {
    tableType: "server",
    normalDelete: false,
    readScope: "web_user",
    writeScope: "web_user",
  },
  branch_table: {
    tableType: "server",
    normalDelete: false,
    readScope: "web_user",
    writeScope: "web_user",
  },
  branch_user_join: {
    tableType: "server",
    normalDelete: true,
    readScope: "web_user",
    writeScope: "web_admin",
  },
  owner_table: {
    tableType: "server",
    normalDelete: false,
    readScope: "web_user",
    writeScope: "web_user",
  },
  // {
  //   app: ['DELETE', 'INSERT', 'REFERENCES', 'SELECT', 'TRIGGER', 'TRUNCATE', 'UPDATE'],
  //   web_admin: ['DELETE', 'INSERT', 'SELECT', 'UPDATE'],
  //   web_central_admin: ['SELECT'],
  //   web_central_user: ['SELECT'],
  //   web_dealer_admin: ['SELECT'],
  //   web_dealer_user: ['SELECT'],
  // },
  client_central_admin: {
    tableType: "server",
    normalDelete: true,
    readScope: "web_user",
    writeScope: "web_user",
  },
  client_central: {
    tableType: "server",
    normalDelete: true,
    readScope: "web_user",
    writeScope: "web_user",
  },
  server_delete_user_user: {
    tableType: "server",
    normalDelete: true,
    readScope: "web_user",
    writeScope: "web_user",
  },
  client_dealer: {
    tableType: "server",
    normalDelete: true,
    readScope: "web_user",
    writeScope: "web_user",
  },
  ledger_central_admin: {
    tableType: "ledger",
    normalDelete: false,
    readScope: "web_user",
  },
  ledger_central: {
    tableType: "ledger",
    normalDelete: false,
    readScope: "web_user",
  },
  ledger_dealer_admin: {
    tableType: "ledger",
    normalDelete: false,
    readScope: "web_user",
  },
  ledger: {
    tableType: "ledger",
    normalDelete: false,
    readScope: "web_user",
  },
  ledger_private: {
    tableType: "ledger",
    normalDelete: false,
    readScope: "web_admin",
  },
  record: {
    tableType: "record",
    normalDelete: false,
    readScope: "web_user",
  },
  record_delete: {
    tableType: "record",
    normalDelete: true,
    readScope: "web_user",
  },
  private: {
    tableType: "server",
    normalDelete: true,
    readScope: "web_admin",
    writeScope: "app",
  },
} satisfies Record<string, PermissionsOptions>



export const rls = {
  
  /** Allow access to specific branches or branch groups */
  Branch: new TableSecurity<s.Branch>(permissions.super_admin, x => ({
    remoteTable: s.Branch.name,
    groups: x.Groups.groupID.__,
    rows: x.id.__,
  })),
  /** Allow access to specific branches or branch groups */
  BranchBillingInfo: new TableSecurity<s.BranchBillingInfo>(permissions.branch_table, x => ({
    remoteTable: s.Branch.name,
    groups: x.branch.Groups.groupID.__,
    rows: x.branchID.__,
  })),
  /** Allow access to specific branches or branch groups */
  BranchDiscountLedger: new TableSecurity<s.BranchDiscountLedger>(permissions.ledger, x => {
    x["branch"].Groups.groupID.__;
    x.branch.Groups.groupID.__;
    return ({
      remoteTable: s.Branch.name,
      groups: x.branch.Groups.groupID.__,
      rows: x.branchID.__,
    });
  }),
  /** Allow access to specific branches or branch groups */
  BranchLedger: new TableSecurity<s.BranchLedger>(permissions.ledger),
  /** Allow access to specific branches or branch groups */
  SalesTaxLedger: new TableSecurity<s.SalesTaxLedger>(permissions.ledger),
  BranchContactInfo: new TableSecurity(priv("server", true, "user", "admin")),
  /** Allow access to specific branches or branch groups */
  BranchLocations: new TableSecurity<s.BranchLocations>(permissions.branch_table, x => ({
    remoteTable: s.Branch.name,
    groups: x.branch.Groups.groupID.__,
    rows: x.branchID.__,
  })),
  /** Allow access to specific branches or branch groups */
  BranchUnitTypeMarkup: new TableSecurity<s.BranchUnitTypeMarkup>(permissions.branch_table, x => ({
    remoteTable: s.Branch.name,
    groups: x.branch.Groups.groupID.__,
    rows: x.branchID.__,
  })),

  /** Allow access to specific branches or branch groups */
  BranchUser: new TableSecurity<s.BranchUser>(permissions.branch_user_join, x => ({
    remoteTable: s.Branch.name,
    groups: x.branch.Groups.groupID.__,
    rows: x.branchID.__,
  })),

  CentralDiscountLedger: new TableSecurity<s.CentralDiscountLedger>(permissions.ledger),
  CentralLedger: new TableSecurity<s.CentralLedger>(permissions.ledger),

  Division: new TableSecurity<s.Division>(permissions.super_admin, x => ({
    remoteTable: s.Division.name,
    groups: x.Groups.groupID.__,
    rows: x.id.__,
  })),

  DivisionBillingInfo: new TableSecurity<s.DivisionBillingInfo>(permissions.client_central, x => ({
    remoteTable: s.Division.name,
    groups: x.division.Groups.groupID.__,
    rows: x.divisionID.__,
  })),

  DivisionDiscountLedger: new TableSecurity<s.DivisionDiscountLedger>(permissions.ledger, x => ({
    remoteTable: s.Division.name,
    groups: x.division.Groups.groupID.__,
    rows: x.divisionID.__,
  })),

  DivisionLedger: new TableSecurity<s.DivisionLedger>(permissions.ledger),
  /** Allow access to specific branches or branch groups */
  OwnerUser: new TableSecurity<s.OwnerUser>(permissions.branch_user_join, x => ({
    remoteTable: s.Owner.name,
    groups: x.owner.Groups.groupID.__,
    rows: x.ownerID.__,
  })),
  Owner: new TableSecurity<s.Owner>(permissions.super_admin, x => ({
    remoteTable: s.Owner.name,
    groups: x.Groups.groupID.__,
    rows: x.id.__,
  })),
  // dealers are not allowed direct access to owner information
  OwnerBillingInfo: new TableSecurity<s.OwnerBillingInfo>(permissions.owner_table, x => ({
    remoteTable: s.Owner.name,
    groups: x.owner.Groups.groupID.__,
    rows: x.ownerID.__,
  })),
  OwnerLedger: new TableSecurity<s.OwnerLedger>(permissions.ledger),

  Customer: new TableSecurity<s.Customer>(permissions.record_delete, x => ({
    remoteTable: s.Customer.name,
    groups: x.Groups.groupID.__,
    rows: x.id.__,
  })),
  CustomerBillingInfo: new TableSecurity<s.CustomerBillingInfo>(permissions.client_dealer, x => ({
    remoteTable: s.Customer.name,
    groups: x.customer.Groups.groupID.__,
    rows: x.customer.id.__,
  })),
  CustomerOtherContacts: new TableSecurity<s.CustomerOtherContacts>(permissions.client_dealer, x => ({
    remoteTable: s.Customer.name,
    groups: x.customer.Groups.groupID.__,
    rows: x.customerID.__,
  })),
  CustomerPaymentInfo: new TableSecurity<s.CustomerPaymentInfo>(permissions.private, x => ({
    remoteTable: s.Customer.name,
    groups: x.customer.Groups.groupID.__,
    rows: x.customerID.__,
  })),
  BranchPaymentInfo: new TableSecurity<s.BranchPaymentInfo>(permissions.private, x => ({
    remoteTable: s.Branch.name,
    groups: x.branch.Groups.groupID.__,
    rows: x.branchID.__,
  })),
  OwnerPaymentInfo: new TableSecurity<s.OwnerPaymentInfo>(permissions.private, x => ({
    remoteTable: s.Owner.name,
    groups: x.owner.Groups.groupID.__,
    rows: x.ownerID.__,
  })),
  DivisionPaymentInfo: new TableSecurity<s.DivisionPaymentInfo>(permissions.private, x => ({
    remoteTable: s.Division.name,
    groups: x.division.Groups.groupID.__,
    rows: x.divisionID.__,
  })),
  CustomerLedger: new TableSecurity<s.CustomerLedger>(permissions.ledger, x => ({
    remoteTable: s.Customer.name,
    groups: x.customer.Groups.groupID.__,
    rows: x.customerID.__,
  })),

  Rental: new TableSecurity<s.Rental>(permissions.record, x => ({
    remoteTable: s.Customer.name,
    groups: x.customer.Groups.groupID.__,
    rows: x.customerID.__,
  })),
  // {
  //   tableType: "server",
  //   normalDelete: false,
  //   readScope: "web_user",
  //   writeScope: "web_user",
  // }
  Unit: new TableSecurity<s.Unit>({
    tableType: "custom",
    INSERT: "web_admin",
    UPDATE: "web_user",
    DELETE: "web_admin",
    SELECT: "web_user",
  }, x => ({
    remoteTable: s.Unit.name,
    groups: x.Groups.groupID.__,
    rows: x.id.__,
  })),

  Transaction: new TableSecurity<s.Transaction>(permissions.record),
  // Invoice: new TableSecurity(permissions.record),
  InvoiceLine: new TableSecurity(permissions.record),
  // InvoicePayment: new TableSecurity({ tableType: "custom", INSERT: "app", UPDATE: "app", DELETE: "app", SELECT: "web_user" }),
  PaymentLine: new TableSecurity(permissions.record),
  AutopayAttempt: new TableSecurity(permissions.record),
  GreenpayTransaction: new TableSecurity({ tableType: "custom", INSERT: "app", UPDATE: "app", DELETE: "app", SELECT: "web_user" }),
  // CustomerPayment: new TableSecurity({ tableType: "record", normalDelete: true, readScope: "web_user", writeScope: "app" }, {}),

  // these tables are special, because RLS queries are run as the same user as the query itself
  User: new TableSecurity<s.User>(permissions.user_table),
  UserPermission: new TableSecurity<s.UserPermission>(permissions.rls_tables),
  Permission: new TableSecurity(permissions.rls_tables),

  // utility tables that do not use RLS
  Item: new TableSecurity(priv("server", true, "user", "admin")),
  UnitType: new TableSecurity(priv("server", true, "user", "admin")),
  Promotion: new TableSecurity(priv("server", true, "user", "admin")),
  NoticeTemplate: new TableSecurity(priv("server", true, "user", "admin")),

  // currently abandoned tables
  Movement: new TableSecurity(priv("server", true, "user", "admin")),


} satisfies Record<Exclude<s.TABLE_NAMES, `${string}Group` | `${string}GroupChild`>, TableSecurity<any>>;

// an error here probably means check if the table was added to RootTypes;
Object.keys(rls).forEach(k => { rls[k].table = k; });

export function __TableDirectives<H extends TableType>(opts: {
  heading?: string;
  plural?: string;
  singular?: string;
  filter?: SPPF<H>;
  search?: (PathString<ValueTree<H>>)[];
  sec: TableSecurity<H>,
  extraForms?: Record<string, readonly MemberKeys<H>[]>
}) {
  const { heading, singular: singularName, plural: pluralName, filter, search, extraForms, sec } = opts;
  if (!sec.table)
    throw new Error("TableSecurity.table must be set");
  //@ts-ignore
  if (rls[sec.table] !== sec)
    throw new Error(`${sec.table} must be set to the TableSecurity instance`);
  return [
    new fieldComposite({
      name: __TableDirectives.name,
      opts: JSON.stringify([opts]),
    }),
    new model<H>({}),
    new forms({
      singular: singularName || heading && singular(heading),
      plural: pluralName || heading && plural(heading),
      filter,
      extraForms,
    }),
    new privOpts(sec.priv),
    ...sec.privs.authPostgresAttrs().map(e => new authPostgres(e)),
    ...sec.rls ? [new rowlevelsecurity(sec.rls)] : [],
    ...sec.rls2 ? [new rlsAttr(GenericPathProxy(sec.rls2))] : []
  ];
}

export type rls_security =
  | "rls_security.user_id"
  | "rls_security.user_level"
  | "rls_security.branch_id"
  | "rls_security.branch_type"
  | "rls_security.division_id";
export type rls_layer_1_inner<H extends TableType, K extends MemberKeys<H>> = RealType<H[K]> extends TableType ? {
  [L in MemberKeys<RealType<H[K]>>]?: rls_security | MemberKeys<H>;
} : rls_security;
export type rls_layer_1<H extends TableType> = {
  [K in MemberKeys<H>]?: H[K] extends Member<any, true> ? rls_security | rls_layer_1_inner<H, K> : rls_layer_1_inner<H, K>
};

export const grouptest: TableSecurity<any>[] = [];

interface RowLevelSecurityOptions<H extends TableType> {
  web_central_admin?: false | rls_layer_1<H>,
  web_central_user?: false | rls_layer_1<H>,
  web_dealer_admin?: false | rls_layer_1<H>,
  web_dealer_user?: false | rls_layer_1<H>,
}





export type PermissionsOptions = PermissionsOptionsReadonly | PermissionsOptionsWritable | PermissionOptionsCustom;
export interface PermissionOptionsBase {
  /** 
   * Determines the permissions for the `app` user. 
   * - ledger: Only INSERT is allowed and only for `app` user. `writeScope` is ignored.
   * - record: Only INSERT and UPDATE are allowed and only for the `app` user. `writeScope` is ignored. 
   * - server: Normal INSERT, UPDATE, DELETE are allowed for the `app` user. writeScope is applied to web users.
   */
  tableType: "server" | "record" | "ledger" | "custom";
}
export interface PermissionOptionsCustom {
  tableType: "custom";
  SELECT: PrivScope | "none";
  INSERT: PrivScope | "none";
  UPDATE: PrivScope | "none";
  DELETE: PrivScope | "none";
}
export interface PermissionsOptionsBuilder {
  tableType: "server" | "record" | "ledger";
  /** 
   * Whether rows can be deleted by write level users. 
   * 
   * - The `app` user can never delete rows from `ledger` tableType.
   * - The `app` user, if this is `true`, can delete rows for the `record` tableType.
   * - The `app` user can always delete rows for `server` tableType.
   */
  normalDelete: boolean;
  /** 
   * The user must be at least this wide in scope to make SELECT queries. Doesn't apply to `app` and `web_admin` scopes.
   */
  readScope: PrivScope;
  /** 
   * The user must be at least this wide in scope to make INSERT, UPDATE, DELETE queries. Doesn't apply to `app` and `web_admin` scopes.
   * 
   * This only applies if `tableType` is `server`.
   */
  writeScope?: PrivScope;

}

export interface PermissionsOptionsWritable extends PermissionsOptionsBuilder {
  tableType: "server"
  /** 
   * The user must be at least this wide in scope to make INSERT, UPDATE, DELETE queries. Doesn't apply to `app` and `web_admin` scopes.
   * 
   * This only applies if `tableType` is `server`.
   */
  writeScope: PrivScope;
}


export interface PermissionsOptionsReadonly extends PermissionsOptionsBuilder {
  tableType: "record" | "ledger";
}

export type AccessorDecorator<T> = (
  target: any,
  propertyKey: string | symbol,
  descriptor: TypedPropertyDescriptor<T>
) => void;

export type Filter<T> =
  T extends RecordType ? { [K in MemberKeys<T> & keyof T]: Filter<T[K]>; } :
  T extends EnumType ? ModelEnumInput<MemberKeys<T>> :
  T extends ScalarType<infer X extends string> ? ModelStringInput :
  T extends ScalarType<infer X extends number> ? ModelNumberInput :
  T extends ScalarType<infer X extends boolean> ? ModelBooleanInput :
  never;
type Complete<T> = {
  [P in keyof Required<T>]: Pick<T, P> extends Required<Pick<T, P>> ? T[P] : T[P] | undefined;
};
type Equal<A, B, C> = B extends A & B ? C : never;
const DEPRECATE = false;
const Log: ClassDecorator = (target) => {
  console.log(target);
};
function ok(a: any, message?: string): asserts a {
  if ((a ?? null) === null) throw new Error(message || "Assertion Failed");
}
function manyToManyField<T extends RecordType>(
  ctor: { new(): T; },
  relationName: string,
  fieldVal: field<T, any>["input"] = {}
) {
  if (!(ctor.prototype instanceof TableType))
    throw new Error("manyToMany must be declared on a table type");
  return new IsArray(
    new Member(
      ctor,
      false,
      new manyToMany({
        relationName,
      }),
      new field<T, any>(fieldVal),
      new fieldComposite({
        name: manyToManyField.name,
        opts: JSON.stringify([relationName, fieldVal]),
        target: ctor.name,
      })
    )
  );
}



export interface hasManyFieldOptions<T extends TableType, H extends TableType> {
  /** ID Field from the child, usually "`${H}ID`" */
  childField?: MemberKeys<T>;
  arrayList?: field<T, H>["input"]["arrayList"]; // SPPF<T>;
  arraySort?: field<T, H>["input"]["arraySort"]; // SPPF<T>;
  arrayWhere?: field<T, H>["input"]["arrayWhere"]; // SPPF<T>;
  extraPairs?: [MemberKeys<H>, MemberKeys<T>][];
  title?: string;
  helptext?: string;
  /** type used for add/edit form for this hasMany field */
  detailType?: string;
  prefillPossibleValuesFor?: ValueTree<prejoin<T, H>>["otherField"];
  hidden?: boolean;
  preventCreate?: boolean;
  preventUpdate?: boolean;
  /** true by default */
  preventDelete?: boolean;
  clientSideLoad?: any;
  lookup?: lookup<T, H>["attr"];
}

export function hasManyField<T extends TableType, H extends TableType>(
  ctor: { new(): T; },
  opts: hasManyFieldOptions<T, H>
): IsArray<Member<T, false>> {

  if (!(ctor.prototype instanceof TableType))
    throw new Error("hasMany must be declared on a table type");

  const {
    childField, arrayList, arraySort, arrayWhere,
    hidden, title, helptext, detailType,
    clientSideLoad, prefillPossibleValuesFor,
    preventCreate, preventUpdate, preventDelete,
    lookup: lookupAttr,
  } = opts;
  return new IsArray(
    new Member(
      ctor,
      false,
      new hasMany<T, H>({
        // AWS Amplify: the id field is absolutely essential, otherwise hasMany does not respect the field name
        // of the child index (there's no apparent rationale for that, but that's what it does)
        fields: ["id" as MemberKeys<H>],
        // the indexName refers to which child index this hasMany will pull from
        indexName: childField ? index_makeName(ctor.name, childField) : undefined,
        remote: childField,
        detailType,
      }),
      ...(lookupAttr ? [new lookup<T, H>(lookupAttr)] : []),
      new field<T, H>({
        arrayList,
        arraySort,
        arrayWhere,
        title,
        helptext,
        extraGetPaths: x => ["id" as SPPI],
        hidden,
        preventCreate,
        preventUpdate,
        preventDelete: preventDelete ?? true,
        clientSideLoad,
        onlyfor: [],

      }),
      ...(prefillPossibleValuesFor ? [new prejoin<T, H>({
        otherField: prefillPossibleValuesFor,
        childField,
        prefill: true,
      })] : []),
      new fieldComposite({
        name: hasManyField.name,
        opts: JSON.stringify([opts]),
        target: ctor.name,
      })
    )
  );
}
export function hasOneField<T extends RecordType, H extends RecordType>(
  ctor: { new(): T; },
  host: { new(): H; },
  target: MemberKeys<T>,
  fieldOpts: field<T, H>["input"] = {}
) {
  if (!(ctor.prototype instanceof TableType))
    throw new Error("hasOneField must be declared on a table type");

  return new Member(
    ctor,
    false,
    new hasOne({
      remote: target,
      fields: [target],
      relationName: target ? index_makeName(ctor.name, target) : undefined,
    }),
    new field<T, H>(fieldOpts),
    new fieldComposite({
      name: hasOneField.name,
      opts: JSON.stringify([
        target,
        fieldOpts,
      ]),
      target: ctor.name,
      host: host.name,
    })
  );

}
export function belongsToField<T extends TableType, H extends TableType>(
  ctor: { new(): T; },
  host: { new(): H; },
  belongsToVal: ValueTree<belongsTo<H>>,
  fieldVal: field<T, H>["input"]
) {

  return new Member(
    ctor,
    false,
    new belongsTo<H>(belongsToVal),
    new field<T, H>({
      ...fieldVal,
      onlyfor: fieldVal.arrayList || !fieldVal.unique ? [] : fieldVal.onlyfor,
      extraGetPaths: x => belongsToVal.root ? [`../${belongsToVal.root}` as SPPI] : []
    }),
    new fieldComposite({
      name: belongsToField.name,
      opts: JSON.stringify([belongsToVal, fieldVal,]),
      host: host.name,
      target: ctor.name,
    })
  );

}
export function forKeySet<T extends TableType, H extends TableType, K extends string>(
  ctor: { new(): T; },
  host: { new(): H; },
  key: K,
  required: boolean,
  field: field<T, H>["input"]
) {
  // if a key is unique, then there is a one-to-one relationship
  const member = belongsToField(ctor, host, {
    root: key + "ID" as any,
  }, field);

  const memberID = selectID(ctor, host, {
    belongsTo: key as any,
    required,
    field
  });

  return {
    [key]: member,
    [`${key}ID`]: memberID
  } as {
    [P in K]: typeof member;
  } & {
      [P in `${K}ID`]: typeof memberID;
    };

}
// export function new field<T extends RecordMember<any>, H extends RecordType>() { return new field<T, H>(); }
interface childFilterConst<T extends TableType, H extends RecordType, K extends MemberKeys<T>> {
  filterWith?: never;
  filterConst: childFilterArg<T, K>;
  onlyfor?: MemberKeys<ConfirmType>[];
}
interface childFilterWith<T extends TableType, H extends RecordType, K extends MemberKeys<T>> {
  filterConst?: never;
  filterWith: MemberKeys<H> | `../${string}` | `${MemberKeys<H>}/${string}`;
  onlyfor?: MemberKeys<ConfirmType>[];
}
interface childMemberOpts<T extends TableType, H extends RecordType, F extends MemberKeys<T>> {
  filterWith?: { [K in F]?: childFilterWith<T, H, K>; };
  filterWhere?: parentFilterArg<T>[]
  required?: boolean;
  field: field<T, H>["input"];
  optionValue?: MemberKeys<T>;
  belongsTo?: MemberKeys<H>;
}

interface childOpts<T extends TableType, H extends TableType, F extends MemberKeys<T>> {
  required?: boolean;
  optionValue?: MemberKeys<T>;
  relationScalar: MemberKeys<H>;
  onUpdate?: MemberKeys<ReferentialActions>
  onDelete?: MemberKeys<ReferentialActions>
  field: field<T, H>["input"]
  filterWith?: { [K in F]?: childFilterWith<T, H, K>; };
  filterWhere?: parentFilterArg<T>[]
}


export function selectID<T extends TableType, H extends RecordType, F extends MemberKeys<T>>(
  ctor: { new(): T; }, host: { new(): H; }, opts: childMemberOpts<T, H, F>
) {
  return new ID(opts.required, ...forKeyDirectives<T, H, F>(ctor, host, opts, selectID.name));
}

export function selectRelationID(required: boolean, addIndex: boolean) {
  return new ID(required, new field({ hidden: true, onlyfor: [] }), addIndex ? new index({}) : undefined)
}

export function selectRelation<T extends TableType, H extends TableType, F extends MemberKeys<T>>(
  ctor: { new(): T; },
  host: { new(): H; },
  opts: {
    // arrayList?: SPPF<T>
    required?: boolean
    optionValue?: MemberKeys<T>
    /** Restrict will be set if this is falsy */
    onDelete?: MemberKeys<ReferentialActions>
    /** Restrict will be set if this is falsy */
    onUpdate?: MemberKeys<ReferentialActions>
    field: field<T, H>["input"],
    index?: index<H>["attr"],
    filterWith?: { [K in F]?: childFilterWith<T, H, K>; }
    filterWhere?: parentFilterArg<T>[]
  }
): Member<T, true> {

  const { field: fieldVal, filterWith, optionValue, onDelete, onUpdate, required, index: indexVal } = opts;
  return new Member<T, true>(ctor, required,
    fieldVal.arrayList && new lookup<T, H>({
      targetTable: ctor.name,
      optionValue,
      optionFilterWith: filterWith && Object.entries(filterWith).map(([filterThis, e]) => (e && filterThis && {
        // we stringify nulls too since they are valid filter values
        // filterConst: e.filterConst,
        filterWith: e.filterWith,
        filterThis: filterThis,
        onlyfor: e.onlyfor
      })).filter(truthy),
      onRelation: true
    }),
    new belongsTo({
      onDelete: onDelete || "Restrict",
      onUpdate: onUpdate || "Restrict",
      isRelation: true
    }),
    new field<T, H>(fieldVal),
    indexVal && new index<H>(indexVal),
  );
}


export function uniqueFormRelation<T extends TableType, H extends TableType, F extends MemberKeys<T>>(
  ctor: { new(): T; },
  host: { new(): H; },
  required: boolean,
  fieldVal: field<T, H>["input"]
) {

  return new Member(
    ctor,
    required,
    new belongsTo({}),
    new field<T, H>({
      ...fieldVal,
      unique: true,
      hidden: false,
    })
  );
}

export function forKeyString<T extends TableType, H extends TableType, F extends MemberKeys<T>>(
  ctor: { new(): T; }, host: { new(): H; }, opts: childOpts<T, H, F>
): String;
export function forKeyString<T extends TableType, H extends RecordType, F extends MemberKeys<T>>(
  ctor: { new(): T; }, host: { new(): H; }, opts: childMemberOpts<T, H, F>
): String;
export function forKeyString<T extends TableType, H extends RecordType, F extends MemberKeys<T>>(
  ctor: { new(): T; }, host: { new(): H; }, opts: childMemberOpts<T, H, F>
) {
  return new String(opts.required, ...forKeyDirectives<T, H, F>(ctor, host, opts, forKeyString.name));
}
export function forKeyDirectives<T extends TableType, H extends TableType, F extends MemberKeys<T>>(
  ctor: { new(): T; }, host: { new(): H; }, opts: childOpts<T, H, F>, name: string
): DirectiveInput<"FIELD_DEFINITION">[];
export function forKeyDirectives<T extends TableType, H extends RecordType, F extends MemberKeys<T>>(
  ctor: { new(): T; }, host: { new(): H; }, opts: childMemberOpts<T, H, F>, name: string
): DirectiveInput<"FIELD_DEFINITION">[];
export function forKeyDirectives<T extends TableType, H extends RecordType, F extends MemberKeys<T>>(
  ctor: { new(): T; }, host: { new(): H; }, opts: childMemberOpts<T, H, F>, name: string
) {
  if (!(ctor.prototype instanceof TableType))
    throw new Error("childField must be declared on a table type");

  const {
    field: fieldVal, filterWith, filterWhere, optionValue, belongsTo,
  } = opts;

  return [
    new fieldComposite({
      name: name,
      opts: JSON.stringify([opts]),
      host: host.name,
      target: ctor.name,
      directive: true,
    }),
    fieldVal.arrayList && new lookup<T, H>({
      targetTable: ctor.name,
      optionValue,
      optionFilterWith: filterWith && Object.entries(filterWith).map(([filterThis, e]) => {
        return e && filterThis && {
          // we stringify nulls too since they are valid filter values
          // filterConst: e.filterConst,
          filterWith: e.filterWith,
          filterThis: filterThis,
          onlyfor: e.onlyfor
        };
      }).filter(truthy),
      optionFilterWhere: filterWhere
    }),
    belongsTo && new select<T, H>({
      forReadonly: { hostTable: host.name, relation: belongsTo }
    }),
    belongsTo && new belongsToRoot<H>({
      rootFor: belongsTo
    }),
    // addIndex && new index<H>({ sortKeyFields }),
    new field<T, H>({
      ...fieldVal,
      onlyfor: !fieldVal.arrayList && fieldVal.unique ? [] : fieldVal.onlyfor,
    })
  ].filter(truthy);
}


export abstract class TableType extends RecordType {

  override __type = "table" as const;
  id = new ID(true, new field({ hidden: true, onlyfor: ['UPDATE', 'DELETE'] }), new primaryKey({}));
  createdAt: ScalarDateTime = new ScalarDateTime(undefined, true, new field({ hidden: true, onlyfor: [] }));
  updatedAt: ScalarDateTime = new ScalarDateTime(undefined, true, new field({ hidden: true, onlyfor: [] }));
  extra;
  constructor(public __extraType: keyof PrismaJson.ExtraValues = "TableType_extra", ...args: CP<typeof RecordType>) {
    super(...args);
    this.extra = new ScalarJSON(__extraType, false, new field({ hidden: true }));
    this.createdAt.__skipSchema = true;
    this.updatedAt.__skipSchema = true;
  }

}

export abstract class Group<T extends TableType> extends TableType {


  constructor(child: new () => GroupChild<T>, displayList: ViewListFunc<GroupChild<T>>, ...args: CP<typeof TableType>) {
    super(...args);
    const table = this.constructor.name;
    //@ts-ignore
    const sec = rls[table] = new TableSecurity(permissions.super_admin);
    //@ts-ignore
    sec.table = table;
    this.__typeDirectivesAdd(
      ...__TableDirectives<GroupChild<T>>({ sec, }),
    );
    this.children = hasManyField(child, {
      childField: "group",
      arrayList: displayList,
      title: "Children",
    });
  }

  DisplayName: String = new String(true, new field({ unique: true, title: "Group Name" }));

  GroupType: String = new String(true, new field({ title: "Group Type" }))

  children: IsArray<Member<GroupChild<T>, false>>;

}

export abstract class GroupChild<T extends TableType> extends TableType {

  constructor(parent: { new(): Group<T> }, target: { new(): T }, selectList: ViewListFunc<T>, ...args: CP<typeof TableType>) {
    super(...args);
    const table = this.constructor.name;
    //@ts-ignore
    const sec = rls[table] = new TableSecurity(permissions.super_admin);
    //@ts-ignore
    sec.table = table;
    this.__typeDirectivesAdd(
      ...__TableDirectives<GroupChild<T>>({ sec }),
      new uniquePrisma<GroupChild<T>>({ fields: ["group", "child"] }),
    );
    this.group = selectRelation(parent, this.constructor as new () => this, {
      required: true,
      onDelete: "Cascade",
      field: { arrayList: x => [x.DisplayName.__, x.GroupType.__], hidden: true },
    });
    this.child = selectRelation(target, this.constructor as new () => this, {
      required: true,
      onDelete: "Cascade",
      field: { arrayList: selectList, hidden: true },
    });

  }

  group: Member<Group<T>, true>

  child: Member<T, true>

}

// class BranchGroup1 extends Group {
//   constructor() {
//     super();
//   }
// }


/** String Path Proxy */
function SPP<T extends ScalarType<any>>(name: { new(): T }): T;
/** String Path Proxy */
function SPP<T extends EnumType>(name: { new(): T }): T;
/** String Path Proxy */
function SPP<T extends RecordType>(name: { new(): T }): RealTypeTree<T, "">;
function SPP(name: {}, path: string[]): unknown;
function SPP(name: unknown, path: string[] = []): any {
  return new Proxy<any>({}, {
    get(t: any, p: string, r) {
      if (p === "__") return path.join("/");
      return SPP({}, [...path, p]);
    }
  });
}

export class BaseLedger extends TableType {

  __LedgerDirectivesOther = <T extends BaseLedger, O extends TableType>(base: { new(): O }, fields: SPPF<T>, AccountType: MemberKeys<QuickbooksChartOfAccountsCategory>, sec: TableSecurity<T>, isDiscount: boolean = false) => [
    ...__TableDirectives<T>({ sec }),
    new ledger({ AccountType }),
  ];

  __LedgerDirectivesCentral = <T extends BaseLedger>(AccountType: MemberKeys<QuickbooksChartOfAccountsCategory>, sec: TableSecurity<T>, isDiscount: boolean = false) => [
    ...__TableDirectives<T>({ sec }),
    new ledger({ AccountType }),

  ];

  line = selectRelation(Transaction, BaseLedger, {
    onDelete: "Cascade",
    required: true,
    field: { preventUpdate: true },
    index: {}
  });

  // line = belongsToField(Transaction, BaseLedger, { root: "lineID", onDelete: "Cascade", onUpdate: "Restrict", }, {});
  // lineID = selectID(Transaction, BaseLedger, { required: true, field: { title: "Payout", preventUpdate: true }, belongsTo: "line" });

  Amount = new CubesDinero(true, new field({ title: "Amount" }));

}

export class PaymentInfo extends TableType {

  CardInfo = new ScalarJSON("CardInfo");

  GatewayResponses = new IsArray(new String());

  PaymentVaultID = new String(false, new field({ unique: true, }));

  PaymentValidateTxnID = new String(false, new field({}));

  StripeAccountID = new String(false, new field({ unique: true }));

}

export class ContactInfo extends TableType {

  constructor(sec: TableSecurity<any>) {
    super();
    this.__typeDirectivesAdd(
      ...__TableDirectives({ heading: this.constructor.name, sec })
    );
  }

  Name = new String(
    false,
    new field({
      title: "Name",
    })
  );
  Address = new Member(
    GeocodeAddress,
    false,
    new field({
      title: "Address",
    })
  );
  Phone = new ScalarPhone(
    false,
    new field({
      title: "Phone",
      inputType: "tel",
    })
  );
  Fax = new ScalarPhone(
    false,
    new field({
      title: "Fax",
      hidden: true,
    })
  );
  Notes = new String(false, new field({
    title: "Notes",
    inputType: "textarea",
  }));
}

export class CubesEnumMember extends EnumMember {
  constructor(title?: string, helptext?: string) {
    const f = new field({});
    super(f);
    this.__hookRegister.push((state) => {
      const attrs = this.__fieldAttributes().field?.first();
      if (!attrs) return;
      attrs.title = title ?? state.key;
      attrs.helptext = helptext ?? "";
    })
  }
}


export class Member<T extends RecordType, ID extends boolean = false> extends GQLMember<T> {
  hasID?: ID;
  constructor(
    ctor: { new(): T },
    required: boolean = false as boolean,
    ...__fieldDirectives: DirectiveInput<"FIELD_DEFINITION">[]
  ) {
    super(ctor, required, ...__fieldDirectives);
    let found = false, item: any = ctor.prototype;
    while (item && !found)
      if ((item = (item && Object.getPrototypeOf(item))) === RecordType.prototype)
        found = true;

    if (!found) throw new Error("Member can only be used for RecordType");
    // if (!(root.types as any)[ctor.name]) console.error(ctor.name);
    return new Proxy(this, {
      get(target: RecordType, p: keyof RecordType, receiver: any) {
        if (p === "__proxy") return true;
        if (typeof p === "string" && (false
          || p === "__fieldAttrs"
          || p === "__fieldAttributes"
          || p === "__fieldDirectivesAdd"
          || p === "__required"
          || p === "__index"
          || p === "__stack"
        ))
          return target[p];
        const t: any = root.registry.get(ctor as any);
        if (!t) console.error(ctor.name);
        return t[p];
      },
      getPrototypeOf(target) {
        return ctor.prototype;
      },
    }) as any;
  }
}




export type CP<T extends abstract new (...args: any) => any> = ConstructorParameters<T>;
// export type AttributesTableBoth<T extends TableType, H extends TableType, O extends DirectiveOptions> = Attributes<O>;
// export type AttributesTableHost<T extends RecordType, H extends TableType, O extends DirectiveOptions> = Attributes<O>;
// export type AttributesTableTarget<T extends TableType, H extends RecordType, O extends DirectiveOptions> = Attributes<O>;
// export type AttributesRecord<T extends RecordType, H extends RecordType, O extends DirectiveOptions> = Attributes<O>;
// export type AttributesEnum<H extends RecordType, O extends DirectiveOptions> = Attributes<O>;
// export type AttributesScalar<H extends RecordType, O extends DirectiveOptions> = Attributes<O>;
// export type AttributesEnumValue<H extends EnumType> = Attributes<"ENUM_VALUE">;
// export function attrs<T extends TableType, H extends TableType, O extends DirectiveOptions>(def: O, attrs: AttributesTableBoth<T, H, O>): any;
// export function attrs<T extends RecordType, H extends TableType, O extends DirectiveOptions>(def: O, attrs: AttributesTableHost<T, H, O>): any;
// export function attrs<T extends TableType, H extends RecordType, O extends DirectiveOptions>(def: O, attrs: AttributesTableTarget<T, H, O>): any;
// export function attrs<T extends RecordType, H extends RecordType, O extends DirectiveOptions>(def: O, attrs: AttributesRecord<T, H, O>): any;
// export function attrs<T extends any, H extends RecordType, O extends DirectiveOptions>(def: O, attrs: AttributesEnum<H, O>): any;
// export function attrs<T extends any, H extends RecordType, O extends DirectiveOptions>(def: O, attrs: AttributesScalar<H, O>): any;
// export function attrs<T extends any, H extends EnumType>(def: "", attrs: AttributesEnumValue<H>): any;
// export function attrs<O extends DirectiveOptions>(def: O, a: Attributes<O>) {
//   return new attributes({
//     target: def,
//     attrs: JSON.stringify(a)
//   });
// }
