
import {
  Attributes,
  ENUM_NAMES,
  Modes,
  TABLE_NAMES,
  schema,
  TYPE_NAMES,
  field,
  root,
  Attribute,
  ok,
  SCALAR_NAMES,
  SPPI,
  dateCubes,
  Root,
  is
} from "common";

import * as c from "../../../../../../../server/datamath/schema-builder/cubes-schema";
import * as g from "../../../../../../../server/datamath/schema-builder/graphql-declarator";
import { header, output } from "../../../../../../../server/datamath/schema-builder/cubes-prisma";
import {
  QuestionOptions,
  QuestionBase,
  QuestionCalendar,
  QuestionGroup,
  QuestionInputMask,
  QuestionInputNumber,
  QuestionSimple,
  QuestionSubGroup,
  SimpleControlType,
  LedgerTables,
  UIService,
} from '../utils';

function fixAttrs(v: Attributes<any>) {
  const attrs: any = JSON.parse(JSON.stringify(v));
  Object.keys(attrs).forEach((k) => {
    if (k !== "index")
      attrs[k] = attrs[k] && attrs[k].first();
  });
  return attrs;
}

interface RecordMember2 extends g.RecordMember<any> {
  __is(type: "enum"): this is g.EnumType & { __name: ENUM_NAMES; };
  /** this will still be a RecordType, but the value it checks against does not inherit from "record" */
  __is(type: "table"): this is g.RecordType & { __name: TABLE_NAMES; };
  __is(type: "record"): this is g.RecordType & { __name: Exclude<TYPE_NAMES, TABLE_NAMES>; };
  __is(type: "scalar"): this is g.ScalarType<any> & { __name: SCALAR_NAMES; };
  __is(type: "directive"): this is g.Directive<any>;
  __is(type: "AnyMember"): this is g.AnyMember<any, any>;
  __is(type: "RecordMember"): this is g.RecordMember<any>;

}
type GeneratorResult = (mode: Modes) => QuestionBase<any, any>;
type RecursiveRecord<K extends string, V> = { [P in K]: V | RecursiveRecord<K, V> };

// Don't try to type this function, it's too complex - 7/20/2024
export class Generator {
  static Group(name: TYPE_NAMES, mode: Modes, ui: UIService): QuestionGroup<TYPE_NAMES, Record<string, QuestionBase<any, any>>>;
  static Group<T extends TYPE_NAMES, F extends g.MemberKeys<Root["types"][T]>>(name: T, mode: Modes, ui: UIService, filterControls: F[]): QuestionGroup<T, Record<F[number], QuestionBase<any, any>>>;
  static Group(name: TYPE_NAMES, mode: Modes, ui: UIService, filterControls: RecursiveRecord<string, boolean> | undefined): QuestionGroup<TYPE_NAMES, Record<string, QuestionBase<any, any>>>;
  // static Group<T extends TYPE_NAMES>(name: T, mode: Modes, ui: UIService, filterControls: undefined): QuestionGroup<T, Record<g.MemberKeys<Root["types"][T]>, QuestionBase<any, any>>>;
  static Group(name: TYPE_NAMES, mode: Modes, ui: UIService, filterControls?: readonly string[] | RecursiveRecord<string, boolean>) {
    const v = root.types[name] as g.RecordType & { __name: TYPE_NAMES; };
    const forms = v.__typeAttributes().forms;
    const filterControlsSort = ([ka, va]: [string, g.RecordMember<any>], [kb, vb]: [string, g.RecordMember<any>]) => filterControls && Array.isArray(filterControls) ? filterControls.indexOf(ka) - filterControls.indexOf(kb) : va.__index - vb.__index;
    const filterObj = Array.isArray(filterControls) ? filterControls.reduce((n, e) => (n[e] = true, n), {} as Record<string, boolean>) : filterControls;
    return new QuestionGroup({
      __typename: v.__name as TYPE_NAMES,
      __prismaheader: header[v.__name] ? header[v.__name].map((e: string) => e.trim()) : undefined,
      forms: forms && forms[0],
      controls: {
        ...v.__type === "table"
          ? ui.tableControls(mode, !filterObj || !!filterObj["extra"])
          : { id: new QuestionSimple("Hidden", { onlyfor: [] }) },
        ...v.__listMembers().sort(filterControlsSort).reduce((acc, [k, v2]) => {

          const res = Generator.Field(v.__name as TYPE_NAMES, k, filterObj, ui);
          if (res) { acc[k] = res(mode); }
          return acc;
        }, {} as Record<string, QuestionBase<any, any>>),
      } as const
    });
  }
  static Field(name: TYPE_NAMES, k: string, filterControls: RecursiveRecord<string, boolean> | undefined, ui: UIService): ((mode: Modes) => QuestionBase<any, any>) | null {
    const v: g.RecordType = root.types[name];
    const { v2, isArray, isArrayRequired } = (() => {
      let v2 = v.__field(k), isArray = false, isArrayRequired = false;
      if (v2 instanceof g.IsArray) {
        isArrayRequired = v2.__required;
        v2 = v2.__wrap;
        isArray = true;
      }
      return { v2: v2 as RecordMember2, isArray, isArrayRequired };
    })();

    if (k === "id" || k === "createdAt" || k === "updatedAt" || k === "extra")
      return null;

    if (filterControls && !filterControls[k])
      return null;

    const attrs: Attributes = v2.__fieldAttributes();
    const field = attrs.field?.first() ?? {};
    const extra = { ...fixAttrs(attrs), field: undefined, index: undefined };

    const hasOne = attrs.hasOne?.first();
    const hasMany = attrs.hasMany?.first();
    const belongsTo = attrs.belongsTo?.first();
    const relation = attrs.relationPrisma?.first();
    const remote = hasMany?.remote || relation?.remote;

    const gen = new Generator(v2.__required, v.__name as TYPE_NAMES, filterControls?.[k], k, extra, ui);
    console.log("Generate Field", name, k, attrs);
    if (v2.__is("enum")) {
      // res.push(`this.ui.QuestionEnum(mode, ${isArray}, this.${v2.__name}, {`);
      // so.push(...Object.entries(select ?? []).map(([k, v]) => `${JSON.stringify(k)}: ${JSON.stringify(v)},`));
      return gen.QuestionSelectEnum(v2.__name as ENUM_NAMES, isArray, attrs.select?.first());
    } else if (v2.__is("scalar")) {
      let lookup = attrs.lookup?.first() as g.ValueTree<c.lookup<any, any>> | undefined;
      let select = attrs.select?.first() as g.ValueTree<c.select<any, any>> | undefined;
      if (isArray) {
        // TODO: this is not implemented yet
        // res.push(`new QuestionSimple("Hidden", {`);
        return gen.QuestionSimple("Hidden");
      } else if (lookup && lookup.optionValue) {
        return gen.selectField(k, v);
      } else if (v2 instanceof c.ScalarDate || v2 instanceof c.ScalarDateTime || v2 instanceof c.ScalarTime) {
        // res.push(`new QuestionCalendar({`);
        // so.unshift(`format: ${j(v2.format)},`);
        // so.unshift(`showDate: ${v2 instanceof s.ScalarDate || v2 instanceof s.ScalarDateTime},`);
        // so.unshift(`showTime: ${v2 instanceof s.ScalarTime || v2 instanceof s.ScalarDateTime},`);
        console.log(v2.format);
        return gen.QuestionCalendar({
          format: v2.format,
          showDate: v2 instanceof c.ScalarDate || v2 instanceof c.ScalarDateTime,
          showTime: v2 instanceof c.ScalarTime || v2 instanceof c.ScalarDateTime,
        });
      }
      else {
        switch (v2.__name) {
          case c.CubesDinero.name:
            // res.push(`new QuestionInputNumber({`);
            // so.unshift(
            //   `inputMode: "currency",`,
            //   `currency: 'USD',`
            // );
            extra.currency = undefined;
            return gen.QuestionInputNumber({ inputMode: "currency", });
          case c.Float.name:
            // res.push(`new QuestionInputNumber({`);
            // so.unshift(`inputMode: "decimal",`);
            if (!gen.props.inputType) gen.props.inputType = "number";
            return gen.QuestionInputNumber({ inputMode: "decimal" });
          case c.Int.name: {
            // res.push(`new QuestionInputNumber({`);
            // so.unshift(`inputMode: "integer",`);
            if (!gen.props.inputType) gen.props.inputType = "integer";
            return gen.QuestionInputNumber({ inputMode: "integer" });
          }

          case c.ScalarPhone.name:
            // { res.push(`new QuestionInputMask({`); so.unshift(`mask: "(999) 999-9999",`); break; }
            // return gen.QuestionInputMask({ mask: "(999) 999-9999" });
            if (!gen.props.inputType) gen.props.inputType = "tel";
            return gen.QuestionSimple("InputText");
          case c.Boolean.name:
            // { res.push(`new QuestionSimple("CheckBox", {`); break; }
            return gen.QuestionSimple("CheckBox");
          case c.String.name:
            // { res.push(`new QuestionSimple("InputText", {`); break; }
            return gen.QuestionSimple("InputText");
          case c.ScalarEmail.name:
            // { res.push(`new QuestionSimple("InputText", {`); break; }
            return gen.QuestionSimple("InputText");
          case c.ScalarJSON.name:
            gen.props.useDBNull = true;
            return gen.QuestionSimple("Textarea");
          case c.ScalarTimestamp.name:
            // { res.push(`new QuestionSimple("Hidden", {`); break; }
            return gen.QuestionSimple("Hidden");
          case c.ID.name: {
            let unique = attrs.field?.first()?.unique;
            if (lookup) {
              return gen.selectField(k, v);
            } else if (unique) {
              // unique fields probably are showing the subform, so just hide the id field
              // res.push(`new QuestionSimple("Hidden", {`);
              return gen.QuestionSimple("Hidden");
            } else {
              // res.push(`new QuestionSimple("InputLabel", {`);
              return gen.QuestionSimple("InputLabel");
            }
          }
          default:
            // { res.push(`new QuestionSimple("InputText", {`); break; }
            return gen.QuestionSimple("InputText");
        }
      }
    } else if (v2.__is("record")) {
      if (!isArray) {
        gen.props.useDBNull = true;
        if (v2.__name === c.NotificationPreferences.name) {
          // res.push(`this.ui.QuestionNotificationPreferences(mode, {`);
          return gen.QuestionNotificationPreferences();
        } else if (v2.__name === c.GeocodeAddress.name) {
          // res.push(`this.ui.QuestionGeocodeAddress(mode, true, {`);
          return gen.QuestionGeocodeAddress();
        } else if (v2.__name === c.EmailDoc.name) {
          // res.push(`this.ui.QuestionEmailDoc(mode, {`);
          return gen.QuestionEmailDoc();
        } else {
          // res.push(`new QuestionSubGroup(this.${v2.__name}(mode), {`);
          return gen.QuestionSubGroup(v2.__name);
        }
      } else if (isArray) {
        if (v2.__name === c.FileUpload.name) {
          // res.push(`this.ui.QuestionFileUpload(mode, {`);
          return gen.QuestionFileUpload();
          // res.push(`new QuestionSimple("Hidden", {`);
          // return gen.QuestionSimple("Hidden");
        } else {
          // res.push(`this.ui.QuestionRecordArray(${JSON.stringify(v2.__name)}, {`);
          return gen.QuestionRecordArray(v2.__name);
        }
      }

    } else if (v2.__is("table") && attrs.lookup?.length && !isArray) {
      return gen.selectField(k, v);
    } else if (v2.__is("table") && attrs.lookup?.length && isArray) {
      return gen.lookupTable();
    } else if (v2.__is("table") && belongsTo && !isArray) {
      //these are required because they contain data
      if (field.hidden || !field.unique)
        // res.push(`new QuestionSimple("Hidden", {`);
        return gen.QuestionSimple("Hidden");

      else
        // res.push(`this.ui.QuestionBelongsToUnique${js ? '' : `<c.${v2.__name}, c.${v.__name}>`}(this.${v2.__name}(mode), mode, ${j(attrs.belongsTo.first() ?? null)}, {`);
        return gen.QuestionBelongsToUnique(v2.__name, belongsTo);
    } else if (v2.__is("table") && hasOne && !isArray) {
      //these are required because they contain data
      if (field?.hidden)
        // res.push(`new QuestionSimple("Hidden", {`);
        return gen.QuestionSimple("Hidden");

      else
        // res.push(`this.ui.QuestionHasOne${js ? '' : `<c.${v2.__name}, c.${v.__name}>`}(this.${v2.__name}(mode), mode, ${j(attrs.hasOne.first() ?? null)}, {`);
        return gen.QuestionHasOne(v2.__name, hasOne);
    } else if (v2.__is("table") && remote && isArray) {
      if (!field?.arrayList) {
        // res.push(`new QuestionSimple("Hidden", {`);
        return gen.QuestionSimple("Hidden");
      } else {
        extra.hasMany = undefined;
        extra.prejoin = undefined;
        extra.relations = undefined;
        // res.push(`this.ui.QuestionHasMany${js ? '' : `<c.${v2.__name}, c.${v.__name}>`}(${j(v2.__name)}, ${j(hasMany?.remote || relations?.remote)}, ${j(prejoin ?? null)}, mode, {`);
        return gen.QuestionHasMany(v2.__name, remote, attrs.prejoin?.first());
      }
    } else if (v2.__is("table") && (relation || field?.clientSideLoad)) {
      if (!isArray)
        // res.push(`new QuestionSubGroup(this.${v2.__name}(mode), {`);
        return gen.QuestionSubGroup(v2.__name);
      else if (!field?.arrayList)
        // res.push(`new QuestionSimple("Hidden", {`);
        return gen.QuestionSimple("Hidden");
      else
        // res.push(`this.ui.QuestionRecordArray(${JSON.stringify(v2.__name)}, {`);
        return gen.QuestionRecordArray(v2.__name);
    } else if (v2.__is("table") && field?.clientSideOnly) {
      // res.push(`new QuestionSimple("Hidden", {`);
      return gen.QuestionSimple("Hidden");
    }

    throw new Error(`${v.__name} ${isArray} ${k} ${v2.__name} ${v2.__type} ${JSON.stringify(attrs)} not handled`);


  }

  private enums: Record<ENUM_NAMES, (g.ValueTree<field<any, any>> & { value: string; order: number; })[]> = {} as any;
  private added: any;
  props: QuestionOptions<any, any>;
  filter;
  private attrs: Attributes;
  constructor(required: boolean, name: TYPE_NAMES, filter: boolean | RecursiveRecord<string, boolean> | undefined, k: string, private extra: any, private ui: UIService) {
    this.attrs = schema.anyModel(name).fields[k].attributes;
    const fieldAttr = this.attrs.field?.first() ?? {};
    this.props = { required, ...JSON.clone(fieldAttr), };
    this.added = {} as any;
    if (typeof filter === "object")
      this.filter = filter;
    Object.keys(schema.enums).forEach((k) => {
      this.enums[k] = JSON.clone(
        Object.values(schema.enums[k].options)
          .map(({ value, order, attributes }) => ({
            ...attributes.field?.first() ?? {}, value, order
          })));
    });
  }


  selectField(k: any, name: any): GeneratorResult {
    const check = (k: any, v: any) => ["method", "queryName_internal"].indexOf(k) === -1 ? v : undefined;
    const lookup = JSON.clone(this.attrs.lookup?.first() ?? null, check);
    const select = JSON.clone(this.attrs.select?.first() ?? null, check);
    if (!lookup) throw new Error("lookup is undefined");
    const relation = k.slice(0, k.length - 2);
    if (output[name] && output[name][relation])
      this.added[relation] = output[name][relation];
    this.extra.lookup = undefined;
    return mode => this.ui.select(mode, lookup, select ?? undefined)(this.props);

  }

  lookupTable(): GeneratorResult {
    const lookup = this.attrs.lookup?.first()
    ok(lookup);
    return mode => this.ui.lookupTable(mode, lookup, this.props);
  }

  QuestionSimple(type: SimpleControlType): GeneratorResult {
    console.log("QuestionSimple", type, this.props);
    return mode => new QuestionSimple(type, this.props);
  }
  QuestionInputMask(opts: { mask: string; }): GeneratorResult {
    console.log("QuestionInputMask", opts, this.props);
    return mode => new QuestionInputMask({ ...this.props, ...opts });
  }
  QuestionSelectEnum(name: ENUM_NAMES, isArray: boolean, select: g.ValueTree<c.select<any, any>> | undefined): GeneratorResult {
    console.log("QuestionSelectEnum", name, isArray, select, this.props);
    return mode => this.ui.QuestionEnum(mode, isArray, this.enums[name], { ...this.props, ...select ?? {} });
  }
  QuestionCalendar(opts: { format?: string; showDate?: boolean; showTime?: boolean; }): GeneratorResult {
    console.log("QuestionCalendar", opts, this.props);
    return mode => new QuestionCalendar({ ...this.props, ...opts });
  }
  QuestionInputNumber(opts: {
    inputMode: "currency" | "decimal" | "integer";
    // currency?: "USD" | "EUR" | "CNY";
  }): GeneratorResult {
    console.log("QuestionInputNumber", opts, this.props);
    return mode => new QuestionInputNumber({ ...this.props, ...opts });
  }
  QuestionSubGroup(name: TYPE_NAMES): GeneratorResult {
    console.log("QuestionSubGroup", name, this.props);
    return mode => new QuestionSubGroup(Generator.Group(name, mode, this.ui, this.filter), this.props);
  }
  QuestionRecordArray(name: TYPE_NAMES): GeneratorResult {
    console.log("QuestionRecordArray", name, this.props);
    return mode => this.ui.QuestionHasMany(name, undefined, undefined, this.props);
  }
  QuestionHasMany(name: TABLE_NAMES, remote: any, prejoin: Attribute["prejoin"]): GeneratorResult {
    console.log("QuestionHasMany", name, remote, prejoin, this.props);

    if (LedgerTables.contains(name)) {
      this.props.extraGetPaths = this.props.extraGetPaths || [];
      this.props.extraGetPaths.push("line/invoiceLine/id" as SPPI, "line/paymentLine/id" as SPPI);
    }

    return mode => this.ui.QuestionHasMany<TABLE_NAMES, TABLE_NAMES>(name, remote, prejoin as any, this.props);
  }
  QuestionHasOne(name: TYPE_NAMES, hasOne: Attribute["hasOne"] & {}): GeneratorResult {
    console.log("QuestionHasOne", name, hasOne, this.props);
    return mode => this.ui.QuestionHasOne<any, any>(Generator.Group(name, mode, this.ui, this.filter), mode, hasOne, this.props);
  }
  QuestionBelongsToUnique(name: any, belongsTo: Attribute["belongsTo"] & {}): GeneratorResult {
    console.log("QuestionBelongsToUnique", name, belongsTo, this.props);
    return mode => this.ui.QuestionBelongsToUnique<any, any>(Generator.Group(name, mode, this.ui, this.filter), mode, belongsTo, this.props);
  }
  QuestionNotificationPreferences(): GeneratorResult {
    console.log("QuestionNotificationPreferences", this.props);
    return mode => this.ui.QuestionNotificationPreferences(mode, this.props);
  }
  QuestionGeocodeAddress(): GeneratorResult {
    console.log("QuestionGeocodeAddress", this.props);
    return mode => this.ui.QuestionGeocodeAddress(mode, true, this.props);
  }
  QuestionEmailDoc(): GeneratorResult {
    console.log("QuestionEmailDoc", this.props);
    return mode => this.ui.QuestionEmailDoc(mode, this.props);
  }
  QuestionFileUpload(): GeneratorResult {
    console.log("QuestionFileUpload", this.props);
    debugger;
    return this.QuestionSimple("Hidden");
  }


}
