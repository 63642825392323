const spp_recursive_symbol: unique symbol = Symbol("spp_recursive_symbol");


import { AnyMember, IsArray, MemberKeys, RecordMember, RecordType, truthy, Wrapping } from "./graphql-declarator";
import { ENUM_NAMES, ENUM_VALUES, PostgresUserRoles, RootTypes, TABLE_NAMES, TYPE_NAMES } from "./cubes-schema";
import { Member, PrivScope, TableType } from "./cubes-schema-helpers";
import type { Attributes } from "./cubes-attributes";
import type { FieldClass } from "./cubes-schema-classes";
import { join } from "path";
import { root } from "./cubes-index";

export interface EnumSchemaEntry<T extends ENUM_NAMES = any> {
  name: T;
  attributes: Attributes<"ENUM">;
  options: Record<ENUM_VALUES<T>, EnumSchemaOption<T>>;
}
export interface EnumSchemaOption<T extends ENUM_NAMES = any> {
  attributes: Attributes<"ENUM_VALUE">;
  label?: string;
  icon?: string[];
  value: ENUM_VALUES<T>;
  order: number;
}


export interface ListDef<K extends string> {
  query: () => string;
  paths: string[];
  tableName: K extends `List${infer T extends TABLE_NAMES}${string}` ? T : never;
  queryName: K;
  instance: any
}

export declare const SPPI_SYMBOL: unique symbol;
export declare const ARRAY_PATH_SYMBOL: unique symbol;
export type SPPI<T extends string[] = any> = string & {
  [SPPI_SYMBOL]: never;
  [ARRAY_PATH_SYMBOL]: T;
};



// this should not be an error, because we want strings to convert explicitly to SPPI and implicitly back to string, 
// so that a reciever of a string can also recieve an SPPI, 
const t1: string = "" as SPPI;
// @ts-expect-error so that a reciever of an SPPI can only recieve an SPPI
const t2: SPPI = "";


export type RealType<T> =
  T extends IsArray<infer X> ? RealType<X> :
  T extends Member<infer X, true> ? X :
  T extends Member<infer X, any> ? X :
  T;
export type RealTypeTree<T, R extends string> = {
  [K in MemberKeys<T> & keyof T as T[K] extends RecordMember<any> ? K : never]: RealTypeTree<RealType<T[K]>, string>
} & {
  __: string  // R extends `/${infer X}` ? X : R
}

{
  // type FieldType = {
  //   [K in keyof RootTypes]: {
  //     [K2 in keyof RootTypes[K]as RootTypes[K][K2] extends RecordMember<any> ? K2 : never]: RealType<RootTypes[K][K2]>
  //   }
  // }
  // type TypeNames = {
  //   [K in keyof RootTypes]:
  // }

  // type KeysMatching<T, V> = {
  //   [K in keyof T]: T[K] extends V ? K : never
  // }[keyof T];

}



export type SPPTypeTree<T> = {
  /** 
   * If you get a string index Typescript error, that usually means you need to explicitly declare the field type in the cubes schema. 
   */
  [K in string & keyof T as K extends MemberKeys<T> ? T[K] extends RecordMember<any> ? K : never : never]: SPPTypeTree<RealType<T[K]>>
} & {
  __: SPPI // SPPI<R extends `/${infer X}` ? X : R>
}

export type SPPTypeTreeHost<T, H> = {
  [K in MemberKeys<T> & keyof T as T[K] extends RecordMember<any> ? K : never]: SPPTypeTree<RealType<T[K]>>
} & {
  "..": SPPTypeTree<RealType<H>>
  __: SPPI // SPPI<R extends `/${infer X}` ? X : R>
}

// type UnionToIntersection<T> = (T extends any ? (x: T) => any : never) extends (x: infer R) => any ? R : never
// type Merge<T> = { [K in keyof UnionToIntersection<T>]: UnionToIntersection<T>[K] extends string | number | ((...args: any) => any) | symbol | boolean ? UnionToIntersection<T>[K] : Merge<UnionToIntersection<T>[K]>; }





// export interface SelectPathProxy {
//   <T extends RecordMember<any>>(): <R extends readonly any[]>(func: (e: SelectTypeTree<T, []>) => R) => number extends R["length"] ? "selector must return a const array" : ToSelectObject<R>;
// }

// /** 
//  * The path type is a Prisma select statement.
//  * Needs to be used with PrismaQuery.selectPathsProxy 
//  */
// export const SelectPathProxy: SelectPathProxy = GenericPathProxy;

export function GenericPathProxy(arg?: symbol | Function | undefined, path: string[] = []): any {

  const res = new Proxy<any>({}, {
    get(t: any, p: string, r) {
      if (p === "__") {
        return path.join("/") as any;
      }
      return GenericPathProxy(spp_recursive_symbol, [...path, p]);
    }
  });
  if (arg === spp_recursive_symbol) return res;
  if (arg === undefined) return (func: any) => func(res);
  if (typeof arg === "function") return arg(res);
  throw new Error("arg must be spp_recursive_symbol | typeof function | undefined");

}

function check(key: string, row: any) {

  return agg(row, key.split("/")) !== undefined;

  function agg(n: any, keys: string[]): any {
    const e = keys.shift();
    if (!e) return n;
    if (n === undefined || n === null) return n;
    if (Array.isArray(n[e])) {
      return n[e].flatMap((f: any) => agg(f, keys.slice()));
    } else {
      return agg(n[e], keys);
    }
  }

}

export function GenericPathProxyWithTables<T extends Record<string, string>, R>(table: T, func: (table: { [K in keyof T]: any }) => R): R {
  return func(Object.keys(table).reduce((n, k) => {
    n[k] = GenericPathProxyWithTable(table[k], spp_recursive_symbol);
    return n;
  }, {} as any));
}

export function GenericPathProxyWithTable(table: string, arg?: symbol | Function | undefined, path: string[] = []): any {

  const res = new Proxy<any>({}, {
    get(t: any, p: string, r) {
      if (p === "__") return table + ":" + path.join("/");
      return GenericPathProxyWithTable(table, spp_recursive_symbol, [...path, p]);
    }
  });

  if (arg === spp_recursive_symbol) return res;
  if (arg === undefined) return (func: any) => func(res);
  if (typeof arg === "function") return arg(res);
  throw new Error("arg must be spp_recursive_symbol | typeof function | undefined");

}

export class TableViewColumn<K extends SPPI = SPPI, T = any> {
  key!: K
  title?: string
  helptext?: string
  hidden?: boolean;
  sort?: number;
  sorter?: (a1: any, b1: any) => number;
  displayGroup?: string;
  aggregate?: "sum" | "average" | "min" | "max" | "first" | "last" |
    "count-truthy" | "count-unique" | "count" |
    "array-unique" | "array-truthy" | "array";
  filterType?: "boolean" | "text" | "numeric" | "date" | "enum" | "currency" | "none";
  childgroup?: (SPPI | TableViewColumn)[];
  /** Whether this column is used in the search field */
  queryColumn?: boolean;
  /** Consult DataListColumn for correct return types */
  calculate?: (row: any) => T;
  /** This is for custom React markup similar to valMarkup. val is the value returned by calculate or get. */
  markup?: (val: T) => any;
  /** Indicate the column is not part of the schema */
  custom?: FieldClass;
  alignment?: "center" | "start" | "end" | undefined;
  link?: (row: any) => string | null | undefined;
  constructor(options: TableViewColumn<K, T>) {
    Object.assign(this, options);
  }
}

export type ViewListFunc<T extends RecordMember<any>> = (e: SelectTypeTreeInner<T, []>) => (SPPI | TableViewColumn)[];
export type SPPF<T extends RecordMember<any>> = (e: SelectTypeTreeInner<T, []>) => (SPPI)[];
export type SPPFH<T extends RecordMember<any>, H extends RecordType> = (e: SelectTypeTreeHostInner<T, H, []>) => SPPI[];

export type SelectTypeTree<T extends TYPE_NAMES> = T extends never ? any : SelectTypeTreeInner<typeof root.types[T], []>;

type SelectTypeTreeInner<T extends RecordMember<any>, R extends string[]> = {
  __: SPPI<R>;
} & (T extends RecordType ? {
  [K in MemberKeys<T> & keyof T as T[K] extends RecordMember<any> ? K : never]:
  T[K] extends RecordMember<any> ? SelectTypeTreeInner<RealType<T[K]>, [...R, K]> : never
} : {});

type SelectTypeTreeHostInner<T extends RecordMember<any>, H extends RecordMember<any>, R extends string[]> = {
  __: SPPI<R>;
  "..": SelectTypeTreeInner<RealType<H>, [...R, ".."]>;
} & (T extends RecordType ? {
  [K in MemberKeys<T> & keyof T as T[K] extends RecordMember<any> ? K : never]:
  T[K] extends RecordMember<any> ? SelectTypeTreeInner<RealType<T[K]>, [...R, K]> : never
} : {});




type Filter<COLS, FILTER> = {
  [KEY in keyof COLS]:
  COLS[KEY] extends [infer FIRST extends FILTER, ...infer REST] ? REST : never
}
export type ToSelectObject<COLS extends readonly (SPPI | { key: SPPI })[]> = ToSelectObjectInner<UnwrapColumns<COLS>>;


type ToSelectObjectInner<COLS> = {
  [K in keyof COLS as COLS[K] extends [infer T, ...any] ? T extends string ? T : never : never]:
  COLS[K] extends [infer T, ...infer R] ? R extends [] ? true : { select: ToSelectObjectInner<Filter<COLS, T>> } : never;
}


export type UnwrapColumns<COLS extends readonly (SPPI | { key: SPPI })[]> = {
  [K in keyof COLS as K extends `${infer N extends number}` ? N : never]:
  (COLS[K] extends { key: infer U } ? U : COLS[K]) extends { [ARRAY_PATH_SYMBOL]: infer U } ? U : never
}


// export type ObjectPathTree<T, R extends string[]> = {
//   __: SPPI<R>;
// } & {
//   [K in string & keyof T]-?: ObjectPathTree<T[K], [...R, K]>;
// }


export function ObjectPathProxy<T, R extends { key: string[], value: any }[]>(
  model: T,
  fn: (x: ObjectPathTree<T, []>) => R
): ObjectPathTreeResult<R> {
  const paths = fn(GenericPathWalker());
  const res = {};
  for (const path of paths) {
    let obj: any = res;
    for (let i = 0; i < path.key.length - 1; i++) {
      obj = obj[path.key[i]] = obj[path.key[i]] || {};
    }
    if (path.key.length)
      obj[path.key[path.key.length - 1]] = path.value;
  }
  return res;
}

type FilterKeyValueObject<COLS, FILTER> = {
  [KEY in keyof COLS]:
  COLS[KEY] extends { key: [infer FIRST extends FILTER, ...infer REST], value: infer VALUE } ? REST extends [] ? never : { key: REST, value: VALUE } : never
}

type ObjectPathTreeResult<COLS extends { key: string[], value: any }[]> = {
  [K in keyof COLS as COLS[K] extends { key: [infer T, ...any] } ? T extends string ? T : never : never]:
  K extends number ? never : COLS[K] extends { key: [infer T, ...infer R], value: infer S } ? R extends [] ? S : ObjectPathTreeResult<FilterKeyValueObject<COLS, T>> : never;
}

export type ObjectPathTree<T, R extends string[]> =
  {
    __: { key: SPPI<R>, value: true };
    ___<S extends T>(val: T): { key: SPPI<R>, value: S };
  } &
  (({ [K in KeyOf<NN<T>>]-?: ObjectPathTree<NN<T>[K], [...R, K]> }));

type KeyOf<T> = string & keyof T;

type NN<T> =
  T extends undefined ? never :
  T extends null ? never :
  T extends string ? never :
  T extends number ? never :
  T extends boolean ? never :
  T;
function GenericPathWalker(path: string[] = []): any {
  return new Proxy<any>({}, {
    get(t: any, p: string, r) {
      if (p === "__") {
        return ({ key: path.join("/"), value: true });
      }
      if (p === "___") {
        return (val: any) => ({ key: path.join("/"), value: val });
      }
      return GenericPathWalker([...path, p]);
    }
  });
}


type SPP1<T extends TYPE_NAMES> = SelectTypeTreeInner<RootTypes[T], []>;
type SPP2<T extends RecordMember<any>> = SelectTypeTreeInner<T, []>;
type SPP3<T extends TYPE_NAMES, H extends TYPE_NAMES> = SelectTypeTreeHostInner<RootTypes[T], RootTypes[H], []>;
type SPP4<T extends RecordMember<any>, H extends RecordType> = SelectTypeTreeHostInner<T, H, []>;

interface StringPathProxy {

  <T extends TYPE_NAMES>(): <R>(func: (e: SPP1<T>) => R) => R;
  <T extends RecordMember<any>>(): <R>(func: (e: SPP2<T>) => R) => R;

  <T extends TYPE_NAMES, H extends TYPE_NAMES>(): <R>(func: (e: SPP3<T, H>) => R) => R;
  <T extends RecordMember<any>, H extends RecordType>(): <R>(func: (e: SPP4<T, H>) => R) => R;

}

export type StringPath<T extends TYPE_NAMES> = SelectTypeTreeInner<RootTypes[T], []>;

interface StringPathLookup {

  <T extends TYPE_NAMES>(): SPP1<T>;
  <T extends RecordMember<any>>(): SPP2<T>;

  <T extends TYPE_NAMES, H extends TYPE_NAMES>(): SPP3<T, H>;
  <T extends RecordMember<any>, H extends RecordType>(): SPP4<T, H>;

}

/** The path type is a standard string path as SPPI<string> */
export const StringPathProxy: StringPathProxy = GenericPathProxy;

/** The path type is a standard string path as SPPI<string> */
export const StringPathLookup: StringPathLookup = GenericPathProxy.bind(undefined, spp_recursive_symbol);

// export type SelectPathProxyVar<T extends RecordMember<any>> = SelectTypeTree<T, "SelectPathProxy_path_point">;
// export type SelectPathFunc<T extends RecordMember<any>> = <R>(e: SelectPathProxyVar<T>) => R;
// interface SelectPathProxyInner<T extends RecordType> { <R>(func: (e: SelectPathProxyVar<T>) => R): R }
// interface SelectPathProxy {
//   <T extends TYPE_NAMES>(): SelectPathProxyInner<RootTypes[T]>
//   <T extends RecordType>(): SelectPathProxyInner<T>
// }


type SQLTypeTree<T> = {
  /** 
   * If you get a string index Typescript error, that usually means you need to explicitly declare the field type in the cubes schema. 
   */
  [
  K in string & keyof T as T[K] extends RecordMember<any> ? (
    T[K] extends Member<any, true> ? K | `${K}ID` :
    T[K] extends RecordMember<any> ? K : never
  ) : never
  ]: SQLTypeTree<RealType<T[K]>>
} & {
  __: string
}

export type TypeTreeWithID<T, R = SPPI> = {
  /** 
   * If you get a string index Typescript error, that usually means you need to explicitly declare the field type in the cubes schema. 
   */
  [
  K in string & keyof T as T[K] extends RecordMember<any> ? (
    T[K] extends Member<any, true> ? K | `${K}ID` :
    T[K] extends RecordMember<any> ? K : 1
  ) : 2
  ]: TypeTreeWithID<RealType<T[K]>, R>
} & {
  __: SPPI
}

interface SQLSelectProxy {
  <T extends TABLE_NAMES, R>(t: T): SQLTypeTree<typeof root.types[T]>;
  <T extends TABLE_NAMES, R>(t: T, func: (e: SQLTypeTree<typeof root.types[T]>) => R): R;
}
export const SQLSelectProxy: SQLSelectProxy = _SQLSelectProxy;

function _SQLSelectProxy(t: string, arg?: symbol | Function | undefined, path: [boolean, string, string] = [false, t, ""], state = new SQLSelectProxyState(t as any)): any {
  const res = new Proxy<any>({}, {
    get(_1: any, p: string | symbol, _3) {
      if (typeof p === "symbol") {
        throw new Error(`symbols are not supported. Did you forget the ending "__" property.`)
      }
      if (p === "__") {
        state.finished = true;
        return root.types[t]?.__is("table") ? `"public"."${t}"` : `"public"."${path[1]}"."${path[2]}"`;
      }

      if (p === "__state") return state;

      if (state.finished === false && !path[0])
        throw new Error(`the last path didn't end with the "__" property`);

      state.finished = false;
      const item: any = root.types[t];
      let child = item[p];
      const relchild = !child && p.endsWith("ID") && p.length > 2 && item[p.slice(0, p.length - 2)]
      const isID = !child && relchild instanceof TableType && !!relchild?.__fieldAttributes().belongsTo?.first()?.isRelation;

      if (isID) {
        return _SQLSelectProxy("ID", spp_recursive_symbol, [true, t, p], state);
      }
      if (child instanceof RecordMember) {
        return _SQLSelectProxy(child.__name, spp_recursive_symbol, [true, t, p], state);
      }

      throw new Error(`${p} not RecordMember of ${t}`);
    }
  });
  if (arg === spp_recursive_symbol) return res;
  if (typeof arg === "function") return arg(res);
  if (arg === undefined) return res;
  throw new Error("arg must be spp_recursive_symbol | typeof function | undefined");
}
class SQLSelectProxyState {
  public innerJoin: TABLE_NAMES[] = [];
  public finished = true;
  // public paths: string[] = [];
  constructor(
    public from: TABLE_NAMES,
  ) {
    if (!root.types[from]?.__is("table"))
      throw "The starting type must be a table.";
  }
}


export function printObjectTree(acc: string[], obj: any, tag: symbol, ignore: string[], level: number = 0): any {
  for (const key of Object.keys(obj)) {
    if (ignore.indexOf(key) !== -1) {
      //ignore it
    } else if (obj[key] === tag) {
      acc.push("  ".repeat(level) + key);
    } else if ((obj[key] ?? null) === null) {
      console.log("null", key)
    } else if (Array.isArray(obj[key])) {
      throw new Error("support for arrays not implemented");
    } else {
      // console.log(requestedObj[key])
      // if the value isn't "true", we must have found a nested object
      // so, we'll call this same function again, now starting from
      // the nested object inside requestedObj
      acc.push("  ".repeat(level) + key + " {");
      printObjectTree(acc, obj[key], tag, ignore, level + 1);
      acc.push("  ".repeat(level) + "}")
    }
  }

  if (level === 0) return acc.join("\n")
}


export function getValueByPath(obj: any, path: string[]) {
  return path.reduce((n, e) => n ? n[e] : n, obj as any);
}
export function setValueByPath(obj: any, path: string[], val: any) {
  return reducer_setValueByPath(obj, path, () => val)
}
export function reducer_setValueByPath(acc: any, keys: string[], val: () => any) {
  if (!keys.length) return val();
  let key = keys[0];
  if (acc[key] === undefined) acc[key] = {};
  acc[key] = reducer_setValueByPath(acc[key], keys.slice(1), val);
  return acc;
}
export function reduceObjectTreeFromObjectPaths(paths: string[], val: any) {
  const res = {} as any;
  for (let e of paths) {
    let parts = e.split("/");
    reducer_setValueByPath(res, parts, () => val);
  }
  return res;
}
export function getPathsFromObject(obj: any, paths: string[], deny: "undefined" | "null" | "falsy" | false,) {
  let tag = Symbol("getPathsFromObject");
  const res = reduceObjectTreeFromObjectPaths(paths, tag);
  assignValuesToObjectTree(res, obj, tag, deny, []);
  return res;
}

export function selectObjectFromPaths(root: RecordType, paths: string[], value: any, set__typename: boolean) {
  const tag = Symbol("getObjectValueFromSchemaPaths");
  const res = paths.length
    ? paths.reduce((n, e) => reducer_ObjectTreeFromGraphPath(n, e.split("/"), root, tag, set__typename), {})
    : reducer_ObjectTreeFromGraphPath({}, [], root, tag, set__typename);
  assignValuesToObjectTree(res, value, tag, false, ["__typename"]);
  return res;
}

/**
 * 
 * @param acc The accumulator
 * @param keys path as array of strings
 * @param item root RecordType to start from
 * @param placeholder symbol which signals a primitive
 * @returns The accumulator (or possibly the placeholder if the keys array is empty)
 * @throws if keys is not empty and the first key does not exist on item
 */
export function reducer_ObjectTreeFromGraphPath(
  acc: any, keys: string[], item: any, placeholder: symbol, set__typename: boolean,
  selector?: (e: RecordMember<any>) => boolean
) {
  const selector2 = (item: any) => {
    if (selector && !selector(item)) return false;
    if (item instanceof AnyMember && item.__fieldAttributes().hasMany?.length) return false;
    if (item instanceof AnyMember && item.__fieldAttributes().belongsTo?.length) return false;
    return true;

  }
  if (item instanceof Wrapping) {
    if (!item.__wrap) debugger;
    item = item.__wrap;
  }
  if (!keys.length) {
    if (item instanceof RecordType) {
      if (set__typename)
        acc.__typename = item.__name;
      item.__listMembers().forEach(([k2, item2]) => {
        if (selector2(item2))
          acc[k2] = reducer_ObjectTreeFromGraphPath({}, [], item2, placeholder, set__typename, selector)
      });
      return acc;
    } else {
      return placeholder;
    }
  }
  let key = keys[0]
  if (!item[key]) { debugger; }
  if (!acc[key]) acc[key] = {};
  if (set__typename && !acc.__typename && item instanceof RecordType)
    acc.__typename = item.__name;
  if (selector2(item[key]))
    acc[key] = reducer_ObjectTreeFromGraphPath(acc[key], keys.slice(1), item[key], placeholder, set__typename, selector);
  return acc;
}

export function assignValuesToObjectTree(res: any, val: any, tag: symbol, deny: "undefined" | "null" | "falsy" | false, ignore: string[]): any {
  let check: (e: any) => boolean;
  switch (deny) {
    case "falsy": check = truthy; break;
    case "null": check = (e) => e != null; break;
    case "undefined": check = (e) => e !== undefined; break;
    default: check = () => true;
  }
  for (const key of Object.keys(res)) {
    if (ignore.indexOf(key) !== -1) {
      //ignore it, leaving the destination value intact
    } else if (val == null || !val.hasOwnProperty(key)) {
      if (!check(val))
        throw new Error(`You requested a key that doesn't exist: ${key}`);
      else
        res[key] = null;
    } else if (res[key] === tag || val[key] === null) {
      res[key] = val[key];
    } else if (Array.isArray(val[key])) {
      // keep track of the object they requested
      const originalRequest = res[key]
      // then, create an array where that request used to be
      // for us to "push" new elements onto
      res[key] = []
      for (const actualItem of val[key]) {
        // make a variable to store the result of assignValuesToObjectKeys
        // we use { ...originalRequest } here to create a "copy"
        const requestedItem = { ...originalRequest }
        assignValuesToObjectTree(requestedItem, actualItem, tag, deny, ignore)
        res[key].push(requestedItem)
      }
    } else {
      // console.log(requestedObj[key])
      // if the value isn't "true", we must have found a nested object
      // so, we'll call this same function again, now starting from
      // the nested object inside requestedObj
      assignValuesToObjectTree(res[key], val[key], tag, deny, ignore)
    }
  }
}


export function findValueInObject(val: any, predicate: (val: any) => boolean, keys: string[] = [], paths: string[] = []): any {
  if (predicate(val)) {
    paths.push(keys.join("/"));
    return paths;
  }
  if (val && typeof val === "object") {
    for (const key of Object.keys(val)) {
      if (!val.hasOwnProperty(key)) continue;
      findValueInObject(val[key], predicate, [...keys, key], paths);
    }
  }
  console.log(paths, keys);
  return paths;
}

export function normalizePath(e: string) {
  let a = e.split("/").filter(x => x !== ".");
  let start = 0;
  while (a.indexOf("..") > -1) {
    if (a.indexOf("..") > 0) {
      a.splice(a.indexOf("..") - 1, 2)
    } else {
      a.shift();
      start++;
    }
  }
  if (a.last() === "") a.pop();
  return "../".repeat(start) + a.join("/");
}

export const resolveDots = (k: string, e: string, ...rest: string[]): string => {
  return join(k, e, ...rest);
  e = normalizePath(e);
  let val = e.startsWith("../") ? e.slice(3) :
    `${k ? k + "/" : ""}${e.startsWith("./") ? e.slice(2) : e}`;
  val = normalizePath(val);
  if (rest.length) return resolveDots(val, rest[0], ...rest.slice(1));
  else return val;
}

export function recursiveindent(lines: RecursiveArray<string>, depth: number = 0): string {
  return lines.map(e => {
    if (typeof e === "string") return "  ".repeat(depth) + e;
    else if (Array.isArray(e)) return recursiveindent(e, depth + 1);
    else return "";
  }).filter(e => e.trim()).join("\n");
}

export type RecursiveArray<T> = (T | RecursiveArray<T>)[];

export function getPath(group: any, path: string[], partial = false): [any, number] {

  for (let i = 0; i < path.length; i++) {
    const e = path[i];
    if (!group)
      throw new Error("Cannot navigate full path " + path.join("/"))
    else if (e === "..")
      if (group.parent) group = group.parent.parent;
      else throw new Error("parent does not exist")
    else if (e === ".")
      true;
    // else if (group.controls[e] instanceof QuestionSubGroup)
    //   group = group.controls[e].group;
    // else if (group.controls[e] instanceof QuestionTable)
    //   throw new Error("Cannot descend into a table");
    // else if (group.controls[e] && (partial || i === path.length - 1))
    //   return [group.controls[e], i + 1];
    else
      throw new Error(`Cannot descend into ${e}`);
  }
  throw new Error("Should not happen");
}


export function toRecord<T, K extends keyof T>(k: K, arr: T[]): Record<string, T> {
  return arr.reduce((n, e) => (n[e[k]] = e, n), {} as any);
}

export function toRecordFunc<T, K extends string>(k: (e: T) => K, arr: T[]): Record<K, T> {
  return arr.reduce((n, e, i) => {
    let key = k(e);
    if (!key) throw new Error("Key missing at index " + i);
    n[key] = e;
    return n;
  }, {} as any);
}

export function groupBy<T, K extends keyof T>(k: K, arr: T[]): Record<string, T[]> {
  return arr.reduce((n, e) => ((n[e[k]] = n[e[k]] || []), n[e[k]].push(e), n), {} as any);
}

export function groupByFunc<T>(k: (e: T) => string, arr: T[]): Record<string, T[]> {
  return arr.reduce((n, e, i) => {
    let key = k(e);
    if (!key) throw new Error("Key missing at index " + i);
    n[key] = n[key] || [];
    n[key].push(e);
    return n;
  }, {} as any);
}



export function isPrivScope(actual: PrivScope | MemberKeys<PostgresUserRoles>, expect: PrivScope) {
  // if (actual.startsWith("web_central_")) actual = "web_central";
  // if (actual.startsWith("web_dealer_")) actual = "web_dealer";
  const rankActual = PrivScope.indexOf(actual);
  const rankExpect = PrivScope.indexOf(expect);
  if (rankActual === -1 || rankExpect === -1) throw "scope does not exist";
  // 0th place, 1st place, 2nd place, 3rd place, so app < web_admin. 
  if (rankActual > rankExpect) return false;
  return true;
}
if (!isPrivScope("app", "web_admin")) { console.log("isPrivScope is reversed"); process.exit(1); }
if (!isPrivScope("web_admin", "web_user")) { console.log("isPrivScope is reversed"); process.exit(1); }
/** Array of privelage levels from most important to least important */
export const PrivLevel = ["admin", "user"] as const;
export type PrivLevel = typeof PrivLevel[number];
/** Returns whether actual priv level is at least expected priv level */
export function isPrivLevel(actual: PrivLevel | MemberKeys<PostgresUserRoles>, expect: PrivLevel) {
  if (actual.endsWith("_admin")) actual = "admin";
  if (actual.endsWith("_user")) actual = "user";
  const rankActual = PrivLevel.indexOf(actual);
  const rankExpect = PrivLevel.indexOf(expect);
  if (rankActual === -1 || rankExpect === -1) throw "level does not exist";
  // 0th place, 1st place, 2nd place, 3rd place, so admin < user. 
  if (rankActual > rankExpect) return false;
  return true;
}
if (isPrivLevel("user", "admin")) { console.log("isPrivLevel is reversed"); process.exit(1); }



export type PermissionNames =
  | `${"read" | "write"} ${"public" | "billing" | "ledger" | "users"} for ${"all" | "groups of" | "specific"} owners`
  | `${"read" | "write"} ${"public" | "billing" | "ledger" | "users"} for central`
  | `${"read" | "write"} ${"public" | "billing" | "ledger" | "users"} for ${"all" | "groups of" | "specific"} divisions`
  | `${"read" | "write"} ${"public" | "billing" | "ledger" | "users" | "markup"} for ${"all" | "groups of" | "specific"} branches`
  | `${"read" | "write"} ${"public" | "billing" | "ledger" | "users"} for ${"all" | "groups of" | "specific"} customers`
  | `charge customer credit card / bank account ${"any" | "specific"} amount for ${"all" | "groups of" | "specific"} customers`
  | `${"read" | "write"} customer payment info for ${"all" | "groups of" | "specific"} customers`
  | `${"read" | "write"} transaction info for ${"all" | "groups of" | "specific"} ${"customers" | "owners" | "branches" | "divisions"}`
  | `${"read" | "write"} transaction info for central`
  ;

function t<A extends readonly string[]>(...args: A) {
  return args;
}

export const PermissionNamesArray = [
  ...t("read", "write").flatMap(x => t("public", "billing", "ledger", "users").flatMap(y => t("all", "groups of", "specific").flatMap(z => [
    `${x} ${y} for ${z} owners` as const,
    `${x} ${y} for central` as const,
    `${x} ${y} for ${z} divisions` as const,
    `${x} ${y} for ${z} branches` as const,
    `${x} ${y} for ${z} customers` as const,
  ]))),
  ...t("read", "write").flatMap(x => t("markup").flatMap(y => t("all", "groups of", "specific").flatMap(z => [
    `${x} ${y} for ${z} branches` as const,
  ]))),
  ...t("all", "groups of", "specific").flatMap(x => [
    ...t("any", "specific").flatMap(y => [
      `charge customer credit card / bank account ${y} amount for ${x} customers` as const,
    ]),
    ...t("read", "write").flatMap(y => [
      `${y} customer payment info for ${x} customers` as const,
    ]),
  ]),
  ...t("read", "write").flatMap(x => t("customers", "owners", "branches", "divisions").flatMap(y => t("all", "groups of", "specific").flatMap(z => [
    `${x} transaction info for ${z} ${y}` as const,
  ]))),
  ...t("read", "write").flatMap(x => [`${x} transaction info for central` as const]),
] satisfies PermissionNames[];

{
  type A = PermissionNames;
  type B = typeof PermissionNamesArray[number];
  type C = A extends B ? B extends A ? true : false : false
  // make sure PermissionNamesArray has exactly the same values as PermissionNames
  // duplicates won't be caught but also don't matter here.
  const c: C = true;
}

