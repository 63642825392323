import React, { useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { QuestionTinyMCE } from "../utils/question-base";
import { Labelled } from "@shopify/polaris";


export function QuestionTinyMCEComp({ q, ...commonProps }: {
  q: QuestionTinyMCE;
  id: string;
  label: string;
  required: boolean;
  disabled: boolean;
  readOnly: boolean;
  readonly: boolean;
  autoComplete: string;
  helpText: string | undefined;
}) {
  return q.init ? (
    <Labelled {...commonProps}><Editor
      apiKey="pl04avl2zghfs0x2057tiwiyjf3cf3j3ky37wf0n9slk443u"
      ref={editor => { if (editor) q.updateEditor(editor); }}
      init={q.init as any}
      value={q.form.value?.html ?? ""}
      onEditorChange={(newValue, editor) => {
        q.form.setValue({ html: newValue, text: editor.getContent({ format: "text" }) });
        q.form.markAsDirty();
      }}
      onBlur={() => { q.form.markAsTouched(); }}
    /></Labelled>
  ) : null;
}
