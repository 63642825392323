
import {
  index,
  mapsTo,
  uniquePrisma,
  relationPrisma,
  noticeTags,
  field,
  filter,
  select,
  initdata,
  lookup,
  FilterWith,
  primaryKey,
  DirectiveInput,
  Attributes,
} from "./cubes-attributes";
import {
  EnumType,
  IsArray,
  RecordType, MemberKeys,
  ScalarType,
  __name_symbol,
  EnumMember,
  ValueTree,
  DirectiveOptions,
  RecordMember,
  GraphRoot
} from "./graphql-declarator";

import {
  __TableDirectives,
  forKeyString,
  CubesEnumMember,
  Member,
  BaseLedger,
  TableType,
  ContactInfo,
  selectID,
  hasOneField,
  hasManyField,
  belongsToField,
  selectRelation,
  PaymentInfo,
  Group,
  GroupChild,
  rls
} from "./cubes-schema-helpers";


import { WHERE_balanceWhereLine } from "./cubes-schema-from-prisma";
import type { PermissionNames, SPPI } from "./cubes-index";
export * from "./cubes-attributes";


import { __rents } from "./cubes-const";






function ok(a: any, message?: string): asserts a { if ((a ?? null) === null) throw new Error(message || "Assertion Failed"); }
const is = <T>(a: any, p: boolean): a is T => p;
type CP<T extends abstract new (...args: any) => any> = ConstructorParameters<T>;
type CPS = ConstructorParameters<typeof ScalarType<string>>;

/** A unique identifier for an object. This scalar is serialized like a String but isn't meant to be human-readable. */
export class ID extends ScalarType<string> {
  [__name_symbol] = "ID" as const;
}
/** A UTF-8 character sequence. */
export class String<T extends string = string> extends ScalarType<T> {
  [__name_symbol] = "String" as const;

}
/** 
 * A Boolean value, either `true` or `false`. 
 * 
 * If the field is required, the default is not set. 
 * If the field is not required, the default is set to false. 
 * In a where clause, `"column" <> false` is the same as `column = true`. The null value is filtered out in both cases.
 * In prisma, {not:boolean} uses `<>`, and {not:null} uses `IS NOT NULL`.
 * So instead if the field is not required, we'll just use the default value of false.
 * If you need a three way value, use BooleanNullable.
 */
export class Boolean<T extends boolean = boolean> extends ScalarType<T> {
  [__name_symbol] = "Boolean" as const;
}
export class BooleanNullable<T extends boolean = boolean> extends ScalarType<T> {
  [__name_symbol] = "BooleanNullable" as const;
}
/** An integer value between -(2^31) and 2^31-1. */
export class Int<T extends number = number> extends ScalarType<T> {
  [__name_symbol] = "Int" as const;

}
/** An IEEE 754 floating point value. */
export class Float<T extends number = number> extends ScalarType<T> {
  [__name_symbol] = "Float" as const;
}

/** An extended ISO 8601 date string in the format `YYYY-MM-DD`. */
export class ScalarDate extends ScalarType<string> {
  [__name_symbol] = "ScalarDate" as const;
  constructor(public format: string = "yyyy-MM-dd", ...args: CPS) { super(...args); }
}
/** An extended ISO 8601 time string in the format `hh:mm:ss.sss`. */
export class ScalarTime extends ScalarType<string> {
  [__name_symbol] = "ScalarTime" as const;
  constructor(public format: string = "hh:mm:ss.SSS", ...args: CPS) { super(...args); }
}
/** An extended ISO 8601 date and time string in the format `YYYY-MM-DDThh:mm:ss.sssZ`. */
export class ScalarDateTime extends ScalarType<string> {
  [__name_symbol] = "ScalarDateTime" as const;
  constructor(public format: string = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", ...args: CPS) { super(...args); }
}
/** An integer value representing the number of seconds before or after `1970-01-01-T00:00`Z. */
export class ScalarTimestamp extends ScalarType<number> {
  [__name_symbol] = "ScalarTimestamp" as const;
  constructor(public format: string = "yyyy-MM-dd", ...args: CPS) { super(...args); }
}

/** A phone number. This value is stored as a string. Phone numbers can contain either spaces or hyphens to separate 
 * digit groups. Phone numbers without a country code are assumed to be US/North American numbers adhering to the 
 * North American Numbering Plan (NANP). 
 */
export class ScalarPhone<T = any> extends ScalarType<T> {
  [__name_symbol] = "ScalarPhone" as const;
  /* static register = root.register(() => new this()); */ /*__builtin = true;*/
}

export class ScalarJSON<K extends keyof PrismaJson.AllTypes> extends ScalarType<PrismaJson.AllTypes[K]> {
  static stringify<T>(a: T): string { return JSON.stringify(a); }
  [__name_symbol] = "ScalarJSON" as const;
  constructor(public type: K, ...args: CPS) { super(...args); }
}
/** An email address in the format local-part@domain-part as defined by RFC 822. */
export class ScalarEmail<T = any> extends ScalarType<T> {
  [__name_symbol] = "ScalarEmail" as const;

}
/** A URL as defined by RFC 1738. For example, https://www.amazon.com/dp/B000NZW3KC/ or mailto:example@example.com. 
 * URLs must contain a schema (http, mailto) and can't contain two forward slashes (//) in the path part. 
 */
export class ScalarURL<T = any> extends ScalarType<T> {
  [__name_symbol] = "ScalarURL" as const;
}
/** A valid IPv4 or IPv6 address. IPv4 addresses are expected in quad-dotted notation (123.12.34.56). IPv6 addresses 
 * are expected in non-bracketed, colon-separated format (1a2b:3c4b::1234:4567). You can include an optional 
 * CIDR suffix (123.45.67.89/16) to indicate subnet mask.
 */
export class ScalarIPAddress<T = any> extends ScalarType<T> {
  [__name_symbol] = "ScalarIPAddress" as const;
}

export class CubesDinero extends ScalarType<number> {
  [__name_symbol] = "CubesDinero" as const;
}
export class FieldFilterType extends EnumType {
  text = new EnumMember();
  numeric = new EnumMember();
  boolean = new EnumMember();
  date = new EnumMember();
  enum = new EnumMember();
}
export class ConfirmType extends EnumType {

  READ = new EnumMember()
  CREATE = new EnumMember()
  UPDATE = new EnumMember()
  DELETE = new EnumMember()
}
export class ReferentialActions extends EnumType {

  Cascade = new EnumMember();
  Restrict = new EnumMember();
  NoAction = new EnumMember();
  SetNull = new EnumMember();
  SetDefaul = new EnumMember();
}
/** this is used in the database privelages */
export class BranchUserLevel extends EnumType {
  user = new CubesEnumMember("User");
  admin = new CubesEnumMember("Admin");
}

export class QuickbooksChartOfAccountsCategory extends EnumType {

  // __builtin = false;

  Bank = new CubesEnumMember("Asset: Bank");
  Other_Current_Asset = new CubesEnumMember("Asset: Other Current Asset");
  Fixed_Asset = new CubesEnumMember("Asset: Fixed Asset");
  Other_Asset = new CubesEnumMember("Asset: Other Asset");
  Accounts_Receivable = new CubesEnumMember("Asset: Accounts Receivable");
  Equity = new CubesEnumMember("Equity");
  Expense = new CubesEnumMember("Expense: Expense");
  Other_Expense = new CubesEnumMember("Expense: Other Expense");
  Cost_of_Goods_Sold = new CubesEnumMember("Expense: Cost of Goods Sold");
  Accounts_Payable = new CubesEnumMember("Liability: Accounts Payable");
  Credit_Card = new CubesEnumMember("Liability: Credit Card");
  Long_Term_Liability = new CubesEnumMember("Liability: Long Term Liability");
  Other_Current_Liability = new CubesEnumMember("Liability: Other Current Liability");
  Income = new CubesEnumMember("Revenue: Income");
  Other_Income = new CubesEnumMember("Revenue: Other Income");
}
export class PostgresUserRoles extends EnumType {
  web_admin = new EnumMember()
  web_user = new EnumMember()
  // web_central_admin = new EnumMember()
  // web_central_user = new EnumMember()
  // web_dealer_admin = new EnumMember()
  // web_dealer_user = new EnumMember()
}

export class PostgresRoles extends PostgresUserRoles {
  app = new EnumMember();
}
export class PostgresTablePermissions extends EnumType {

  INSERT = new EnumMember();
  SELECT = new EnumMember();
  UPDATE = new EnumMember();
  DELETE = new EnumMember();
  TRUNCATE = new EnumMember();
  REFERENCES = new EnumMember();
  TRIGGER = new EnumMember();

}
export class EditorType extends EnumType {


  __builtin = true
  InputText = new EnumMember()
  TextArea = new EnumMember()
  TinyMCE = new EnumMember()
  Email = new EnumMember()
}
export class InvoiceType extends EnumType {
  Manual = new CubesEnumMember();
  Rental = new CubesEnumMember();
  Commercial = new CubesEnumMember();
  // Fees = new CubesEnumMember();
}

export class InvoiceStatus extends EnumType {

  Draft = new CubesEnumMember("Draft");
  Sent = new CubesEnumMember("Sent");
  Paid = new CubesEnumMember("Paid");
  Void = new CubesEnumMember("Void");

}

/**
 * 2023-02-13 ChatGPT: what's a good word for regional departments? "branches"
 */
export class BranchType extends EnumType {



  CENTRAL = new CubesEnumMember("Central");
  DEALER = new CubesEnumMember("Dealer");
  REPAIRS = new CubesEnumMember("Repairs");
  // CLEANING = new CubesEnumMember("Cleaning");
}

export class OwnerPaymentSchedule extends EnumType {



  None = new CubesEnumMember("None")
  Q1 = new CubesEnumMember("Quarterly");
  Q2 = new CubesEnumMember("Bi-annually");
  Q4 = new CubesEnumMember("Annually");
}


export class ProRating extends EnumType {



  CUSTOM = new CubesEnumMember();
  FULL_NOW = new CubesEnumMember();
  FULL_LATER = new CubesEnumMember();
  FIRST_LATER = new CubesEnumMember();
  FIRST_NOW = new CubesEnumMember();
  NONE = new CubesEnumMember();
}

export class ActionType extends EnumType {



  DELIVERY = new CubesEnumMember("Delivery");
  PICKUP = new CubesEnumMember("Pickup");
  REPOSSESSION = new CubesEnumMember("Repossession");
  MOVEMENT = new CubesEnumMember("Movement");
}

export class TransactionType extends EnumType {
  InvoiceLine = new CubesEnumMember("Invoice Line");
  PaymentLine = new CubesEnumMember("Payment Line");
}

//Auction Available Late Lien Locked Out Moving Out Pending Pre-Lien Rented Reserved Unavailable
export class UnitStatus extends EnumType {




  Available = new CubesEnumMember("Available", "Unit is available to rent")
  Reserved = new CubesEnumMember("Reserved", "Unit has been reserved but rental hasn't started")
  Rented = new CubesEnumMember("Rented", "Unit is attached to an active rental")
  Released = new CubesEnumMember("Released", "Released from rental, empty, awaiting triage")
  Unavailable = new CubesEnumMember("Unavailable", "Unit not available to rent")
}

export class RentalStatus extends EnumType {


  /* __builtin = false */
  Reserved = new CubesEnumMember("Reserved", "Rental is waiting for a unit.")
  Scheduled = new CubesEnumMember("Scheduled", "Rental is scheduled and unit is attached.")
  Rented = new CubesEnumMember("Rented", "Rental is active with unit attached.")
  Moving_Out = new CubesEnumMember("Moving Out", "Rental scheduled for pickup/moveout.")
  Completed = new CubesEnumMember("Finalizing", "Rental ended but unit still attached.")
  Retained = new CubesEnumMember("Held", "Rental ended but unit has been retained for some reason.")
  Released = new CubesEnumMember("Released", "Rental ended and unit released.")
  Archived = new CubesEnumMember("Archived", "Rental is permenantly archived and cannot be modified.")
  RentToOwn = new CubesEnumMember("Rent to Own", "Rental is a rent to own agreement.");
  SoldToCustomer = new CubesEnumMember("Sold to Customer", "Unit was sold to this customer.");
}

export class SignupSource extends EnumType {
  Branch = new CubesEnumMember("Branch");
  Account = new CubesEnumMember("Account");
}

export class BillingStatus extends EnumType {

  /* __builtin = false */

  Normal = new CubesEnumMember("Normal", "Customer current")
  Late = new CubesEnumMember("Late", "Customer past due")
  Locked_Out = new CubesEnumMember("Locked Out", "Customer locked out, unit still in use")
  Auction = new CubesEnumMember("Auction", "Contents to be auctioned. Unit may be released.")

}

export class CustomerType extends EnumType {
  Residential = new CubesEnumMember("Residential");
  Commercial = new CubesEnumMember("Commercial");
}


export class PersonIdentityDocType extends EnumType {



  DRIVERS_LICENSE = new CubesEnumMember("Driver's License");
  GREEN_CARD = new CubesEnumMember("US Green Card");
  PASSPORT = new CubesEnumMember("US or foreign passport");
  SSN = new CubesEnumMember("Social Security Number");
}


export class PersonIdentityDoc extends RecordType {



  Type = new PersonIdentityDocType(false, true, new field({ title: "Type" }));
  FullName = new String(true, new field({ title: "Full Name" }));
  DateOfBirth = new ScalarDate("yyyy-MM-dd", true, new field({ title: "Date of Birth", filterType: "date" }));
  Issuer = new String(
    true,
    new field({
      title: "Issuer",
      helptext: "State (for driver's license) or Country listed on ID",
    })
  );
  Number = new String(true, new field({ title: "Number" }));
  Expiration = new ScalarDate("yyyy-MM-dd", true, new field({ title: "Expiration", filterType: "date" }));
  Present = new Boolean(
    false,
    new field({
      title: "Document Presented",
      helptext:
        "Did the customer physically present his ID to you for verification?",
      filterType: "boolean"
    })
  );

  Photos = new IsArray(new FileUpload(false, new field({
    title: "Photos of ID",
  })));

}

export class GeocodeAddress extends RecordType {

  description = new String(
    false,
    new field({
      title: "Address",
    })
  );

}

export class NoticeTypes extends EnumType {




  // Account_Created = new CubesEnumMember("Account Created", "Automatically sent to a customer after their email is entered on the site.");
  Storage_Agreement = new CubesEnumMember("Storage Agreement", "The subject and text to include in the Docusign storage agreement email.");
  Login_Link = new CubesEnumMember("Login Link (not used)", "Email sent when a user requests a login link");

  Verify_Email = new CubesEnumMember("Verify Email", "Email sent requesting the user to confirm their email address.");
  Change_Email = new CubesEnumMember("Email Changed", "Sent to the old verified email address when the customer's email changes.");
  Verify_Customer_Email = new CubesEnumMember("Verify Customer Email", "Sent to a customer to give to a branch to verify email ownership.");
  Invoice_Reminder = new CubesEnumMember("Invoice Reminder", "Sent 14 and 2 days before the charge date for manual pay customers.");
  Autopay_Reminder = new CubesEnumMember("Autopay Reminder", "Sent 14 and 2 days before the charge date for auto pay customers.");

  Customer_IS_TESTING = new CubesEnumMember("Customer IS TESTING", "Sent to the verified email of a customer when the IS_TESTING status changes.")
  Reset_Password = new CubesEnumMember("Reset Password", "Link containing the code to allow the customer to update their password.")

  Payment_Receipt = new CubesEnumMember("Automatic Payment Receipt", "Automatically sent to a customer immediately following a successful payment.");
  Failed_Recurring_Payment = new CubesEnumMember("Failed Recurring Payment", "Automatically sent to a customer after a failed autopay.");

  Payment_Due = new CubesEnumMember("Payment Due", "Automatically sent to a customer on any rental due date when they have a balance past due.");
  Late_Fee_Assessed = new CubesEnumMember("Late Fee Assessed", "Automatically sent to a customer when a late fee is assessed.");

  Rental_Rented = new CubesEnumMember("Rental Rented", "Automatically sent to a customer when rental status changes (forward) to Rented");
  Rental_Moving_Out = new CubesEnumMember("Rental Moving Out", "Automatically sent to a customer when rental status changes (forward) to Moving Out");
  Rental_Completed = new CubesEnumMember("Rental Finalizing", "Automatically sent to a customer when rental status changes (forward) to Finalizing");

  // Invoice_Reminder = new CubesEnumMember("Invoice Reminder", "Automatically sent to a customer to remind them of an upcoming payment.");
  // Move_Out_Receipt = new CubesEnumMember("Move Out Receipt", "Automatically sent to a customer after successfully moving out of a rental.");
  // Scheduled_Rate_Change = new CubesEnumMember("Notice of Scheduled Rate Change", "Manually sent to one or more customers when scheduling rental price changes.");

  // Rental_Reserved = new CubesEnumMember("Rental Instructions", "Automatically sent to a customer a when rental status changes (forward) to Reserved");
  // Rental_Scheduled = new CubesEnumMember("Rental Instructions", "Automatically sent to a customer af when rental status changes (forward) to Scheduled");
  // Rental_Retained = new CubesEnumMember("Rental Instructions", "Automatically sent to a customer when rental status changes (forward) to Retained");
  // Rental_Released = new CubesEnumMember("Rental Instructions", "Automatically sent to a customer when rental status changes (forward) to Released");
  // Rental_Archived = new CubesEnumMember("Rental Instructions", "Automatically sent to a customer when rental status changes (forward) to Archived");
  // Reservation_Receipt = new CubesEnumMember("Reservation Receipt", "Automatically sent to a customer after successfully reserving a unit. Manually sent when viewing an active reservation.");
  // Scheduled_Move_Out = new CubesEnumMember("Scheduled Move Out", "Automatically sent to a customer when a rental is scheduled for move out on a future date.");
  // Storage_Agreement = new CubesEnumMember("Storage Agreement", "Manually sent to a customer when viewing a storage agreement.");
  // Waiting_List_Confirmation = new CubesEnumMember("Waiting List Confirmation", "Automatically sent to a customer immediately after they are added to the waiting list.");
  // Past_Due_Notice = new CubesEnumMember("Past Due Notice");
  // Lockout_Notice = new CubesEnumMember("Lockout Notice", "Notice of Lockout of Storage Unit and Intended Sale of Personal Property At Auction");
  // Lien_Notice = new CubesEnumMember("Lien Notice", "Notice of Foreclosure of Lien and Sale of Personal Property at Auction");
}

export class NoticeTemplate extends TableType {
  static Invoice_Reminder_Tags = [
    "ACCOUNT_LINK", "CUBE_NAME", "DAYS_UNTIL", "PAYMENT_LOGIN_LINK"
  ] as const;
  static Autopay_Reminder_Tags = [
    ...this.Invoice_Reminder_Tags, "PAYMENT_TYPE", "PAYMENT_NUMBER"
  ] as const;
  static all_tags = {
    "Storage_Agreement": ["STORAGE_AGREEMENT_LINK"] as const,
    "Login_Link": ["PAYMENT_LOGIN_LINK"] as const,

    "Invoice_Reminder": NoticeTemplate.Invoice_Reminder_Tags.slice(),
    "Autopay_Reminder": NoticeTemplate.Autopay_Reminder_Tags.slice(),

    "Payment_Receipt": ["ACCOUNT_LINK", "AMOUNT", "DATE"] as const,
    "Failed_Recurring_Payment": ["ACCOUNT_LINK", "AMOUNT", "DATE", "ERROR_MESSAGE"] as const,

    "Rental_Rented": ["ACCOUNT_LINK", "CUBE_NAME", "CUBE_TYPE", "START_DATE", "PRICE_MARKUP"] as const,
    "Rental_Moving_Out": ["ACCOUNT_LINK", "CUBE_NAME", "CUBE_TYPE", "START_DATE", "END_DATE"] as const,
    "Rental_Completed": ["ACCOUNT_LINK", "CUBE_NAME", "CUBE_TYPE", "START_DATE", "END_DATE"] as const,

    "Verify_Email": ["CONFIRM_EMAIL_LINK"] as const,
    "Verify_Customer_Email": ["CONFIRM_EMAIL_CODE", "CODE_FIRST_HALF", "CODE_SECOND_HALF"] as const,
    "Customer_IS_TESTING": ["ACCOUNT_LINK"] as const,
    "Change_Email": ["ACCOUNT_LINK"] as const,

    "Reset_Password": ["RESET_PASSWORD_LINK", "RESET_PASSWORD_CODE"] as const,

    "Late_Fee_Assessed": ["ACCOUNT_LINK", "BALANCE", "PAST_DUE", "LATE_FEE"] as const,
    "Payment_Due": ["ACCOUNT_LINK", "BALANCE", "PAST_DUE"] as const,

  } satisfies { [K in MemberKeys<NoticeTypes>]: readonly string[] };

  // static email_body = {
  //   Payment_Receipt: `
  //     <p>Thank you for your payment of $AMOUNT on $DATE. You can view your account at $ACCOUNT_LINK.</p>
  //   `,
  //   Failed_Recurring_Payment: `
  //     <p>Your autopay payment of $AMOUNT on $DATE failed. Please update your payment method at $ACCOUNT_LINK.</p>
  //   `,
  // } satisfies  { [K in MemberKeys<NoticeTypes>]?: string }

  static tag_body = {
    "ACCOUNT_LINK": "Link to the customer's account page",
    "CUBE_NAME": "Name of the storage facility",
    "DAYS_UNTIL": "Number of days until the payment is due",
    "PAYMENT_LOGIN_LINK": "Link to the payment login page",
    "PAYMENT_TYPE": "Type of payment method",
    "PAYMENT_NUMBER": "Last 4 digits of the payment method",
    "CONFIRM_EMAIL_LINK": "Link to confirm the customer's email",
    "RESET_PASSWORD_LINK": "Link to reset the customer's password",
    "RESET_PASSWORD_CODE": "Code to reset the customer's password",
    "AMOUNT": "Amount of the payment",
    "DATE": "Date of the payment",
    "START_DATE": "Start date of the rental",
    "NEW_EMAIL": "New email address",
    "OLD_EMAIL": "Old email address",
    "IS_PROD": "Whether the account is in production or testing"
  } as const;


  __init__ = void this.__typeDirectivesAdd(
    ...__TableDirectives<NoticeTemplate>({
      heading: "Notice Templates",
      sec: rls.NoticeTemplate
    }),
    new mapsTo({ name: "NoticeTemplates" }),
  );

  NoticeType = new NoticeTypes(false, true, new field({ unique: true, preventUpdate: true, title: "Notice" }));

  EmailSubject = new String(false, new field({ title: "Email Subject" }));
  EmailBody = new Member(EmailDoc, false,
    ...Object.entries(NoticeTemplate.all_tags).map(([NoticeType, tags]) => new noticeTags({ NoticeType, tags: [...tags] }))
  );
  TextMessage = new String();
  Letter = new String();

}

export class DocusignEnvelopeStatus extends EnumType {


  none = new CubesEnumMember("None", "None started yet");
  created = new CubesEnumMember("Created", "The envelope is created as a draft. It can be modified and sent later.");
  sent = new CubesEnumMember("Sent", "The envelope will be sent to the recipients after the envelope is created.");
  delivered = new CubesEnumMember("Delivered", "The envelope has been delivered to the recipients.");
  signed = new CubesEnumMember("Signed", "The envelope has been signed by the recipients.");
  completed = new CubesEnumMember("Completed", "The recipients have finished working with the envelope: the documents are signed and all required tabs are filled in.");
  declined = new CubesEnumMember("Declined", "The envelope has been declined by the recipients.");
  voided = new CubesEnumMember("Voided", "The envelope is no longer valid and recipients cannot access or sign the envelope.");
}

export class TxnType extends EnumType {
  greenpay_cc = new CubesEnumMember("Greenpay Credit Card");
  greenpay_ck = new CubesEnumMember("Greenpay Check");
  stripe_connected_account = new CubesEnumMember("Stripe Connected Account");
}

export class DocusignEnvelopeInfo extends RecordType {



  status = new DocusignEnvelopeStatus(true, true, new field({}));
  envelopeId = new ID(true, new field({}));
}

export class CacheQuery extends EnumType {



  ShortLookup = new CubesEnumMember();

}

export class NotificationPreferences extends RecordType {



  NotifyEmail = new IsArray(
    new NoticeTypes(false, false, new field({ title: "Email" }))
  );
  NotifySMS = new IsArray(
    new NoticeTypes(false, false, new field({ title: "SMS" }))
  );
  NotifyFax = new IsArray(
    new NoticeTypes(false, false, new field({ title: "Fax" }))
  );
}


export class ContactInfoType extends RecordType {



  Name = new String(true, new field({ title: "Name", }));
  Address = new Member(GeocodeAddress, false);
  Phone = new ScalarPhone(false);
  Fax = new ScalarPhone(false, new field({ hidden: true, }));
  Notes = new String(false);
}

export class StorageAgreement extends RecordType {



  IsSigned = new Boolean();

  Jurisdiction = new String(
    true,
    new field({
      title: "Agreement Jurisdiction",
      preventUpdate: true
    })
  );

  Attachments = new IsArray(new Member(FileUpload, false));

  Signator = new Member(PersonIdentityDoc);

}

export class EmailDoc extends RecordType {



  html = new String()
  text = new String()

}

export class RentalType extends EnumType {



  Estimate = new CubesEnumMember("Estimate");
  Reservation = new CubesEnumMember("Reservation");
  Active = new CubesEnumMember("Active");
}

export class InvoiceLineCreateType extends RecordType {



  rentalID = selectID(Rental, InvoiceLine, {
    required: true,
    field: {
      hidden: true,
      preventUpdate: true,
    }
  });

  Date = new ScalarDate("yyyy-MM-dd", true);

  itemID = selectID(Item, InvoiceLine, {
    required: true,
    field: {
      arrayList: (x => [
        x.ItemName.__,
        x.Description.__,
      ]),
      title: "Item Name",
      preventUpdate: true,
    },
    filterWhere: [{ ItemType: { not: "Rental" } }]
  });

  Description = new String();

  Amount = new CubesDinero(true, new field({}));

}

export class Transaction extends TableType {


  __init__ = void this.__typeDirectivesAdd(
    ...__TableDirectives<Transaction>({
      heading: "Transactions",
      sec: rls.Transaction,
    })
  );

  IS_TESTING = new Boolean(true, new field({
    title: "Transaction for Testing Purposes",
    helptext: "Transactions will be ignored and payments will be made through a test gateway.",
  }), new index({}));

  // this should not have an index because hardly any rows are voided, eventually I may even remove the voided rows or unvoid them.
  VoidSince = new ScalarDateTime(undefined, false, new field({ hidden: true, title: "Voided", filterType: "date" }));
  Date = new ScalarDate("yyyy-MM-dd", true, new field({ title: "Date", filterType: "date" }));
  Description = new String();
  // LineType = new TransactionType(false);

  salesTaxLedgerLine = hasManyField(SalesTaxLedger, { childField: "line" });
  customerLedgerLine = hasOneField(CustomerLedger, Transaction, "line");

  centralLedgerLine = hasOneField(CentralLedger, Transaction, "line");
  divisionLedgerLine = hasOneField(DivisionLedger, Transaction, "line");
  branchLedgerLine = hasOneField(BranchLedger, Transaction, "line");

  ownerLedgerLine = hasOneField(OwnerLedger, Transaction, "line");

  centralDiscountLedgerLine = hasOneField(CentralDiscountLedger, Transaction, "line");
  divisionDiscountLedgerLine = hasOneField(DivisionDiscountLedger, Transaction, "line");
  branchDiscountLedgerLine = hasOneField(BranchDiscountLedger, Transaction, "line");

  invoiceLine = hasOneField(InvoiceLine, Transaction, "line", {});
  paymentLine = hasOneField(PaymentLine, Transaction, "line", {});

}

export class GreenpayTransaction extends TableType {
  __init__ = void this.__typeDirectivesAdd(
    ...__TableDirectives<GreenpayTransaction>({
      heading: "Payment Lines",
      sec: rls.GreenpayTransaction,
    }),
  );

  txnID = new String(true, new field({ unique: true }));
  json = new ScalarJSON("GreenpayTransaction_json", true);
}

export class PaymentStatus extends EnumType {

  __builtin: boolean = true;

  Validated = new CubesEnumMember();
  Approved = new CubesEnumMember();
  Declined = new CubesEnumMember();
  Cleared = new CubesEnumMember();
  Bounced = new CubesEnumMember();
  Voided = new CubesEnumMember();

}

export class PaymentLedger extends EnumType {

  __builtin: boolean = true;

  Customer = new CubesEnumMember();
  Central = new CubesEnumMember();
  Branch = new CubesEnumMember();
  Owner = new CubesEnumMember();
  SalesTax = new CubesEnumMember();
  Division = new CubesEnumMember();
}

export class PaymentLine extends TableType {

  __init__ = void this.__typeDirectivesAdd(
    ...__TableDirectives<PaymentLine>({
      heading: "Payment Lines",
      sec: rls.PaymentLine,
    }),
  );

  line = selectRelation(Transaction, PaymentLine, {
    required: true,
    onUpdate: "Restrict",
    onDelete: "Cascade",
    field: {
      title: "Transaction",
      unique: true,
      preventUpdate: true,
      arrayList: (x => [
        x.Date.__,
        x.VoidSince.__,
        x.customerLedgerLine.Amount.__,
        x.branchLedgerLine.Amount.__,
        x.centralLedgerLine.Amount.__,
        x.ownerLedgerLine.Amount.__,
      ])
    }
  });


  txnID = new String(true, new field({ unique: true }));

  PaymentStatus = new PaymentStatus(false, true, new field({ title: "Status" }));
  PaymentLedger = new PaymentLedger(false, false);
  PaymentAmount = new CubesDinero(false);
  PaymentFee = new CubesDinero(true);
  PaymentTxnType = new TxnType(false, false);
  PaymentApproved = new ScalarDateTime("yyyyMMddHHmmss", false, new field({ title: "Approved", filterType: "date" }));
  PaymentCleared = new ScalarDateTime("yyyyMMddHHmmss", false, new field({ title: "Cleared", filterType: "date" }));

  FullyApplied = new Boolean(false, new index({}));

  refunding = selectRelation(PaymentLine, PaymentLine, { required: false, field: { hidden: true } });
  Refunds = hasManyField(PaymentLine, { childField: "refunding", hidden: true });

  autopayLines = hasManyField(AutopayAttempt, {
    childField: "paymentLine",
    preventCreate: true,
    preventUpdate: true,
    hidden: true,
  });

  // InvoiceLines = hasManyField(InvoicePayment, { childField: "paymentLine", hidden: true });

}

// export class Invoice extends TableType {

//   __init__ = void this.__typeDirectivesAdd(
//     ...__TableDirectives<InvoiceLine>({
//       heading: "Invoice Lines",
//       sec: rls.Invoice
//     })
//   );

//   InvoiceType = new InvoiceType(true, new field({ title: "Type" }));

//   Status = new InvoiceStatus(true, new field({ title: "Status" }));

//   Date = new ScalarDate("yyyy-MM-dd", true, new field({ title: "Date", filterType: "date" }));

//   customer = selectRelation(Customer, Invoice, {
//     required: true,
//     field: {
//       title: "Customer",
//       arrayList: x => [
//         x.billing.Name.__,
//         x.Email.__,
//       ]
//     }
//   });

//   Lines = hasManyField(InvoiceLine, {
//     childField: "invoice",
//     preventCreate: true,
//     preventUpdate: true,
//     hidden: true,
//   });

// }

// export class InvoicePayment extends TableType {

//   __init__ = void this.__typeDirectivesAdd(
//     ...__TableDirectives<InvoicePayment>({
//       heading: "Invoice Payments",
//       sec: rls.InvoicePayment
//     }),
//     new uniquePrisma<InvoicePayment>({ fields: ["invoiceLine", "paymentLine"] })
//   );
//   constructor(...args: CP<typeof TableType>) {
//     super(...args);
//     this.id = new ID(true, new field({ hidden: true, onlyfor: ['UPDATE', 'DELETE'] }), new primaryKey({
//       // default: `(lpad(to_hex(nextval('InvoicePaymentID')), 8, '0') || '0000-0000-0000-000000000000')::uuid`,
//       default: `((lpad(to_hex(nextval('invoicepaymentid'::regclass)), 8, '0'::text) || '0000-0000-0000-000000000000'::text))::uuid`
//     }));
//   }
//   invoiceLine = selectRelation(InvoiceLine, InvoicePayment, { required: true, index: {}, field: {} });
//   paymentLine = selectRelation(PaymentLine, InvoicePayment, { required: true, index: {}, field: {} });
//   amountPaymentAppliedToInvoice = new CubesDinero(true, new field({}), new mapsTo({ name: "amount" }));
//   feePaymentAppliedToInvoice = new CubesDinero(false, new field({}), new mapsTo({ name: "fee" }));
// }

export class InvoiceLine extends TableType {



  __init__ = void this.__typeDirectivesAdd(
    ...__TableDirectives<InvoiceLine>({
      heading: "Invoice Lines",
      sec: rls.InvoiceLine
    })
  );

  // invoice = selectRelation(Invoice, InvoiceLine, {
  //   required: false,
  //   onUpdate: "Restrict",
  //   onDelete: "SetNull",
  //   field: {
  //     title: "Invoice",
  //     hidden: true,
  //   },
  //   index: {}
  // });

  line = selectRelation(Transaction, InvoiceLine, {
    required: true,
    onUpdate: "Restrict",
    onDelete: "Cascade",
    field: {
      title: "Transaction",
      unique: true,
      preventUpdate: true,
      arrayList: x => [
        x.Date.__,
        x.VoidSince.__,
        x.customerLedgerLine.Amount.__,
        x.branchLedgerLine.Amount.__,
        x.centralLedgerLine.Amount.__,
        x.ownerLedgerLine.Amount.__,
      ]
    }
  });

  // PaymentLines = hasManyField(InvoicePayment, { childField: "invoiceLine", hidden: true });

  item = selectRelation(Item, InvoiceLine, {
    required: true,
    field: {
      title: "Item",
      preventUpdate: true,
      arrayList: x => [
        x.ItemName.__,
        x.Description.__
      ]
    }
  });

  rental: Member<Rental, true> = selectRelation(Rental, InvoiceLine, {
    required: false,
    field: {
      title: "Rental",
      preventUpdate: true,
      arrayList: x => [
        x.activeUnit.currentLocation.description.__,
        x.unit.Name.__,
      ]
    }
  });

  promotion = selectRelation(Promotion, InvoiceLine, { required: false, field: {} });

  // an index here is only helpful if we need to filter on a specific date, not just on not null
  paidOn = new ScalarDate("yyyy-MM-dd", false, new field({ title: "Paid On", filterType: "date" }));
  paidOnOld = new ScalarDate("yyyy-MM-dd", false, new field({ title: "Paid On", filterType: "date" }));

  BranchPaymentFee = new CubesDinero(false);
  branch = selectRelation(Branch, InvoiceLine, { required: false, field: { hidden: true } });

  OwnerPaymentFee = new CubesDinero(false);
  owner = selectRelation(Owner, InvoiceLine, { required: false, field: { hidden: true } });

  PriceInfo = new ScalarJSON("InvoiceLine_PriceInfo", false, new field({ hidden: true }));

  // it's wierd but this refers to a different row in the same table
  lateFee = selectRelation(InvoiceLine, InvoiceLine, { required: false, field: { hidden: true } });
  lateFeeFor = hasOneField(InvoiceLine, InvoiceLine, "lateFee", { hidden: true });

  autopayAttempts = hasManyField(AutopayAttempt, {
    childField: "invoiceLine",
    preventCreate: true,
    preventUpdate: true,
    hidden: true,
  });

}

export class AutopayAttempt extends TableType {

  __init__ = void this.__typeDirectivesAdd(
    ...__TableDirectives<AutopayAttempt>({
      heading: "Autopay Attempts",
      sec: rls.AutopayAttempt
    }),
    new uniquePrisma<AutopayAttempt>({ fields: ["invoiceLine", "paymentLine"] })
  );

  invoiceLine = selectRelation(InvoiceLine, AutopayAttempt, { required: true, field: { hidden: true } });

  paymentLine = selectRelation(PaymentLine, AutopayAttempt, { required: false, field: { hidden: true } });

  date = new ScalarDate("yyyy-MM-dd", true, new field({ title: "Date", filterType: "date" }));
  success = new Boolean(true, new field({ title: "Success", filterType: "boolean" }));
  reason = new String(false, new field({ title: "Reason" }));
  txnID = new String(false, new field({ title: "Transaction ID" }));

}



export class CustomerLedger extends BaseLedger {


  __init__ = void this.__typeDirectivesAdd(
    ...this.__LedgerDirectivesOther(Customer, x => [], "Accounts_Receivable", rls.CustomerLedger));

  customer: Member<Customer, true> = selectRelation(Customer, CustomerLedger, {
    required: true, field: { title: "Customer", arrayList: (x => [x.billing.Name.__]) },
  })

}

export class BranchLedger extends BaseLedger {


  __init__ = void this.__typeDirectivesAdd(
    ...this.__LedgerDirectivesOther(Branch, x => [x.branch.DisplayName.__], "Accounts_Payable", rls.BranchLedger),

  );

  branch: Member<Branch, true> = selectRelation(Branch, BranchLedger, {
    required: false, field: { title: "Branch", arrayList: x => [x.DisplayName.__] }
  });

}

export class BranchDiscountLedger extends BaseLedger {


  __init__ = void this.__typeDirectivesAdd(
    ...this.__LedgerDirectivesOther(Branch, x => [x.branch.DisplayName.__], "Expense", rls.BranchDiscountLedger, true),
  );

  branch: Member<Branch, true> = selectRelation(Branch, BranchDiscountLedger, {
    required: false, field: { title: "Branch", arrayList: (x => [x.DisplayName.__]) }
  })

  promotion = selectRelation(Promotion, BranchDiscountLedger, {
    required: true, field: { title: "Promotion", arrayList: (x => [x.Title.__, x.Notes.__]) }
  })

}

export class SalesTaxLedger extends BaseLedger {


  __init__ = void this.__typeDirectivesAdd(
    ...this.__LedgerDirectivesOther(Branch, x => [x.branch.DisplayName.__], "Accounts_Payable", rls.SalesTaxLedger),
  );


  branch: Member<Branch, true> = selectRelation(Branch, SalesTaxLedger, {
    required: false,
    field: { title: "Branch", arrayList: (x => [x.DisplayName.__]) }
  })

  TaxJurisdiction = new String(true, new index({}), new field({ title: "Jurisdiction" }));

  TaxPercent = new Float();

}
export class OwnerLedger extends BaseLedger {


  __init__ = void this.__typeDirectivesAdd(
    ...this.__LedgerDirectivesOther(Owner, x => [x.owner.billing.Name.__], "Accounts_Payable", rls.OwnerLedger),
  );

  owner: Member<Owner, true> = selectRelation(Owner, OwnerLedger, {
    required: false,
    field: { title: "Owner", arrayList: (x => [x.billing.Name.__, x.billing.Address.description.__]) }
  })

}

export class CentralLedger extends BaseLedger {


  __init__ = void this.__typeDirectivesAdd(
    ...this.__LedgerDirectivesCentral("Accounts_Payable", rls.CentralLedger),
  );


}


export class CentralDiscountLedger extends BaseLedger {


  __init__ = void this.__typeDirectivesAdd(
    ...this.__LedgerDirectivesCentral("Expense", rls.CentralDiscountLedger, true),
  );

  promotion = selectRelation(Promotion, CentralDiscountLedger, {
    required: true,
    field: {
      title: "Promotion",
      arrayList: (x => [
        x.Title.__,
        x.Notes.__
      ])
    }
  });
}


export class DivisionLedger extends BaseLedger {

  __init__ = void this.__typeDirectivesAdd(
    ...this.__LedgerDirectivesOther(Division, x => [x.division.Name.__], "Accounts_Payable", rls.DivisionLedger),
  );

  division: Member<Division, true> = selectRelation(Division, DivisionLedger, {
    required: false,
    field: { title: "Division", arrayList: (x => [x.Name.__]) }
  });

}

export class DivisionDiscountLedger extends BaseLedger {


  __init__ = void this.__typeDirectivesAdd(
    ...this.__LedgerDirectivesOther(Division, x => [x.division.Name.__], "Expense", rls.DivisionDiscountLedger, true),
  );

  promotion: Member<Promotion, true> = selectRelation(Promotion, DivisionDiscountLedger, {
    required: true,
    field: { title: "Promotion", arrayList: (x => [x.Title.__, x.Notes.__]) }
  });


  division = selectRelation(Division, DivisionDiscountLedger, {
    required: false,
    field: { title: "Division", arrayList: x => [x.Name.__], }
  });

}


export class CentralPage extends RecordType {


}

export class CentralHoldingLine extends RecordType {
  Name = new String(true, new field({ title: "Name" }));
  Amount = new CubesDinero(true, new field({ title: "Amount" }));
}

export class ItemType extends EnumType {

  Rental = new CubesEnumMember("Rental");
  Penalty = new CubesEnumMember("Penalty");
  Credit = new CubesEnumMember("Credit");
  Repairs = new CubesEnumMember("Repairs");
  Movement = new CubesEnumMember("Movement");
  Insurance = new CubesEnumMember("Insurance");

}

export class ChargeSource extends EnumType {



  UnitType = new CubesEnumMember("Unit Type");
  Rental = new CubesEnumMember("Rental");
  Customer = new CubesEnumMember("Customer");
  Jurisdiction = new CubesEnumMember("Jurisdiction");
}

// const Promotions_AllRentals_arrayList: SPPI<any>[] = ;
// const BranchPercentHelptext = 

export class Promotion extends TableType {
  static extraForms = {
    PromotionCREATE: ["Title", "Notes", "PercentOffBranch", "PercentOffCentral", "BillingCycles", "FreeRental"]
  } as const satisfies Record<string, readonly MemberKeys<Promotion>[]>;

  __init__ = void this.__typeDirectivesAdd(
    ...__TableDirectives<Promotion>({
      heading: "Promotions",
      sec: rls.Promotion,
      extraForms: Promotion.extraForms
    })
  );
  Title = new String(true, new field({ title: "Title" }));
  Notes = new String(false, new field({ title: "Notes" }));
  PercentOffBranch = new Float(true, new field({
    title: "Branch Discount",
    helptext: `Percent of rental price to remove from branch's portion. Max ${__rents.RENT_BRANCH}.`
  }))
  PercentOffCentral = new Float(true, new field({
    title: "Central Discount",
    helptext: `Percent of rental price to remove from central's portion. Max ${__rents.RENT_CENTRAL}.`
  }))
  FreeRental = new Boolean(false, new field({
    title: "Free Rental",
    helptext: "This promotion makes the rental free for the months applied. It will not apply any other discounts or charges.",
  }))
  BillingCycles = new Int(true, new field({
    title: "Months Applied",
    helptext: "Number of months to apply this promotion to.",
  }));
  AllRentals: IsArray<Member<Rental>> = hasManyField(Rental, {
    childField: "promotion",
    arrayList: x => [
      x.customer.billing.Name.__,
      x.StartDate.__,
      x.EndDate.__,
    ],
    preventCreate: true,
    preventUpdate: true,
  });

  branchDiscountLines = hasManyField(BranchDiscountLedger, { childField: "promotion" });
  divisionDiscountLines = hasManyField(DivisionDiscountLedger, { childField: "promotion" });
  centralDiscountLines = hasManyField(CentralDiscountLedger, { childField: "promotion" });
  invoiceLines = hasManyField(InvoiceLine, { childField: "promotion" });
}

export class RentalCreateType extends RecordType {

  customerID = selectID(Customer, Rental, {
    required: true,
    field: {
      title: "Customer",
      preventUpdate: true,
      hidden: true,
      arrayList: (x => [
        x.billing.Name.__,
        x.Email.__,
        x.billing.Phone.__,
      ]),
    }

  });

  unitTypeID = selectID(UnitType, Rental, {
    required: true,
    field: {
      arrayList: (x => [
        x.Name.__,
        x.Description.__,
        x.RentalPrice.__,
      ]),
      arraySort: (x => [
        x.Name.__,
      ]),
      title: "Unit Type",
      preventUpdate: true,
      clientSideOnly: true,
      onlyfor: [],
    }
  });

  currentBranchID = selectID(Branch, Rental, {
    required: true,
    // filterWhere: [{ BranchType: "DEALER" }],
    field: {
      arrayList: (x => [
        x.DisplayName.__,
        x.BranchType.__,
      ]),
      arraySort: (x => [
        x.BranchType.__,
        x.DisplayName.__,
      ]),
      title: "Branch",
      preventUpdate: true,
      clientSideOnly: true,
      onlyfor: [],
    }
  });

  unitID = selectID(Unit, Rental, {
    required: true,
    filterWith: {
      unitType: { filterWith: "unitTypeID", onlyfor: ["CREATE"] },
      currentBranch: { filterWith: "currentBranchID", onlyfor: ["CREATE"] },
    },
    field: {
      arrayList: (x => [
        x.Name.__,
        x.unitType.Name.__,
        x.Unavailable.__,
        x.currentRental.RentalStatus.__,
        x.currentRental.StartDate.__,
        x.currentRental.EndDate.__,
      ]),
      arraySort: (x => [
        x.AvailableSince.__,
      ]),
      title: "Unit",
      preventUpdate: true,
    }
  });

  rentals = new IsArray(new Member(Rental, false,
    new field<Rental, RentalCreateType>({
      title: "Current Rentals for this Unit",
      preventCreate: true,
      preventUpdate: true,
      arrayList: (x => [
        x.customer.billing.Name.__,
        x.RentalStatus.__,
        x.StartDate.__,
        x.EndDate.__,
      ]),
      arraySort: (x => [

      ]),
    }),
    new lookup<Rental, RentalCreateType>({
      targetTable: "Rental",
      optionFilterWith: [
        { filterWith: "unitID", filterThis: "unit" },

      ],
      optionFilterWhere: [
        { RentalStatus: { notIn: ["Released", "Archived"] } },
      ]
    })
  ))

  promotionID = selectID(Promotion, Rental, {
    required: false,
    field: {
      arrayList: (x => [
        x.Title.__,
        x.Notes.__,
      ]),
      title: "Promotion",
    }
  });

  StartDate = new ScalarDate(
    "yyyy-MM-dd",
    true,
    new field({
      title: "Start Date",
      filterType: "date",
    })
  );

  RentToOwnTotal = new CubesDinero(false, new field({
    title: "Rent To Own total price",
    helptext: "Total price for the Rent to Own cube that the customer needs to pay to own it. You can change this later if necessary."
  }));

  IsRentToOwn = new Boolean(false, new field({
    hidden: true,
  }));

}



export class RentalCharge extends RecordType {

  Date = new ScalarDate("yyyy-MM-dd", true, new field({ title: "Date", filterType: "date", default: "now" }));

  ItemName = forKeyString(Item, RentalCharge, {
    field: { arrayList: (x => [x.ItemName.__, x.ItemType.__,]), title: "Item" },
    filterWhere: [{ ItemType: { notIn: ["Rental", "Penalty"] } }],
    optionValue: "ItemName",
    required: true,
  });

  Amount = new CubesDinero(true, new field({ title: "Amount" }));

}


export class Rental extends TableType {
  static extraForms = {
    RentalFormInvoiceLines: ["RentalStatus", "InvoiceLines"],
    RentalFormUpdateStatus: ["RentalStatus", "promotion", "StartDate", "EndDate", "Photos", "IsRentToOwn", "PriceOverride", "RentToOwnTotal"],
    // RentalCREATE: [
    //   "customerID", 
    //   "unitTypeID", 
    //   "currentBranchID", 
    //   "unit", 
    //   // "Rentals", 
    //   "promotion", 
    //   "StartDate", 
    //   "RentToOwnTotal", 
    //   "IsRentToOwn", 
    // ]
  } as const satisfies Record<string, readonly MemberKeys<Rental>[]>

  __init__ = void this.__typeDirectivesAdd(
    ...__TableDirectives<Rental>({
      heading: "Rentals",
      filter: (x => [
        x.promotion.Title.__,
      ]),
      sec: rls.Rental,
      extraForms: Rental.extraForms
    })
  );

  RentalStatus = new RentalStatus(true, true, new field<RentalStatus, Rental>({
    default: "Reserved",
    title: "Rental Status",
    preventUpdate: true,
    filterType: "enum",
  }));

  customer = belongsToField(Customer, Rental, { root: "customerID" }, {});
  customerID = selectID(Customer, Rental, {
    belongsTo: "customer",
    required: true,
    field: {
      title: "Customer",
      preventUpdate: true,
      arrayList: (x => [
        x.billing.Name.__,
        x.Email.__,
        x.billing.Phone.__,
      ]),
    }

  });


  // unitType = belongsToField(UnitType, Rental, { root: "unitTypeID" }, {});
  unitTypeID = selectID(UnitType, Rental, {
    required: true,
    field: {
      arrayList: (x => [
        x.Name.__,
        x.Description.__,
        x.RentalPrice.__,
      ]),
      title: "Unit Type",
      preventUpdate: true,
      clientSideOnly: true,
      onlyfor: [],
    }
  });

  currentBranchID: ID = selectID(Branch, Rental, {
    required: true,
    field: {
      arrayList: (x => [
        x.DisplayName.__,
        x.BranchType.__,
      ]),
      title: "Branch",
      preventUpdate: true,
      clientSideOnly: true,
      onlyfor: [],
    }
  });

  unit: Member<Unit, true> = selectRelation(Unit, Rental, {
    required: true,
    filterWith: {
      unitType: { filterWith: "unitTypeID", onlyfor: ["CREATE", "UPDATE"] },
      currentBranch: { filterWith: "currentBranchID", onlyfor: ["CREATE"] },
    },
    field: {
      arrayList: (x => [
        x.Name.__,
        x.unitType.Name.__,
      ]),
      title: "Unit",
      preventUpdate: true,
    }
  });

  activeUnit = hasOneField(Unit, Rental, "currentRental", { hidden: true, onlyfor: [] });

  promotion = selectRelation(Promotion, Rental, {
    required: false,
    field: {
      arrayList: (x => [x.Title.__, x.Notes.__]),
      title: "Promotion",

    }
  });

  PriceOverride = new CubesDinero(false, new field({
    title: "Price Override",
    helptext: "Override the price for this rental."
  }));

  RentToOwnTotal = new CubesDinero(false, new field({
    title: "Rent To Own total price",
    helptext: "Total price for the Rent to Own cube that the customer needs to pay to own it."
  }));

  IsRentToOwn = new Boolean(false, new field({
    title: "Rent to Own",
    helptext: "This rental is a Rent to Own agreement.",
    preventUpdate: true,
    default: false
  }));

  StartDate = new ScalarDate(
    "yyyy-MM-dd",
    false,
    new field({
      title: "Start Date",
      filterType: "date",
    })
  );

  EndDate = new ScalarDate(
    "yyyy-MM-dd",
    false,
    new field({
      title: "End Date",
      filterType: "date",
    })
  );

  // Movements = hasManyField(Movement, { childField: "rentalID" });

  InvoiceLines = hasManyField(InvoiceLine, {
    childField: "rental",
    arrayList: ((x) => [
      x.line.customerLedgerLine.Amount.__,
      x.line.Date.__,
      x.paidOn.__,
      x.line.VoidSince.__,
    ]),
    preventCreate: true,
  });

  Photos = new IsArray(new Member(FileUpload, false))

  InvoiceReminderEmails = new IsArray(new ScalarJSON("InvoiceReminderEmail"));

}

export class FileUpload extends RecordType {
  title = new String();
  key = new String(true);
  type = new String(true);
  size = new Int(true);
  modified = new Int(true);
  exif = new ScalarJSON("FileUpload_exif", false);
  locationLat = new String(false);
  locationLon = new String(false);
  userID = new String(false);
}

export class Movement extends TableType {


  __init__ = void this.__typeDirectivesAdd(
    ...__TableDirectives({
      heading: "Movements",
      // auth: 
      sec: rls.Movement
    })
  );

}

export class DivisionBillingInfo extends ContactInfo {
  constructor() {
    super(rls.DivisionBillingInfo);
  }

  division = selectRelation(Division, DivisionBillingInfo, {
    required: true,
    field: { unique: true, hidden: true }
  });

  PercentRetail = new Float(true);

}

export class Division extends TableType implements PaymentHost<DivisionPaymentInfo> {
  static extraForms = {
    DivisionCREATE: ["Name"]
  } as const satisfies Record<string, readonly MemberKeys<Division>[]>;

  constructor() {
    super();

    this.__typeDirectivesAdd(
      ...__TableDirectives<Division>({
        heading: "Division",
        sec: rls.Division,
        extraForms: Division.extraForms,
      }),
    );
  }

  Name = new String(true, new field({ unique: true }));

  billing = hasOneField(DivisionBillingInfo, Division, "division", {

  });

  Owners: IsArray<Member<Owner>> = hasManyField(Owner, {
    childField: "division",
    preventCreate: true,
    arrayList: (x => [
      x.Email.__,
      x.billing.Name.__,
      x.billing.Address.description.__,
      x.OwnerPaymentSchedule.__,
    ])
  });

  Branches = hasManyField<Branch, Division>(Branch, {
    childField: "division",
    preventCreate: true,
    arrayList: (x) => [
      x.DisplayName.__,
      x.BranchType.__,
    ]
  });

  PaymentDetails = hasOneField(DivisionPaymentInfo, Division, "division", { hidden: true, onlyfor: [] });

  PaymentInfoValid = new Boolean(false, new field({
    title: "Payment Info",
    filterType: "boolean",
    preventCreate: true,
    preventUpdate: true,
    hidden: true,
  }));

  PaymentInfoFlags = new String<`${'c' | 'k' | 'n'}`>(false, new field({ hidden: true }));


  LedgerLines = hasManyField(DivisionLedger, {
    childField: "division",
    preventCreate: true,
    arrayList: x => [
      x.line.Date.__,
      x.line.invoiceLine.item.ItemName.__,
      x.line.invoiceLine.rental.unit.Name.__,
      x.line.invoiceLine.paidOn.__,
      x.line.paymentLine.PaymentStatus.__,
      x.Amount.__,
      x.line.VoidSince.__,
      { key: x.line.invoiceLine.id.__, hidden: true },
      { key: x.line.paymentLine.id.__, hidden: true },
    ],
    arraySort: x => ["-" + x.line.Date.__ as SPPI],
    arrayWhere: { line: WHERE_balanceWhereLine() },
  });

  DiscountLines: IsArray<Member<DivisionDiscountLedger>> = hasManyField(DivisionDiscountLedger, {
    childField: "division",
    arrayList: x => [
      x.line.Date.__,
      x.promotion.Title.__,
      x.line.invoiceLine.rental.unit.Name.__,
      x.Amount.__,
      x.line.VoidSince.__,
    ],
    arraySort: (x => [
      x.line.Date.__,
    ]),
    preventCreate: true,
    preventUpdate: true,
  });

  Groups: IsArray<Member<DivisionGroupChild, false>> = hasManyField(DivisionGroupChild, {
    childField: "child",
    preventCreate: true,
    preventUpdate: true,
    arrayList: x => [x.group.DisplayName.__, x.group.GroupType.__],
  });

}

export class BranchSalesTaxSummary extends RecordType {

  StartDate = new ScalarDate("yyyy-MM-dd", true, new field({ title: "Start Date", filterType: "date" }));
  EndDate = new ScalarDate("yyyy-MM-dd", true, new field({ title: "End Date", filterType: "date" }));

}

export class BranchUser extends TableType {
  static extraForms = {
    // BranchUserApprove: ["user", "branch"]
  } as const satisfies Record<string, readonly MemberKeys<BranchUser>[]>;

  constructor(...args: ConstructorParameters<typeof TableType>) {
    super(...args);
    this.__typeDirectivesAdd(
      ...__TableDirectives<BranchUser>({
        heading: "Branch Users",
        sec: rls.BranchUser,
        extraForms: BranchUser.extraForms
      }),
      new uniquePrisma<BranchUser>({ fields: ["user", "branch"] }),
    );
  }

  user: Member<User, true> = selectRelation(User, BranchUser, {
    field: {
      title: "User",
      arrayList: (x => [x.name.__, x.email.__,]),
      preventUpdate: true,
    },
  });

  branch: Member<Branch, true> = selectRelation(Branch, BranchUser, {
    field: {
      arrayList: x => [x.DisplayName.__, x.BranchType.__, x.division.Name.__]
    },
  });

}
export class BranchBillingInfo extends ContactInfo {

  static extraForms = {
    BranchTaxRates: ["TaxRates"]
  } as const satisfies Record<string, readonly MemberKeys<BranchBillingInfo>[]>;

  constructor() {
    super(rls.BranchBillingInfo);
  }

  branch = selectRelation(Branch, BranchBillingInfo, { required: true, field: { unique: true, hidden: true } });

  TaxRates = new IsArray(new Member(TaxRate, false, new field<TaxRate, Customer>({
    arrayList: (x => [x.TaxJurisdiction.__, x.TaxPercent.__]),
    title: "Sales Tax Table",
    helptext: "Enter the sales tax as a percent. For example, enter 1 to charge 1% sales tax.",
    default: [],
  })));



}


export class BranchContactInfo extends ContactInfo {

  static extraForms = {} as const satisfies Record<string, readonly MemberKeys<BranchContactInfo>[]>;

  constructor() {
    super(rls.BranchContactInfo);
  }

  branch = selectRelation(Branch, BranchContactInfo, { required: true, field: { unique: true, hidden: true } });

  Email = new String(false, new field({
    title: "Email",
    inputType: "email",
  }));

  Notes = new String(false, new field({
    title: "Notes",
    helptext: "Notes about this contact info.",
  }));

}

export class BranchLocations extends ContactInfo {

  constructor() {
    super(rls.BranchLocations);
  }

  branch = selectRelation(Branch, BranchLocations, { required: true, field: { hidden: true } })

}

export class Branch extends TableType implements PaymentHost<BranchPaymentInfo> {
  static extraForms = {
    BranchCREATE: ["BranchType", "DisplayName", "division"]
  } as const satisfies Record<string, readonly MemberKeys<Branch>[]>;

  constructor(
    ...args: ConstructorParameters<typeof TableType>
  ) {
    super(...args);
    this.__typeDirectivesAdd(
      ...__TableDirectives<Branch>({
        heading: "Branches",
        sec: rls.Branch,
        extraForms: Branch.extraForms
      }),
    );

  }


  BranchType = new BranchType(false, true, new index<Branch>({ sortKeyFields: ["DisplayName"] }), new field({
    title: "Type",
  }));

  DisplayName = new String(
    true,
    new field({
      title: "Branch",
    }),
    new index({})
  );

  division: Member<Division, true> = selectRelation<Division, Branch, any>(Division, Branch, {
    field: {
      title: "Division",
      arrayList: (x) => [x.Name.__],
    },
  });

  contactInfo = hasOneField(BranchContactInfo, Branch, "branch", { title: "Contact Info" });

  billing = hasOneField(BranchBillingInfo, Branch, "branch", { title: "Billing Info" });


  Locations = hasManyField(BranchLocations, {
    childField: "branch",
    arrayList: (x => [x.Name.__, x.Address.description.__, x.Phone.__]),
    title: "Locations"
  });

  CurrentUnits: IsArray<Member<Unit>> = hasManyField(Unit, {
    childField: "currentBranch",
    arrayList: x => [x.Name.__, x.unitType.Name.__, x.currentBranch.DisplayName.__],
    arraySort: x => [x.Name.__],
    title: "Current Units",
    hidden: true,
  });

  PaymentDetails = hasOneField(BranchPaymentInfo, Branch, "branch", { hidden: true, onlyfor: [] });

  PaymentInfoValid = new Boolean(false, new field({
    title: "Payment Info",
    filterType: "boolean",
    preventCreate: true,
    preventUpdate: true,
    hidden: true,
  }));

  PaymentInfoFlags = new String<`${'c' | 'k' | 'n'}`>(false, new field({ hidden: true }));

  LedgerLines = hasManyField(BranchLedger, {
    childField: "branch",
    arrayList: x => [
      x.line.Date.__,
      x.line.invoiceLine.item.ItemName.__,
      x.line.invoiceLine.rental.unit.Name.__,
      x.line.invoiceLine.paidOn.__,
      x.line.paymentLine.PaymentStatus.__,
      x.Amount.__,
      x.line.VoidSince.__,
      { key: x.line.invoiceLine.id.__, hidden: true },
      { key: x.line.paymentLine.id.__, hidden: true },
    ],
    arraySort: x => ["-" + x.line.Date.__ as SPPI],
    arrayWhere: { line: WHERE_balanceWhereLine() },
    preventCreate: true,
    // preventUpdate: true,
  });

  DiscountLines: IsArray<Member<BranchDiscountLedger>> = hasManyField(BranchDiscountLedger, {
    childField: "branch",
    arrayList: x => [
      x.line.Date.__,
      x.promotion.Title.__,
      x.line.invoiceLine.rental.unit.Name.__,
      x.Amount.__,
      x.line.VoidSince.__,
    ],
    arraySort: x => [
      x.line.Date.__,
    ],
    preventCreate: true,
    preventUpdate: true,
    title: "Discount Ledger",
    hidden: true,
  });



  SalesTaxLines = hasManyField(SalesTaxLedger, {
    childField: "branch",
    arrayList: x => [x.line.Date.__, x.TaxJurisdiction.__, x.Amount.__, x.line.VoidSince.__],
    arraySort: x => [x.line.Date.__],
    preventCreate: true,
    preventUpdate: true,
    hidden: true
  });

  InvoiceLines = hasManyField(InvoiceLine, { childField: "branch", hidden: true });

  Users = hasManyField(BranchUser, {
    childField: "branch",
    arrayList: x => [x.user.email.__, x.user.awsID.__],
    arraySort: x => [],
    hidden: true,
  });

  UnitTypeMarkup = hasManyField(BranchUnitTypeMarkup, {
    childField: "branch",
    arrayList: x => [x.Markup.__],
    title: "Unit Type Markup",
    prefillPossibleValuesFor: "unitType"
  });

  FirstContacts = hasManyField(Customer, {
    childField: "firstContactBranch",
    preventCreate: true,
    preventUpdate: true,
    hidden: true,
  });

  Groups: IsArray<Member<BranchGroupChild, false>> = hasManyField(BranchGroupChild, {
    childField: "child",
    preventCreate: true,
    preventUpdate: true,
    arrayList: x => [x.group.DisplayName.__, x.group.GroupType.__],
  });

}




export class BranchUnitTypeMarkup extends TableType {


  constructor(...args: ConstructorParameters<typeof TableType>) {
    super(...args);
    this.__typeDirectivesAdd(
      ...__TableDirectives<BranchUnitTypeMarkup>({
        heading: "Branch Markup",

        sec: rls.BranchUnitTypeMarkup,
      }),
      new uniquePrisma<BranchUnitTypeMarkup>({
        fields: ["branch", "unitType"]
      }),
      new initdata(`
INSERT INTO "BranchUnitTypeMarkup" ("branchID", "unitTypeID", "Markup", "updatedAt")
SELECT "Branch".id, "UnitType".id, 0, CURRENT_TIMESTAMP
FROM "Branch" CROSS JOIN "UnitType"
ON CONFLICT ("branchID", "unitTypeID") DO NOTHING;
      `)
    );
  }

  branch: Member<Branch, true> = selectRelation(Branch, BranchUnitTypeMarkup, {
    required: true,
    field: { arrayList: x => [x.DisplayName.__] }
  });

  unitType = selectRelation(UnitType, BranchUnitTypeMarkup, {
    required: true,
    field: { arrayList: x => [x.Name.__, x.RentalPrice.__] }
  });

  Markup = new CubesDinero(true, new field({
    default: 0,
    title: "Markup",
  }));

  units = new IsArray(new Member(Unit, false, new relationPrisma<Unit, BranchUnitTypeMarkup>({
    remote: "currentBranchMarkup",
  }), new field({
    onlyfor: []
  })));

}

export class OwnerUser extends TableType {
  static extraForms = {

  } as const satisfies Record<string, readonly MemberKeys<OwnerUser>[]>;

  constructor(...args: ConstructorParameters<typeof TableType>) {
    super(...args);
    this.__typeDirectivesAdd(
      ...__TableDirectives<OwnerUser>({
        heading: "Owner Users",
        sec: rls.OwnerUser,
        extraForms: OwnerUser.extraForms
      }),
      new uniquePrisma<OwnerUser>({ fields: ["user", "owner"] }),
    );
  }

  user: Member<User, true> = selectRelation(User, OwnerUser, {
    field: {
      title: "User",
      arrayList: (x => [x.name.__, x.email.__,]),
      preventUpdate: true,
    },
  });

  owner: Member<Owner, true> = selectRelation(Owner, OwnerUser, {
    field: {
      arrayList: x => [x.billing.Name.__, x.division.Name.__]
    },
  });

}

export class OwnerBillingInfo extends ContactInfo {


  constructor() {
    super(rls.OwnerBillingInfo);
  }

  // branch = selectRelation(Branch, BranchBillingInfo, { required: true, field: { unique: true, hidden: true } });
  owner = selectRelation(Owner, OwnerBillingInfo, { required: true, field: { unique: true, hidden: true } });

}

export class Owner extends TableType implements PaymentHost<OwnerPaymentInfo> {
  static extraForms = {
    OwnerCREATE: ["Email", "division", "billing", "OwnerPaymentSchedule"]
  } as const satisfies Record<string, readonly MemberKeys<Owner>[]>;

  constructor(
    ...args: ConstructorParameters<typeof TableType>
  ) {
    super(...args);
    this.__typeDirectivesAdd(
      ...__TableDirectives<Owner>({
        heading: "Owners",
        sec: rls.Owner,
        extraForms: Owner.extraForms
      })
    );

  }

  Email = new String(false, new field({
    unique: true,
    title: "Email"
  }));

  division = selectRelation(Division, Owner, {
    field: { arrayList: x => [x.Name.__] },
    required: true,
  });

  billing = hasOneField(OwnerBillingInfo, Owner, "owner", { title: "Billing Info" });

  OwnerPaymentSchedule = new OwnerPaymentSchedule(true, false, new field({ hidden: true }));

  AllUnits = hasManyField(Unit, {
    childField: "currentOwner",
    arrayList: x => [x.Name.__, x.unitType.Name.__, x.currentBranch.DisplayName.__,],
    arraySort: x => [x.Name.__],
    title: "Units",
    preventCreate: true,
    preventUpdate: true,
  });

  PaymentDetails = hasOneField(OwnerPaymentInfo, Owner, "owner", { hidden: true, onlyfor: [] });

  PaymentInfoValid = new Boolean(false, new field({
    title: "Payment Info",
    filterType: "boolean",
    preventCreate: true,
    preventUpdate: true,
    hidden: true,
  }));

  PaymentInfoFlags = new String<`${'c' | 'k' | 'n'}`>(false);

  LedgerLines = hasManyField(OwnerLedger, {
    childField: "owner",
    arrayList: x => [
      x.line.Date.__,
      x.line.invoiceLine.item.ItemName.__,
      x.line.invoiceLine.rental.unit.Name.__,
      x.line.invoiceLine.paidOn.__,
      x.line.paymentLine.PaymentStatus.__,
      x.Amount.__,
      x.line.VoidSince.__,
      { key: x.line.invoiceLine.id.__, hidden: true },
      { key: x.line.paymentLine.id.__, hidden: true },
    ],
    arraySort: x => ["-" + x.line.Date.__ as SPPI],
    arrayWhere: { line: WHERE_balanceWhereLine() },
    preventCreate: true,
    // preventUpdate: true,
  });

  InvoiceLines = hasManyField(InvoiceLine, { childField: "owner", hidden: true });

  Groups: IsArray<Member<OwnerGroupChild, false>> = hasManyField(OwnerGroupChild, {
    childField: "child",
    preventCreate: true,
    preventUpdate: true,
    arrayList: x => [x.group.DisplayName.__, x.group.GroupType.__],
  });

  Users = hasManyField(OwnerUser, {
    childField: "owner",
    arrayList: x => [x.user.email.__, x.user.awsID.__],
    arraySort: x => [],
    hidden: true,
  });


}

export class Item extends TableType {


  __init__ = void this.__typeDirectivesAdd(
    ...__TableDirectives<Item>({
      heading: "Products",

      sec: rls.Item
    }),
  );

  constructor(
    ...args: ConstructorParameters<typeof TableType>
  ) {
    super(...args);
  }

  ItemType = new ItemType(false, true, new index({}), new field({ title: "Type" }));
  ItemName = new String(true, new field({ unique: true, title: "Name" }));

  Description = new String(false, new field({ title: "Description" }));

  Taxable = new Boolean(false, new field({
    title: "Taxable",
    default: false,
  }));

  ChargeLateFee = new Boolean(false, new field({
    title: "Charge Late Fee",
    default: false,
  }));


  Notes = new String(false, new field({
    title: "Notes",
  }));

  AllInvoiceLines = hasManyField(InvoiceLine, { childField: "item" });

}


export class Unit extends TableType {
  static extraForms = {
    UnitCREATE: ["Name", "Notes", "unitType", "currentBranch", "currentOwner"],
    UnitUPDATE: ["Name", "unitType", "currentBranch", "currentOwner", "Notes", "currentLocation", "AvailableSince", "Unavailable", "AllRentals"],
    UnitFormAllRentals: ["Name", "AllRentals"],
    UnitUserUPDATE: ["Name", "unitType", "Notes", "Unavailable", "AvailableSince", "currentLocation"]
  } as const satisfies Record<string, readonly MemberKeys<Unit>[]>

  __init__ = void this.__typeDirectivesAdd(
    ...__TableDirectives<Unit>({
      heading: "Units",
      sec: rls.Unit,
      extraForms: Unit.extraForms
    }),
    new filter<Unit>({
      USER_BRANCH_ID: {
        currentBranchID: "USER_BRANCH_ID"
      }
    })

  );
  constructor(
    ...args: ConstructorParameters<typeof TableType>
  ) {
    super(...args);


  }

  Name = new String(true, new index({}), new field({ title: "S/N", unique: true, preventUpdate: true }));

  unitType = selectRelation(UnitType, Unit, {
    required: true,
    field: {
      title: "Type",
      arrayList: x => [x.Name.__],
      preventUpdate: true,
    }
  })


  // I need to keep track of which unit was assigned to each rental,
  // but I also need to keep track of the current rental, so I'm sort of stuck
  // this doesn't really need an index since most customers will only have one or two
  // or does that mean we really need it so we don't have to entirely scan the table?
  // actually, yes, but we don't need to sort it

  currentRental = selectRelation(Rental, Unit, {
    required: false,
    field: {
      arrayList: x => [
        x.customer.billing.Name.__,
        x.customer.billing.Phone.__,
        x.StartDate.__,
        x.EndDate.__,
      ],
      title: "Current Rental",
      preventUpdate: true,
      preventCreate: true,
    }
  });

  Notes = new String(false, new field({ title: "Notes" }));

  currentLocation = new Member(GeocodeAddress, false, new field({
    title: "Current Location",
  }));

  AvailableSince = new ScalarDate("yyyy-MM-dd", false, new index({}), new field({
    title: "Available Since",
    helptext: "The Date this unit entered the waiting list of available units."
  }));

  Unavailable = new Boolean(false, new field({ filterType: "boolean" }));

  RentToOwnCompleted = new Boolean(false, new field({ filterType: "boolean" }));

  currentBranch: Member<Branch, true> = selectRelation(Branch, Unit, {
    required: true,
    field: {
      title: "Current Branch",
      arrayList: x => [
        x.DisplayName.__,
        x.BranchType.__,
        x.division.Name.__,
      ],
      rlsRestrict: true,
    },

  });

  currentOwner: Member<Owner, true> = selectRelation(Owner, Unit, {
    required: false,
    field: {
      title: "Current Owner",
      arrayList: x => [
        x.billing.Name.__,
        x.Email.__,
        x.billing.Address.description.__,
        x.billing.Phone.__
      ],
      rlsRestrict: true,
    }
  });

  AllRentals: IsArray<Member<Rental>> = hasManyField(Rental, {
    childField: "unit",
    arrayList: x => [
      x.customer.billing.Name.__,
      x.StartDate.__,
      x.EndDate.__,
      x.activeUnit.currentRental.customer.billing.Name.__,
    ],
    arraySort: x => [],
    preventCreate: true,
  });


  currentBranchMarkup: Member<BranchUnitTypeMarkup> = new Member(BranchUnitTypeMarkup, false, new relationPrisma<BranchUnitTypeMarkup, Unit>({
    fields: ["unitType", "currentBranch"],
    references: ["unitType", "branch"],
  }), new field<BranchUnitTypeMarkup, Unit>({
    onlyfor: [],
    hidden: true,
  }));

  Groups: IsArray<Member<UnitGroupChild>> = hasManyField(UnitGroupChild, {
    childField: "child",
    preventCreate: true,
    preventUpdate: true,
    arrayList: x => [x.group.DisplayName.__, x.group.GroupType.__],
  });

}

export class UnitType extends TableType {


  __init__ = void this.__typeDirectivesAdd(
    ...__TableDirectives<UnitType>({
      heading: "Unit Types",
      sec: rls.UnitType,
      extraForms: {
        UnitTypeCREATE: ["Name", "Description", "RentalPrice"]
      }
    })
  );

  constructor(
    ...args: ConstructorParameters<typeof TableType>
  ) {
    super(...args);
  }

  Name = new String(true, new index({}), new field({ title: "Name" }));
  Description = new String(false, new field({ title: "Description" }));
  AllPhotos = new IsArray(new Member(FileUpload, false, new field({ hidden: true, })));
  RentalPrice = new CubesDinero(true, new field({ title: "Base price" }));

  AllUnits = hasManyField(Unit, { childField: "unitType", hidden: true });

  BranchMarkup: IsArray<Member<BranchUnitTypeMarkup>> = hasManyField(BranchUnitTypeMarkup, {
    childField: "unitType",
    arrayList: x => [x.Markup.__,],
    prefillPossibleValuesFor: "branch",
  })

}

export class CustomerBillingInfo extends ContactInfo {


  constructor() { super(rls.CustomerBillingInfo); }

  customer = hasOneField(Customer, CustomerBillingInfo, "billingID", { hidden: true, onlyfor: [] });

  LateFeeExempt = new Boolean(
    true,
    new field({
      title: "No Late Fee",
      filterType: "boolean",
      default: false,
    })
  );

  TaxExempt = new Boolean(
    true,
    new field({
      title: "Tax Exempt",
      filterType: "boolean",
      default: false,
    })
  );


}

export class CustomerPaymentInfo extends PaymentInfo {


  constructor() {
    super();
    this.__typeDirectivesAdd(
      ...__TableDirectives({
        heading: this.constructor.name,
        sec: rls.CustomerPaymentInfo
      })
    );
  }

  customer = selectRelation(Customer, CustomerPaymentInfo, {
    required: true,
    field: { title: "Billing", unique: true, }
  });

  EmailVerificationCodes = new IsArray(new ScalarJSON("EmailVerificationCodes"));

  PasswordResetCode = new String(false, new field({}));

}

export class BranchPaymentInfo extends PaymentInfo {


  constructor() {
    super();
    this.__typeDirectivesAdd(
      ...__TableDirectives({
        heading: this.constructor.name,
        sec: rls.BranchPaymentInfo
      })
    );
  }

  branch: Member<Branch, true> = selectRelation(Branch, BranchPaymentInfo, {
    required: true,
    field: { title: "Billing", unique: true, }
  });

}

export class OwnerPaymentInfo extends PaymentInfo {


  constructor() {
    super();
    this.__typeDirectivesAdd(
      ...__TableDirectives({
        heading: this.constructor.name,
        sec: rls.OwnerPaymentInfo
      })
    );
  }

  owner: Member<Owner, true> = selectRelation(Owner, OwnerPaymentInfo, {
    required: true,
    field: { title: "Billing", unique: true, }
  });

}

export class DivisionPaymentInfo extends PaymentInfo {


  constructor() {
    super();
    this.__typeDirectivesAdd(
      ...__TableDirectives({
        heading: this.constructor.name,
        sec: rls.DivisionPaymentInfo
      })
    );
  }

  division: Member<Division, true> = selectRelation(Division, DivisionPaymentInfo, {
    required: true,
    field: { title: "Billing", unique: true, }
  });

}

export class CustomerOtherContacts extends ContactInfo {

  constructor() { super(rls.CustomerOtherContacts); }
  customer = belongsToField(Customer, CustomerOtherContacts, { root: "customerID" }, {});
  customerID = selectID(Customer, CustomerOtherContacts, {
    belongsTo: "customer",
    required: true,
    field: {
      title: "Customer",
      preventUpdate: true,
      hidden: true,
    }
  })
}


export class TaxRate extends RecordType {
  TaxJurisdiction = new String(true, new field({ title: "Jurisdiction" }));
  TaxPercent = new Float(true, new field({ title: "Tax Percentage" }));
}
export class TaxBalance extends RecordType {
  TaxJurisdiction = new String(true, new field({ title: "Jurisdiction" }));
  _sum = new Member(TaxBalanceInner, true, new field({ title: "Balance" }));
}
export class TaxBalanceInner extends RecordType {
  Amount = new CubesDinero(true, new field({}));
}
export class WelcomeEmailInfo extends RecordType {

  MessageId = new String();
  Email = new String();
  Error = new ScalarJSON("WelcomeEmailInfo_Error");
  Timestamp = new ScalarDateTime();

}

export class SentEmailInfo extends RecordType {
  Timestamp = new ScalarDateTime(undefined, false, new field({ title: "Date", filterType: "date" }));
  MessageId = new String();
  Recipient = new String(false, new field({ title: "Email" }));
  NoticeType = new NoticeTypes(false, false, new field({ title: "Notice" }));
  Text = new String();
  Error = new ScalarJSON("WelcomeEmailInfo_Error");
  cron_info = new ScalarJSON("SentEmailInfo_cron_info")
  IS_TESTING = new Boolean()
}

export interface PaymentHost<T extends TableType> {
  PaymentInfoValid: Boolean;
  PaymentInfoFlags: String<"c" | "k" | "n">;
  PaymentDetails: Member<T, false>;
}

export class Customer extends TableType implements PaymentHost<CustomerPaymentInfo> {
  static extraForms = {
    CustomerCREATE: ["Email", "HowTheyFoundUs", "CustomerType", "BillingDay", "billing"],
    CustomerFormEditEmail: ["Email", "AWSID", "StorageAgreementCompleted", "IS_TESTING"],
    CustomerFormBillingInfo: ["HowTheyFoundUs", "CustomerType", "BillingDay", "billing", "firstContactBranch"],
    CustomerFormOtherContacts: ["OtherContacts"],
    CustomerFormEmails: ["SentEmails"],
  } as const satisfies { [K in TYPE_NAMES]?: never; } & Record<string, readonly MemberKeys<Customer>[]>
  constructor(
    ...args: ConstructorParameters<typeof RecordType>
  ) {
    super("customer_extra", ...args);

    this.__typeDirectivesAdd(
      ...__TableDirectives<Customer>({
        heading: "Customers",
        filter: x => [
          x.AllRentals.promotion.Title.__,
        ],
        search: [],
        sec: rls.Customer,
        extraForms: Customer.extraForms,
      }),
      new filter({
        USER_BRANCH_ID: {
          AllRentals: { some: { unit: { currentBranchID: "USER_BRANCH_ID" } } }
        }
      })
    );

  }

  IS_TESTING = new Boolean(true, new field({
    title: "Is Test Customer",
    helptext: "Transactions will be ignored and payments will be made through a test gateway.",
    filterType: "boolean",
  }), new index({}))

  Email = new String(true, new field({
    title: "Email",
    unique: true,
    inputType: "email",
  }));

  AWSID = new String(false, new field({ hidden: true, unique: true, }));

  EmailVerified = new Boolean(false,
    new field({ title: "Email Address", filterType: "boolean", }),
    new mapsTo({ name: "VerifiedEmail" })
  );

  // EmailVerificationCodes is in PaymentInfo to hide them from branches

  VerifiedEmailInfo = new Member(WelcomeEmailInfo, false, new field({}));

  HowTheyFoundUs = new String(false, new field({
    title: "How did the customer hear about us?",
  }));

  SignupSource = new SignupSource(true, true, new field({ hidden: true }));

  firstContactBranch: Member<Branch, true> = selectRelation(Branch, Customer, {
    field: {
      arrayList: x => [
        x.DisplayName.__,
        x.BranchType.__,
      ],
      title: "Branch of First Contact"
    },
    filterWhere: [{ BranchType: "DEALER" }]
  })

  CustomerType = new CustomerType(true, true, new field({
    title: "Customer Type",
  }), new select({
    display: "buttons"
  }));

  BillingDay = new Int(false, new field({
    title: "Payment due day",
    helptext: `The day on which the monthly payment comes due. Use 31 for the last day of every month. If a late fee is charged, it will be at the end of this day.`,
  }));

  AutoPay = new Boolean(false, new field({
    title: "Autopay"
  }));

  CustomerAcceptedAutoPay = new Boolean(false, new field({
    title: "Customer Accepted Autopay",
  }));

  billing = belongsToField(CustomerBillingInfo, Customer, {
    root: "billingID" as any,
  }, {
    unique: true,
    title: "Billing",
  });

  billingID = selectID(CustomerBillingInfo, Customer, {
    belongsTo: "billing",
    required: true,
    field: {
      title: "Billing",
      unique: true,
      hidden: true,
    }
  });

  OtherContacts = hasManyField(CustomerOtherContacts, {
    childField: "customerID",
    arrayList: x => [x.Name.__, x.Phone.__],
    title: "Other Contacts",
    detailType: ContactInfoType.name,
    preventDelete: false,
  });

  LedgerLines: IsArray<Member<CustomerLedger>> = hasManyField(CustomerLedger, {
    childField: "customer",
    arrayList: x => [
      x.line.Date.__,
      x.line.invoiceLine.item.ItemName.__,
      x.line.invoiceLine.rental.unit.Name.__,
      x.line.invoiceLine.paidOn.__,
      x.line.paymentLine.PaymentStatus.__,
      x.Amount.__,
      x.line.VoidSince.__,
    ],
    arraySort: x => ["-" + x.line.Date.__ as SPPI],
    preventCreate: true,
  });



  BillingStatus = new BillingStatus(true, true, new field<BillingStatus, Rental>({
    default: "Normal",
    title: "Billing Status",
    preventUpdate: true,
    preventCreate: true,
  }));



  PaymentDetails = hasOneField(CustomerPaymentInfo, Customer, "customer", { hidden: true, onlyfor: [] });
  PaymentInfoFlags = new String<`${'c' | 'k' | 'n'}`>(false);
  PaymentInfoValid = new Boolean(false, new field({
    title: "Payment Info",
    filterType: "boolean",
    preventCreate: true,
    preventUpdate: true,
    hidden: true,
  }));

  StorageAgreementEnvelopeID = new ID(false, new field({ unique: true, }));
  StorageAgreementIsTesting = new BooleanNullable(false, new field({ hidden: true, }));
  StorageAgreementCompleted = new Boolean(false, new field({
    title: "Storage Agreement",
    filterType: "boolean",
    preventCreate: true,
    preventUpdate: true,
  }));
  StorageAgreementStatus = new DocusignEnvelopeStatus(true, true, new field({
    title: "Storage Agreement Status",
    preventCreate: true,
    preventUpdate: true,
  }));




  AllRentals = hasManyField(Rental, {
    // this uses a custom table design
    childField: "customerID",
    title: "Rentals",
  });

  SentEmails = new IsArray(new Member(SentEmailInfo, false, new field<SentEmailInfo, Customer>({
    arrayList: x => [x.Recipient.__, x.NoticeType.__, x.Timestamp.__],
    arraySort: x => ["-" + x.Timestamp.__ as SPPI],
    extraGetPaths: x => [x.Text.__],
    preventCreate: true,
    preventDelete: true,
    preventUpdate: true,
  })));

  Groups: IsArray<Member<CustomerGroupChild, false>> = hasManyField(CustomerGroupChild, {
    childField: "child",
    preventCreate: true,
    preventUpdate: true,
    arrayList: x => [x.group.DisplayName.__, x.group.GroupType.__],
  });


  // Invoices = hasManyField(Invoice, { childField: "customer", hidden: true });
  // Payments = hasManyField(CustomerPayment, {
  //   childField: "customer",
  //   preventCreate: true,
  //   preventUpdate: true,
  //   hidden: true,
  // });

}

export class User extends TableType {
  static extraForms = {
    "UserUPDATE": ["name", "email", "awsID", "Branches", "Owners"],
    "UserBranches": ["Branches"],
    "UserOwners": ["Owners"],
  } as const satisfies Record<string, readonly MemberKeys<User>[]>;
  constructor(...args: ConstructorParameters<typeof TableType>) {
    super(...args);
    this.__typeDirectivesAdd(
      ...__TableDirectives<User>({
        heading: "Users",
        sec: rls.User,
        extraForms: User.extraForms
      })
    );
  }



  name = new String(false, new field({ title: "Name" }));
  awsID = new String(false, new field({ unique: true, preventUpdate: true, title: "AWS ID" }));
  email = new String(true, new field({ unique: true, preventUpdate: true, title: "Email" }));

  clientOptions = new ScalarJSON("BranchUser_clientOptions");

  permissions = hasManyField(UserPermission, {
    childField: "user",
    preventCreate: true,
    preventUpdate: true,
    hidden: true,
  });

  Groups: IsArray<Member<UserGroupChild, false>> = hasManyField(UserGroupChild, {
    childField: "child",
    preventCreate: true,
    preventUpdate: true,
    arrayList: x => [x.group.DisplayName.__, x.group.GroupType.__],
  });

  Branches: IsArray<Member<BranchUser, false>> = hasManyField(BranchUser, {
    childField: "user",
    arrayList: x => [x.branch.DisplayName.__, x.branch.BranchType.__, x.branch.division.Name.__],
    preventDelete: false,
    // helptext: "For now, you should only give the user access to one branch. Talk to Arlen if they need access to more than one."
  });

  Owners: IsArray<Member<OwnerUser, false>> = hasManyField(OwnerUser, {
    childField: "user",
    arrayList: x => [x.owner.billing.Name.__, x.owner.division.Name.__],
    preventDelete: false,
    // helptext: "For now, you should only give the user access to one owner. Talk to Arlen if they need access to more than one."
  });

}

export class UserPermission extends TableType {

  __init__ = void this.__typeDirectivesAdd(
    ...__TableDirectives<UserPermission>({ heading: "User Permissions", sec: rls.UserPermission, }),
    new uniquePrisma<UserPermission>({ fields: ["user", "perm"] }),
  );

  user = selectRelation(User, UserPermission, {
    required: true,
    field: { arrayList: x => [] },
  });

  perm = selectRelation(Permission, UserPermission, {
    required: true,
    field: { arrayList: x => [] },
  });

  value = new IsArray(new ID(false, new field({})));

}


//   type A = PermissionNames;
//   type B = MemberKeys<PermissionEnum>;
//   type C = A extends B ? B extends A ? true : false : false
//   // make sure the PermissionEnum class has exactly the same keys as PermissionNames
//   const c: C = true;

export class Permission extends TableType {
  constructor(...args: ConstructorParameters<typeof TableType>) {
    super(...args);
    this.__typeDirectivesAdd(
      ...__TableDirectives<Permission>({
        heading: "Permissions",
        sec: rls.Permission,
      }),
    );
  }
  name = new String<PermissionNames>(true, new field({ unique: true }));
  description = new String(false);
  users = hasManyField(UserPermission, {
    childField: "perm",
    preventCreate: true,
    preventUpdate: true,
    hidden: true,
  });
}

export class BranchGroup extends Group<Branch> { constructor() { super(BranchGroupChild, x => []); } }
export class BranchGroupChild extends GroupChild<Branch> { constructor() { super(BranchGroup, Branch, x => []); } }

export class CustomerGroup extends Group<Customer> { constructor() { super(CustomerGroupChild, x => []); } }
export class CustomerGroupChild extends GroupChild<Customer> { constructor() { super(CustomerGroup, Customer, x => []); } }

export class OwnerGroup extends Group<Owner> { constructor() { super(OwnerGroupChild, x => []); } }
export class OwnerGroupChild extends GroupChild<Owner> { constructor() { super(OwnerGroup, Owner, x => []); } }

export class DivisionGroup extends Group<Division> { constructor() { super(DivisionGroupChild, x => []); } }
export class DivisionGroupChild extends GroupChild<Division> { constructor() { super(DivisionGroup, Division, x => []); } }

export class UnitGroup extends Group<Unit> { constructor() { super(UnitGroupChild, x => []); } }
export class UnitGroupChild extends GroupChild<Unit> { constructor() { super(UnitGroup, Unit, x => []); } }

export class UserGroup extends Group<User> { constructor() { super(UserGroupChild, x => []); } }
export class UserGroupChild extends GroupChild<User> { constructor() { super(UserGroup, User, x => []); } }

import { parentFilterArg } from "./cubes-schema-from-prisma"


// import { Prisma } from "prisma-client";
export interface InvoiceReminderEmail {
  DaysUntil: number,
  DueDate: string,
  Timestamp: string,
  MessageID: string | undefined,
  Recipient: string,
}
export type TypeEqual<A, B> = A extends B ? B extends A ? true : false : false;
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace PrismaJson {
    interface ExtraValues {
      RootScals_ScalarJSON: any
      attributes_attrs: any
      editor_options: any
      field_default: any
      fieldComposite_opts: any
      filter_USER_BRANCH_ID: any
      FilterWith_filterConst: any
      forms_extraForms: Record<string, readonly string[]>
      noticeTags_filterConst: any
      TableType_extra: any
      GeocodeAddress_response: any
      InvoiceLine_PriceInfo: any
      PaymentLine_PaymentInfo: any
      FileUpload_exif: any
      Attributes: any
      Movement_transport: any
      Movement_status: any
      BranchUser_clientOptions: any
      Branch_ClientOptions: any
      WelcomeEmailInfo_Error: any
      CardInfo: {
        ccNumber?: string | null
        ccExpiry?: string | null
        ckRouting?: string | null
        ckAccount?: string | null
      }
      EnvelopeSummary: { envelopeId: string, status: string, }
      customer_extra: { rawTxns?: string[]; password?: string; }
      CustomerFilterArg: parentFilterArg<Customer>;
      CustomerOnboardingInfo: {
        SignupSource: "Branch" | "Account";
      }
      UnitFilterArg: parentFilterArg<Unit>
      PrismaPromise: { action: any, table: any, arg: any } | unknown //Prisma.PrismaPromise<any>
      SentEmailInfo_cron_info: any;
      // InvoiceReminderEmails: InvoiceReminderEmail
      InvoiceReminderEmail: InvoiceReminderEmail;
      UserPermission_value: unknown;
      GreenpayTransaction_json: any;
      /** Get the code from the most recent email, as well as all previous codes for that email in the last 24 hours. */
      EmailVerificationCodes: { code: string; email: string; timestamp: number; };
    }

    type SchemaValues = { [K in keyof RootTypes as string extends K ? never : K]: ValueTree<RootTypes[K]> }
    type ExtraValuesMap = { [K in keyof ExtraValues as `"${K}"`]: ExtraValues[K] }
    export interface AllTypes extends ExtraValues, SchemaValues { }
  }

}

export type CustomerPaymentInfoFlags = Customer["PaymentInfoFlags"]["__value"];

export class RootScals {
  Boolean = new Boolean<any>;
  BooleanNullable = new BooleanNullable<any>;
  CubesDinero = new CubesDinero;
  Float = new Float<any>;
  ID = new ID;
  Int = new Int<any>;
  ScalarDate = new ScalarDate;
  ScalarDateTime = new ScalarDateTime;
  ScalarEmail = new ScalarEmail<any>;
  ScalarIPAddress = new ScalarIPAddress<any>;
  ScalarJSON = new ScalarJSON("RootScals_ScalarJSON");
  ScalarPhone = new ScalarPhone<any>;
  ScalarTime = new ScalarTime;
  ScalarTimestamp = new ScalarTimestamp;
  ScalarURL = new ScalarURL<any>;
  String = new String<any>;
}
// prisma/migrations/20241210223509_
export class RootEnums {
  // AccountingType = new AccountingType(false, false);
  // AuthProviderAmplify = new AuthProviderAmplify(false, false);
  // AuthStrategyAmplify = new AuthStrategyAmplify(false, false);
  BranchUserLevel = new BranchUserLevel(false, false);
  // ConfirmType = new ConfirmType(false, false);
  // EditorType = new EditorType(false, false);
  // FieldFilterType = new FieldFilterType(false, false);
  // LookupMethod = new LookupMethod(false, false);
  // ModelOperation = new ModelOperation(false, false);
  // ModelSubscriptionLevel = new ModelSubscriptionLevel(false, false);
  PostgresRoles = new PostgresRoles(false, false);
  // PostgresTablePermissions = new PostgresTablePermissions(false, false);
  PostgresUserRoles = new PostgresUserRoles(false, false);
  // QuickbooksChartOfAccountsCategory = new QuickbooksChartOfAccountsCategory(false, false);
  // ReferentialActions = new ReferentialActions(false, false);
  // SectionType = new SectionType(false, false);
  ActionType = new ActionType(false, false);
  BillingStatus = new BillingStatus(false, false);
  BranchType = new BranchType(false, false);
  CacheQuery = new CacheQuery(false, false);
  ChargeSource = new ChargeSource(false, false);
  ConfirmType = new ConfirmType(false, false);
  CustomerType = new CustomerType(false, false);
  DocusignEnvelopeStatus = new DocusignEnvelopeStatus(false, false);
  FieldFilterType = new FieldFilterType(false, false);
  InvoiceStatus = new InvoiceStatus(false, false);
  InvoiceType = new InvoiceType(false, false);
  ItemType = new ItemType(false, false);
  NoticeTypes = new NoticeTypes(false, false);
  OwnerPaymentSchedule = new OwnerPaymentSchedule(false, false);
  PaymentLedger = new PaymentLedger(false, false);
  PaymentStatus = new PaymentStatus(false, false);
  PersonIdentityDocType = new PersonIdentityDocType(false, false);
  ProRating = new ProRating(false, false);
  RentalStatus = new RentalStatus(false, false);
  RentalType = new RentalType(false, false);
  SignupSource = new SignupSource(false, false);
  TxnType = new TxnType(false, false);
  UnitStatus = new UnitStatus(false, false);
}

export class RootTypes {

  // AuthRuleAmplify = new AuthRuleAmplify;
  // CustomerPayment = new CustomerPayment;
  // Invoice = new Invoice;
  // ModelMutationMap = new ModelMutationMap;
  // ModelQueryMap = new ModelQueryMap;
  // ModelSubscriptionMap = new ModelSubscriptionMap;
  // TimestampConfiguration = new TimestampConfiguration;
  AutopayAttempt = new AutopayAttempt;
  Branch = new Branch;
  BranchBillingInfo = new BranchBillingInfo;
  BranchContactInfo = new BranchContactInfo;
  BranchDiscountLedger = new BranchDiscountLedger;
  BranchGroup = new BranchGroup;
  BranchGroupChild = new BranchGroupChild;
  BranchLedger = new BranchLedger;
  BranchLocations = new BranchLocations;
  BranchPaymentInfo = new BranchPaymentInfo;
  BranchSalesTaxSummary = new BranchSalesTaxSummary;
  BranchUnitTypeMarkup = new BranchUnitTypeMarkup;
  BranchUser = new BranchUser;
  CentralDiscountLedger = new CentralDiscountLedger;
  CentralHoldingLine = new CentralHoldingLine;
  CentralLedger = new CentralLedger;
  CentralPage = new CentralPage;
  ContactInfoType = new ContactInfoType;
  Customer = new Customer;
  CustomerBillingInfo = new CustomerBillingInfo;
  CustomerGroup = new CustomerGroup;
  CustomerGroupChild = new CustomerGroupChild;
  CustomerLedger = new CustomerLedger;
  CustomerOtherContacts = new CustomerOtherContacts;
  CustomerPaymentInfo = new CustomerPaymentInfo;
  Division = new Division;
  DivisionBillingInfo = new DivisionBillingInfo;
  DivisionDiscountLedger = new DivisionDiscountLedger;
  DivisionGroup = new DivisionGroup;
  DivisionGroupChild = new DivisionGroupChild;
  DivisionLedger = new DivisionLedger;
  DivisionPaymentInfo = new DivisionPaymentInfo;
  DocusignEnvelopeInfo = new DocusignEnvelopeInfo;
  EmailDoc = new EmailDoc;
  FileUpload = new FileUpload;
  GeocodeAddress = new GeocodeAddress;
  GreenpayTransaction = new GreenpayTransaction
  InvoiceLine = new InvoiceLine;
  InvoiceLineCreateType = new InvoiceLineCreateType;
  // InvoicePayment = new InvoicePayment;
  Item = new Item;
  Movement = new Movement;
  NoticeTemplate = new NoticeTemplate;
  NotificationPreferences = new NotificationPreferences;
  Owner = new Owner;
  OwnerBillingInfo = new OwnerBillingInfo;
  OwnerGroup = new OwnerGroup;
  OwnerGroupChild = new OwnerGroupChild;
  OwnerLedger = new OwnerLedger;
  OwnerPaymentInfo = new OwnerPaymentInfo;
  OwnerUser = new OwnerUser;
  PaymentLine = new PaymentLine;
  Permission = new Permission;
  PersonIdentityDoc = new PersonIdentityDoc;
  Promotion = new Promotion;
  Rental = new Rental;
  RentalCharge = new RentalCharge;
  RentalCreateType = new RentalCreateType;
  SalesTaxLedger = new SalesTaxLedger;
  SentEmailInfo = new SentEmailInfo;
  StorageAgreement = new StorageAgreement;
  TaxBalance = new TaxBalance;
  TaxBalanceInner = new TaxBalanceInner;
  TaxRate = new TaxRate;
  Transaction = new Transaction;
  Unit = new Unit;
  UnitGroup = new UnitGroup;
  UnitGroupChild = new UnitGroupChild;
  UnitType = new UnitType;
  User = new User;
  UserGroup = new UserGroup;
  UserGroupChild = new UserGroupChild;
  UserPermission = new UserPermission;
  WelcomeEmailInfo = new WelcomeEmailInfo;
}



export class RootScals2 extends RootScals { [X: string]: ScalarType<any>; }
export class RootEnums2 extends RootEnums { [X: string]: EnumType; }
export class RootTypes2 extends RootTypes { [X: string]: RecordType; }




export type DIRECTIVES_NAMES<T extends DirectiveOptions> = keyof Attributes<T>;
export type ENUM_VALUES<T extends ENUM_NAMES> = { [K in T]: MemberKeys<RootEnums[K]>; }[T];
export type FIELD_NAMES<T extends TYPE_NAMES> = { [K in T]: MemberKeys<RootTypes[K]>; }[T];
export type SCALAR_NAMES = { [K in keyof RootScals]: K; }[keyof RootScals];
export type ENUM_NAMES = { [K in keyof RootEnums]: K; }[keyof RootEnums];
export type TYPE_NAMES = { [K in keyof RootTypes]: RootTypes[K] extends RecordType ? K : never; }[keyof RootTypes];
export type TABLE_NAMES = { [K in keyof RootTypes]: RootTypes[K] extends TableType ? K : never; }[keyof RootTypes];
export type ITEM_NAME<T extends RecordMember<any>> = {
  [K in keyof RootItems]: RootItems[K] extends T ? K : never;
}[keyof RootItems];

interface RootItems extends RootScals, RootEnums, RootTypes { }

// /** The generic type allows only directives with ALL the options */
// export type Directives<T extends DirectiveOptions = any> = {
//   [D in DIRECTIVES_NAMES<T>]?: (imp[D]["prototype"] & {
//     __values: ValueTree<imp[D]["prototype"]>;
//   })[];
// };

export type { Attributes };
// export type DirectiveInput<T extends DirectiveOptions> = any;
// /** The generic type allows only directives with ALL the options */
// export type DirectiveInput<T extends DirectiveOptions> = {
//   [D in keyof RootDirs as string extends D ? never : RootDirs[D] extends Directive<T> ? D : never]?: ValueTree<RootDirs[D]>[];
// };
// /** The generic type allows only directives with ALL the options */
// export type Attributes<T extends DirectiveOptions = any> = {
//   [D in keyof RootDirs as string extends D ? never : RootDirs[D] extends Directive<T> ? D : never]?: ValueTree<RootDirs[D]>[];
// };
export type TableFieldStrings = SCALAR_NAMES;
export type TableFieldTypeStrings = | "enum" | "model" | "nonModel" | TableFieldStrings;
export interface Values extends RootScals, RootEnums, RootTypes { } // { [T in keyof imp]: ValueTree<imp[T]["prototype"]> };
export type Scals = RootScals;
export type Enums = RootEnums;
export type Types = RootTypes;
export type Tables = { [K in keyof RootTypes as RootTypes[K] extends TableType ? K : never]: RootTypes[K] }




