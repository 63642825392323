import { EventEmitter, Injector } from "@angular/core";
import { ConfirmType, DataQueryGraph, MemberKeys, okNull, schema, TABLE_NAMES, Complete, Modes, TYPE_NAMES } from "common";
import { DataService } from "data-service";
import { ButtonAwaitPage, ButtonAwaitPageProps, FGCR, FGCV, QuestionBase, QuestionGroup, renderIf, UI_Schema, UIService } from '.';
import { Subscription } from "rxjs";
import { ChartConfiguration } from "chart.js";
import { Params } from "@angular/router";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { ClickEvent } from '.';
import { globalMessage, useAngular, useObservable, useWatchMemo } from "react-utils";
import { useLayoutEffect, useMemo } from "react";
import { renderQuestionItem } from "../components/QuestionForm";
import { Generator } from "./Generator";

export const DialogLoading: unique symbol = Symbol("DialogLoading");
export const ModelHostCounter: unique symbol = Symbol("DialogHostCounter");
export type DialogLoader = {
  [ModelHostCounter]?: number;
  [DialogLoading]: string;
  onClose: () => void;
  finished: boolean;
};

export interface Dialog<T extends TYPE_NAMES, M extends MemberKeys<ConfirmType>, G extends QuestionGroup<T, FGCR>> extends IModelDialog, GroupAdaptor<T, M, G> {
  [ModelHostCounter]?: number;
  [DialogLoading]?: undefined;
}

export abstract class GroupAdaptor<T extends TYPE_NAMES, M extends MemberKeys<ConfirmType>, G extends QuestionGroup<T, FGCR>> {
  protected abstract data: DataService;
  // protected abstract ui: UIService;
  // protected abstract cs: ConfirmationService;
  // protected abstract ms: MessageService;

  abstract tabs?: any;
  abstract table: T;
  abstract mode: M;

  abstract setupComplete: EventEmitter<void>;

  /** 
   * Should be called by onSave - helpful if you want onSave to be this-able as a method of GroupDialog. 
   * The dialog host will subscribe to this and forward the call to fq.onSaveSuccess.
   * This will not close the dialog, so if that is desired the onSave method should call this.onClose.
   */
  public onSaveSuccess: EventEmitter<{ table?: string; id?: string; }> = new EventEmitter();

  abstract onClose(): Promise<void>;
  abstract onDelete?(): Promise<boolean>;
  abstract onLoad(): Promise<boolean>;
  abstract onSaveValue(value: any): Promise<void>;
  abstract onSaveError(error: any): void;



  abstract pageSetupGroup(): G;

  async onClickOk() {
    if (!this.onClickSave) return;
    try { this.saving = true; await this.onClickSave(); } finally { this.saving = false; }
  }
  async onClickCancel() {
    await this.onClose();
  }
  async onClickDelete(): Promise<void> {
    if (!this.onDelete) return;
    try { this.deleting = true; await this.onDelete(); } finally { this.deleting = false; }
  }

  async onClickSave(): Promise<void> {
    console.log(this.group);
    if (!this.group) return;
    if (this.mode !== "CREATE" && this.mode !== "UPDATE") return;

    const { error, value } = this.group.getValueAndValidity(this.mode, this.data.userRole);

    if (error) return this.onSaveError(error);
    if (!value) return;
    return await this.onSaveValue(value);

  }

  onClickEvent?: EventEmitter<ClickEvent>
  onDialog?: EventEmitter<Dialog<any, any, any>>
  onSaveEvent?: EventEmitter<void>
  onRefreshRender = new EventEmitter<object>();

  updateInkBar = () => requestAnimationFrame(() => this.tabs?.updateInkBar());


  awaitclick(res: Promise<any>, button: HTMLButtonElement) {
    button.disabled = true;
    res.finally(() => { button.disabled = false; })
  }

  async pageSetup(keepPage: boolean) {

    this.loading = true;

    okNull(this.table);
    okNull(this.mode);

    delete this.onSaved;

    if (!await this.setGroup(this.pageSetupGroup())) { this.cancelLoading(); return false; }

    okNull(this.group);

    if (!await this.onLoad()) { this.cancelLoading(); return false; }

    this.loading = false;

    this.setupComplete.emit();

    requestAnimationFrame(() => this.tabs?.updateInkBar());

    return true;

  }

  async setGroup(group: G): Promise<boolean> {

    console.log(group);

    if (!group) return false;

    if (this.group) {
      this.subs.remove(this.group.subs);
      this.group.subs.unsubscribe();
    }

    this.loading = true;
    this.group = group;

    this.subs.add(this.group.subs);

    return true;

  }

  #group: G | null = null;
  public get group(): G | null {
    return this.#group;
  }
  public set group(value: G | null) {
    this.#group = value;
    this.charts = [];
    this.extra = {};
  }
  
  subs = new Subscription(() => {
    this.onSaveSuccess.complete();
    this.loadingChange.complete();
    this.setupComplete.complete();
    this.onClickEvent?.complete();
    this.onSaveEvent?.complete();
    this.onDialog?.complete();
    this.onRefreshRender?.complete();
  });

  /** used to block the ui */
  private _loading: boolean = false;
  public get loading(): boolean {
    return this._loading;
  }
  public set loading(value: boolean) {
    this._loading = value;
    this.loadingChange.emit(value);
  }
  public loadingChange = new EventEmitter<boolean>();

  saving: boolean = false;
  deleting: boolean = false;
  charts: ChartConfiguration<any>[] = [];
  onSaved?: { list: () => void; edit: () => void; add: () => void; message: string; };
  extra: Record<string, QuestionBase<any, any>> = {};
  error?: string;

  cancelLoading() {
    this.loading = false;
    if (this.group) {
      this.subs.remove(this.group.subs);
      this.group.subs.unsubscribe();
    }
    this.group = null;
  }


  catchError = (e: any) => {
    console.log(e);
    this.cancelLoading();
    alert("An error occurred while loading the page. You can try refreshing the page.");
  };


  canDeactivate(): boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return !this.group?.form.dirty;
    // return true;
  }


}

export interface IPageDialog {
  showOkCancel: boolean;
  okLabel: string | undefined;
  onClickOk(): Promise<void>;
  onClickCancel(): Promise<void>;
  onClickDelete(): Promise<void>;
  cancelLabel: string | undefined;

  showDelete: boolean;
  deleteLabel: string | undefined;

  title: string | undefined;
  subs: Subscription;
}

export interface IModelDialog extends IPageDialog {

  width: string | undefined;
  maxWidth: string | undefined;
  height: string | undefined;
  maxHeight: string | undefined;

  modalSize: 'small' | 'large' | 'fullScreen' | undefined;
}


export class GroupDialog<N extends TYPE_NAMES, M extends Modes, G extends QuestionGroup<N, FGCR>> extends GroupAdaptor<N, M, G> implements Complete<IModelDialog> {
  [ModelHostCounter]?: number;

  tabs?: any;

  setupComplete: EventEmitter<void> = new EventEmitter();


  constructor(
    public data: DataService,
    // public ui: UIService,
    // public cs: ConfirmationService,
    // public ms: MessageService,
    public table: N,
    public mode: M,
    /** a function that returns a fresh instance of the group */
    public pageSetupGroup: () => G,
    /** called to close the dialog */
    public onClose: () => Promise<void>,
    /** called by pageSetup - calls onLoadHook and makes the server request */
    public onLoad: (this: GroupDialog<N, M, G>) => Promise<boolean>,
    /** entry point for saving. calls onSaveHook and makes the server request */
    public onSaveValue: (this: GroupDialog<N, M, G>, value: any) => Promise<void>,
    /** entry point for deleting - USER IS NOT PROMPTED FIRST */
    public onDelete?: (this: GroupDialog<N, M, G>) => Promise<boolean>,
  ) {
    super();
    this.showOkCancel = true;
    this.showDelete = false;
    this.okLabel = "Ok";
    this.cancelLabel = this.showOkCancel ? "Cancel" : "Ok";
    this.deleteLabel = "Delete";
  }

  title: string | undefined;
  okLabel: string | undefined;
  cancelLabel: string | undefined;
  showOkCancel: boolean;
  deleteLabel: string | undefined;
  showDelete: boolean;
  width: string | undefined;
  maxWidth: string | undefined;
  height: string | undefined;
  maxHeight: string | undefined;
  modalSize: 'small' | 'large' | 'fullScreen' | undefined;

  onSaveError(error: any): void {

  }

}
export abstract class GroupDataAdaptor<T extends TABLE_NAMES, M extends MemberKeys<ConfirmType>, G extends QuestionGroup<T, FGCR>> extends GroupAdaptor<T, M, G> {

  constructor() {
    super();
  }

  id: string | null;
  queryParams?: Params;

  #fresh: string | undefined;
  /** Returns a parsed stringified copy of the value from the server. */
  get fresh(): G["form"]["value"] | undefined { return this.#fresh === undefined ? undefined : JSON.parse(this.#fresh); }
  set fresh(v: G["form"]["value"] | undefined) { this.#fresh = v === undefined ? undefined : JSON.stringify(v); }
  hasFresh(): this is { get fresh(): G["form"]["value"] } { return this.#fresh !== undefined; }
  /** Uses useMemo to parse this.fresh only when it changes. */
  useFresh() {
    return useMemo(() => this.fresh, [this.#fresh]);
  }

  private buildErrorTree(acc: any, item: AbstractControl, val?: any) {
    if (item.valid) return;
    if (item instanceof FormGroup) {
      Object.entries(item.controls).forEach(([k, item]) => {
        if (item.valid) return;
        if (item instanceof FormGroup) {
          acc[k] = {};
          this.buildErrorTree(acc[k], item);
        } else if (item instanceof FormControl) {
          acc[k] = val ?? item.errors;
        } else {
          throw new Error("Unhandled AbstractControl: " + item.constructor.name);
        }
      })
    }
  }



  async onLoad(): Promise<boolean> {
    okNull(this.group);

    const data = new DataQueryGraph(this.table, this.id ?? undefined, this.data.userRole);

    this.group.onLoadHook(data);

    return this.loadFormData(data);

  }


  async loadFormData(data: DataQueryGraph) {
    okNull(this.group);
    if (this.mode === "UPDATE" && this.id) {
      const fresh = await this.data.dataGraphQuery(data, "requests",
        this.data.queryFormData(this.table, this.id, this.group)).catch(this.catchError);
      if (!fresh) return false;
      this.fresh = fresh;
      this.group.form.patchValue(this.fresh);
    } else {
      this.fresh = this.group.form.value;
      await this.data.dataGraphQuery(data, "requests");
      if (this.queryParams) this.group.form.patchValue(this.queryParams as any);
    }
    return true;
  }

  onSaveError(error: any) {
    console.log(error);
    this.group?.form.markAllAsTouched();
    throw error;
    
    if (error.total === 1) {
      alert(`There is 1 field with errors:\n${error.keys.join("\n")}`);
    } else if (!error.total || !error.data) {
      alert(`An unknown error occurred during validation.`);
      console.error(error);
      // eslint-disable-next-line no-debugger
      debugger;
      return;
    } else {
      alert(`There are ${error.total} fields with errors:\n${error.keys.join("\n")}`);
    }


  }

  async onSaveValue(value: any): Promise<void> {

    const graph = new DataQueryGraph(this.table, this.id ?? undefined, this.data.userRole);

    const isUpdate = this.mode === "UPDATE";

    const data = await this.data.dataGraphQuery(graph, "transact", isUpdate ? {
      table: this.table,
      action: "update",
      arg: {
        data: value,
        where: { id: this.id },
        select: { id: true },
      }
    } : {
      table: this.table,
      action: "create",
      arg: {
        data: value,
        select: { id: true },
      }
    }).catch((e) => {
      console.debug(e);
      if (Array.isArray(e?.errors)) e.errors.forEach((e: any) => console.log(e.message));
      globalMessage.add({ severity: 'error', summary: "Failed", detail: `` });
      throw false;
    });

    this.group?.form.markAsPristine();

    globalMessage.add({ severity: 'success', summary: `${this.table} Saved`, detail: `${this.table} ${this.mode.toLowerCase()}d!` });

    this.onSaveSuccess.emit(data);

  }

  async onDelete(): Promise<boolean> {
    console.log(this.group);
    if (!this.group) return false;
    if (this.mode !== "UPDATE") return false;
    if (!schema.tables[this.table]) return false;
    if (this.table === "Rental" || this.table === "Customer") return false;
    return false;
  }
}



export class GroupDataDialog<T extends TABLE_NAMES, M extends Modes, G extends QuestionGroup<T, FGCR>> extends GroupDataAdaptor<T, M, G> implements Complete<IModelDialog> {
  constructor(
    public data: DataService,
    // protected ui: UIService,
    // public cs: ConfirmationService,
    // public ms: MessageService,
    public table: T,
    public mode: M,
    public pageSetupGroup: () => G,
    public onClose: () => Promise<void>,
  ) {
    super();
    this.showOkCancel = true;
    this.showDelete = false;
    this.okLabel = "Ok";
    this.cancelLabel = "Cancel";
    this.deleteLabel = "Delete";
  }
  title: string | undefined;
  okLabel: string | undefined;
  cancelLabel: string | undefined;
  deleteLabel: string | undefined;
  showDelete: boolean;
  showOkCancel: boolean;
  width: string | undefined;
  maxWidth: string | undefined;
  height: string | undefined;
  maxHeight: string | undefined;
  modalSize: 'small' | 'large' | 'fullScreen' | undefined;
  tabs: any;

  setupComplete: EventEmitter<void> = new EventEmitter();



}

function capitalize<T extends string>(table: T): Capitalize<T> {
  return table.slice(0, 1).toUpperCase() + table.slice(1) as any;
}




export class DataPage<
  T extends TABLE_NAMES = TABLE_NAMES,
  M extends MemberKeys<ConfirmType> = Modes,
  G extends QuestionGroup<T, FGCR> = QuestionGroup<T, FGCR>
>
  extends GroupDataAdaptor<T, M, G> {
  override data: DataService;
  override tabs?: any;
  override setupComplete: EventEmitter<void> = new EventEmitter();

  shakeDirty: () => void = () => { this.shakeDirtyTrigger.emit(); };
  shakeDirtyTrigger = new EventEmitter<void>();

  get isDirty() { return this.group?.form.dirty; }

  ButtonAwait = (props: ButtonAwaitPageProps) => ButtonAwaitPage(this, props);

  PageControl = ({ control }: { control: FGCV }) => {
    return renderQuestionItem(control, this.mode, !this.group?.noHiddenAnimation);
  }
  /** Renders the function as a child component only when the page is loaded. Allows full use of React hooks. */
  WhenLoaded = ({ children }: { children: () => JSX.Element }) => {
    return renderIf(this.group && (this.mode !== "UPDATE" || this.fresh) && !this.loading, children);
  }

  constructor(
    public injector: Injector,
    public table: T,
    public mode: M,
    id: string,
    public pageSetupGroup: (this: DataPage) => G,
    public onClose: () => Promise<void>
  ) {
    super();

    this.data = injector.get(DataService);
    this.id = id;

  }
}


export type SchemaReturnType<T extends keyof UI_Schema> = UI_Schema[T] extends (mode: Modes) => (infer G extends QuestionGroup<any, any>) ? G : never;
export type DataPageSchemaFunc<T extends TABLE_NAMES, M extends Modes> = `${T}${M}` extends keyof UI_Schema ? SchemaReturnType<`${T}${M}`> : T extends keyof UI_Schema ? SchemaReturnType<T> : never;
export function useDataPage<T extends TABLE_NAMES, M extends Modes>(table: T, mode: M, id: string): DataPage<T, M, DataPageSchemaFunc<T, M>>;
export function useDataPage<T extends TABLE_NAMES, M extends Modes, G extends QuestionGroup<T, FGCR>>(table: T, mode: M, id: string, form: (mode: M) => G): DataPage<T, M, G>;
export function useDataPage<T extends TABLE_NAMES, M extends Modes, F extends keyof UI_Schema>(table: T, mode: M, id: string, form: F): DataPage<T, M, SchemaReturnType<F>>;
export function useDataPage(table: TABLE_NAMES, mode: Modes, id: string, form?: keyof UI_Schema | ((mode: Modes) => QuestionGroup<any, any>)) {
  const { get, injector } = useAngular();
  const ui = get(UIService);

  useWatchMemo(form);

  const page = useMemo(
    () => new DataPage(
      injector, table, mode, id,
      typeof form === "function" ? () => form(mode) : () => {
        const schema = ui.schema as any;
        if (form) {
          if (schema[form]) return schema[form](mode);
          else { debugger; throw new Error("Invalid form name."); }
        }
        if (schema[table + mode]) return schema[table + mode](mode);
        if (schema[table]) return schema[table](mode);
        return Generator.Group(table, mode, ui);
      }, async () => { }),
    [ui, injector, table, mode, form, id]
  );

  useLayoutEffect(() => {
    page.pageSetup(true);
    return () => { page.subs.unsubscribe(); };
  }, [page]);

  useObservable(page.loadingChange);

  return page;
}
