
import { asCustomer } from './flags';
import { Fragment, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './app';
import { Authenticator } from '@aws-amplify/ui-react';
import { NgContextProvider, rootInjector } from 'react-utils';

const AuthProviderOrAsCustomer = asCustomer ? Fragment : Authenticator.Provider;

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <AuthProviderOrAsCustomer>
      <NgContextProvider injector={rootInjector}>
        <App />
      </NgContextProvider>
    </AuthProviderOrAsCustomer>
  </StrictMode>,
)



// import React from "react";
// import ReactDOM from "react-dom/client";
// import { AuthProvider, AuthProviderProps, useAuth } from "react-oidc-context";


// const cognitoAuthConfig = (redirect_path: string): AuthProviderProps => ({
//   authority: "https://cognito-idp.us-east-2.amazonaws.com/us-east-2_7Gjce0SlN",
//   client_id: "q7n4s8af6b7thfcur95r3vjot",
//   redirect_uri: "https://account.desktop:4201/",
//   response_type: "code",
//   scope: "phone openid email aws.cognito.signin.user.admin",
//   onSigninCallback: (user) => { console.log(user); },

// });

// const root = ReactDOM.createRoot(document.getElementById("root")!);

// // wrap the application with AuthProvider
// root.render(
//   <React.StrictMode>
//     <AuthProvider {...cognitoAuthConfig("signin1")}>
//       <Test />
//     </AuthProvider>
//   </React.StrictMode>
// );

// function Test() {

//   const auth = useAuth();
//   console.log(auth);
//   const signOutRedirect = () => {
//     const clientId = auth.settings.client_id;
//     const logoutUri = "https://account.desktop:4201/logout";
//     const cognitoDomain = "https://usvlnusaklnfvhdjlsjvgmkskahngildsjgpd.auth.us-east-2.amazoncognito.com";
//     window.location.href = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(logoutUri)}`;
//   };

//   if (auth.isLoading) {
//     return <div>Loading...</div>;
//   }

//   if (auth.error) {
//     return <div>Encountering error... {auth.error.message}</div>;
//   }

//   if (auth.isAuthenticated) {
//     return (
//       <div>
//         <pre> Hello: {auth.user?.profile.email} </pre>
//         <pre> ID Token: {auth.user?.id_token} </pre>
//         <pre> Access Token: {auth.user?.access_token} </pre>
//         <pre> Refresh Token: {auth.user?.refresh_token} </pre>
//         <pre> Is Federated: {(auth.user?.profile?.identities as any[] | undefined)?.length}</pre>

//         <button onClick={() => auth.removeUser()}>Sign out</button>
//       </div>
//     );
//   }

//   return (
//     <div>
//       <button onClick={() => auth.signinRedirect()}>Sign in</button>
//       <button onClick={() => signOutRedirect()}>Sign out</button>
//     </div>
//   );

// }