import { EventEmitter, Injector } from "react-utils";
import { AuthSession, fetchAuthSession } from "@aws-amplify/auth";
import { AuthenticatorRoute } from "@aws-amplify/ui";
import { distinctUntilChanged, switchMap } from "rxjs";
import { createContext } from "react";
import { asCustomer } from "../flags";
export type AuthStatus = 'configuring' | 'authenticated' | 'unauthenticated';
export const SignoutContext = createContext(() => {
  sessionStorage.clear();
  location.href = location.origin;
});
const hubauthevents = new EventEmitter<{
  event: "signedIn",
  sub: string,
  email?: string,
  federated?: boolean,
} | {
  event: "signedOut",
}>();
if (!asCustomer) {

  // Hub.listen('auth', (data) => {
  //   console.log("Hub.auth", data);
  //   if (data.payload.event === "signedOut") {
  //     hubauthevents.emit({ event: "signedOut" });
  //   } else if (data.payload.event === "signedIn") {
  //     const authUser = data.payload.data;
  //     const signInDetails = is<AuthUser>(authUser, "signInDetails" in authUser) && authUser.signInDetails || undefined;
  //     const email = signInDetails?.loginId;
  //     const sub = authUser.userId;
  //     // in the case of a federated sign in, the username is a special identifier issued by the identity provider,
  //     // otherwise it is the same as the userSub
  //     const username = authUser.username;
  //     hubauthevents.emit({ event: "signedIn", sub, email, federated: !signInDetails });

  //   }
  // });
  // hubauthevents.subscribe(async e => {
  //   if (e.event === "signedOut") return;
  //   const user = await fetchUserAttributes();
  //   console.log("user", user);
  // });
  // fetchAuthSession({}).then(e => { console.log(e); });
}
export class AuthService {
  hubauthevents = hubauthevents;

  constructor(injector: Injector) {
    if (asCustomer) return;

    this.authChange.pipe(
      distinctUntilChanged(),
      switchMap(async (e) => {
        if (e !== "authenticated") return false;
        if (asCustomer) return false;
        const session = await fetchAuthSession({});
        console.log("session", session);
        return !!session.tokens?.accessToken?.payload.scope?.includes("aws.cognito.signin.user.admin");
      })
    ).subscribe(e => {
      this.canChangePassword = e;
    });

  }

  userId: string | undefined;
  authStatus: AuthStatus = "configuring";
  route: AuthenticatorRoute = "signIn";
  canChangePassword = false;

  authChange = new EventEmitter<AuthStatus>();

  // authChange;

  setStatus(status: AuthStatus, route: AuthenticatorRoute, userId: string | undefined) {
    this.authStatus = status;
    this.route = route;
    this.userId = userId;
    this.authChange.emit(status);
  }

  /**
   * Fetch the auth session including the tokens and credentials if they are available. By default it
   * does not refresh the auth tokens or credentials if they are loaded in storage already. You can force a refresh
   * with `{ forceRefresh: true }` input.
   *
   * @param options - Options configuring the fetch behavior.
   * @throws {@link AuthError} - Throws error when session information cannot be refreshed.
   * @returns Promise<AuthSession>
   */
  async getAuthToken() {

    const branchToken = sessionStorage.getItem("x-cubes-branch-token");
    if (branchToken && asCustomer) return branchToken;

    const session = await fetchAuthSession({}) as MySession;
    return session.tokens.idToken.toString();
  }

}

interface MySession extends AuthSession {
  "tokens": {
    "accessToken": {
      "payload": {
        /** AWS ID (sub/username) */
        "sub": string,
        "iss": string,
        /** Client ID */
        "client_id": string,
        "origin_jti": string,
        /** Event ID */
        "event_id": string,
        "token_use": "access",
        "scope": "aws.cognito.signin.user.admin",
        "auth_time": number,
        "exp": number,
        "iat": number,
        "jti": string,
        /** AWS ID (sub/username) */
        "username": string,
      }
      toString(): string;
    },
    "idToken": {
      "payload": {
        /** AWS ID (sub/username) */
        "sub": string,
        "email_verified": boolean,
        "iss": string,
        /** AWS ID (sub/username) */
        "cognito:username": string,
        "origin_jti": string,
        /** Client ID */
        "aud": string,
        /** Event ID */
        "event_id": string,
        "token_use": "id",
        "auth_time": number,
        "exp": number,
        "iat": number,
        "jti": string,
        /** User's email address */
        "email": string
      }
      toString(): string;
    },
    "signInDetails": {
      /** User's email address */
      "loginId": string,
      "authFlowType": "USER_SRP_AUTH"
    }
  },
  "userSub": "AWSID"
}
