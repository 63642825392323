if (process.env.SERVERSIDE) { Error.stackTraceLimit = Infinity; } else { Error.stackTraceLimit = 30; }
// this sets the execution order of modules. Each on will complete before the next one starts.
import "reflect-metadata";
import "./globals";
import "./graphql-declarator";
import { RootEnums2, RootScals2, RootTypes2 } from "./cubes-schema";
import { DataSchema } from "./cubes-schema-classes";

import { GraphRoot, RecordType, SyncCheck, truthy } from "./graphql-declarator";
import { CreateSQL, sqlPermissions } from "./cubes-prisma";
import { Attributes, Customer, field, indexPrisma, model, TABLE_NAMES } from "./cubes-schema";

export const root = new GraphRoot(new RootScals2, new RootEnums2, new RootTypes2, {});
export type Root = typeof root;
if (!root.registry.get(Customer)) throw "Registry isn't working right";
root.build();
export const schema: DataSchema = DataSchema.fromGQL(root);

export * from "./cubes-utils";
export { Group, GroupChild } from "./cubes-schema-helpers";

export { SyncCheck };
export { IsArray } from "./graphql-declarator";
export { Member } from "./cubes-schema-helpers";
export const PermissionName = (action: "read" | "write", table: TABLE_NAMES, level: "table" | "groups" | "rows", remote: TABLE_NAMES) =>
  `${action} in ${table} for ${level} of ${remote}`;

import { FieldClass } from "./cubes-schema-classes";


export * from "./cubes-schema";
export * from "./cubes-schema-classes";

import { createJoinQueryTest } from "./createJoinQuery";

type proto<T> = { prototype: T };
type imp = typeof import("./cubes-schema");
export type StaticTypes = { [K in keyof imp as imp[K] extends proto<RecordType> ? K : never]: imp[K] };

// import { newTables, newRecords, FieldDefinition, TableDefinition, fieldChecks } from "./data";

export const fieldChecks = {
  hasPayments: ["PaymentDetails", "PaymentInfoValid", "PaymentInfoFlags"],
  isPaymentInfo: [
    "CardInfo",
    "GatewayResponses",
    "PaymentVaultID",
    "PaymentValidateTxnID",
    "PasswordResetCode",
    "StripeAccountID"
  ],
  hasContactInfo: ["Name", "Address", "Phone", "Fax", "Notes"],
  hasStorageAgreement: [
    "StorageAgreementEnvelopeID",
    "StorageAgreementIsTesting",
    "StorageAgreementCompleted",
    "StorageAgreementStatus",
  ],
  isTable: ["id", "createdAt", "updatedAt", "extra"],
}

if (process.env.NEWSCHEMA) {
  writeNewSchemaBuilder2();
} else if (process.env.SERVERSIDE) {

  const { writeFileSync } = require("fs") as typeof import("fs");
  const { join } = require("path");
  console.log("writing files");

  const schemas = [
    { path: "/home/cubes/server/datamath/prisma", output: false, kysely: false },
    { path: "/home/cubes/server/datamath", output: "./node_modules/prisma-client", kysely: "./kysely/" },
    { path: "/home/cubes/server/do-app", output: "./client", kysely: false },
  ];

  schemas.forEach(({ path, output, kysely }) => {
    writeFileSync(path + "/schema.prisma", CreateSQL(root, output, kysely));
    writeFileSync(path + "/privelages.sql", sqlPermissions(root, "cubeswebdev2"));
  });

  // writeFileSync("/home/cubes/server/datamath/dataschema.json", JSON.stringify(schema, null, 2));

  // writeFileSync("/home/cubes/server/datamath/schema-builder/printCubes-attributes.ts", printCubes_fulltypes());

  writeFileSync("/home/cubes/server/datamath/prisma/privelages.sql", sqlPermissions(root, "cubeswebdev2"));
  writeFileSync("/home/cubes/server/datamath/prisma-live/privelages-update.sql", sqlPermissions(root, "cubeswebapp3"));

  writeFileSync("/home/cubes/server/datamath/prisma-schemas.sh", [
    "set -x # print each command as it is executed",
    "set -e # exit if any line ends with a non-zero exit status",
    "set -u # exit script if a variable is uninitialized",
    schemas.map(e => `(cd ${e.path} && npx prisma format${e.output ? " && npx prisma generate" : ""})\n`).join("")
  ].join("\n"))


} else {
  console.log("NOT SERVERSIDE");

  createJoinQueryTest();
}


interface AttributesExtended extends Attributes {
  classComposite?: Attributes["fieldComposite"];
  mapsToTable?: Attributes["mapsTo"];
  hasContactInfo?: [{ email?: boolean }];
  hasStorageAgreement?: [{}];
  // I might actually use this to create the isPaymentInfo table
  hasPaymentDetails?: [{}];
  isPaymentInfo?: [{}];
  isLedger?: [{}];
  isTable?: [{}];
}
function writeNewSchemaBuilder2() {

  const OPTIMIZE = true;
  const j = JSON.stringify;
  const { createWriteStream, readFileSync } = require("fs");
  const { Console } = require("console");
  const newSchema = createWriteStream("/home/cubes/server/datamath/schema-new/new-data.ts", { encoding: "utf8" });
  const writer = new Console({ stdout: newSchema, stderr: process.stderr });
  // writer.log(readFileSync("/home/cubes/server/datamath/schema-builder/new-header.ts", { encoding: "utf8" }));
  writer.log(`import "reflect-metadata";\nimport { attrs, BaseType, EnumType, LedgerType, ModelType, RecordType, ScalarType, TableType } from "./new-header";\n`)
  const modelAttrs = new Set();
  const fieldAttrs = new Set();
  const printAttributes = (attributes: AttributesExtended, dir: "model" | "field") => {
    const pad = dir === "model" ? "" : "  ";
    if (dir === "model") {
      attributes = {
        ...attributes,
        // filter it here so the tuple typing works
        classComposite: attributes.fieldComposite?.filter(e => e.name !== "__TableDirectives"),
        fieldComposite: undefined,
        mapsToTable: attributes.mapsTo,
        mapsTo: undefined,
      };
    }
    Object.entries(attributes).forEach((attr) => {
      const [key, value] = attr;
      if (!value?.length) return;
      // the table security is set for each table separately, we don't need to do it here. 
      if (["privOpts", "authPostgres", "rls", "rowlevelsecurity"].includes(key)) return;
      // these are not used in the schema
      if (["model"].includes(key)) return;
      if (key === "hasMany") {
        value.forEach((e) => {
          delete e.detailType;
          delete e.fields;
          delete e.indexName;
        });
      }
      if (key === "hasOne") {
        value.forEach((e) => {
          delete e.relationName;
          delete e.fields; // literally set to remote
          if (e.updateVerb) throw new Error("updateVerb set");
        });
      }
      if (key === "belongsTo") {
        value.forEach((e) => {
          delete e.isRelation;
          //@ts-ignore
          if (e.root) e.scalarName = e.root;
          delete e.root;
          delete e.onDelete;
          delete e.onUpdate;
        })
      }

      if (dir === "model") modelAttrs.add(key);
      if (dir === "field") fieldAttrs.add(key);

      value?.filter(e => key !== "belongsTo" || Object.keys(e).length).forEach((e: any) => {
        if (key === "belongsTo") console.log(e);
        if (!e) console.log(`Found a falsy value in ${key} ${dir}`);
        writer.log(`${pad}@attrs.${key}(${["field", "forms"].includes(key) ? j(e, null, 2).split("\n").map(e => pad + e).join("\n").trim() : j(e)})`);
      });
    });
    attributes.belongsTo = attributes.belongsTo?.filter(e => Object.keys(e).length);
  };

  Object.entries(schema.scalars).forEach(([name, s]) => {
    // printAttributes(s.attributes, "model");
    writer.log(`export class ${name} extends ScalarType { $type = "${name}" as const; }`);
  });
  Object.entries(schema.enums).forEach(([name, e]) => {
    // printAttributes(e.attributes, "model");
    writer.log(`export class ${name} extends EnumType {`);
    writer.log(`  $type = "${name}" as const;`);
    e.optionsArray.forEach((option) => {
      //@ts-ignore
      option.attributes.enum = option.attributes.field;
      delete option.attributes.field;
      printAttributes(option.attributes, "field");
      writer.log(`  ${option.value} = this.addEnum();`);
    });
    writer.log("}");
  });
  Object.entries(schema.tables).forEach(([name, model]) => {
    model.fieldsArray.forEach((field) => {
      field.attributes.belongsTo?.forEach((e) => {
        delete e.isRelation;
        //@ts-ignore
        if (e.root) e.scalarName = e.root;
        delete e.root;
        delete e.onDelete;
        delete e.onUpdate;
      });
      field.attributes.belongsTo = field.attributes.belongsTo?.filter(e => Object.keys(e).length);
    });
  });
  Object.entries(schema.tables).forEach(([name, model]) => {
    model.fieldsArray.forEach((field) => {
      checkFieldRelations(field);
    });
  });
  Object.entries(schema.tables).forEach(([name, model]) => {
    model.fieldsArray.forEach((field) => {
      field.attributes.belongsTo?.forEach((e) => {
        delete e.onDelete;
        delete e.onUpdate;
      });
      field.attributes.belongsTo = field.attributes.belongsTo?.filter(e => Object.keys(e).length);
    });
    if (model.name === "Transaction") {
      model.fieldsArray.forEach((field) => {
        //onUpdate: "Restrict", onDelete: "Cascade"
        field.attributes.hasMany?.forEach((e) => {
          // @ts-ignore
          e.onDelete = "Cascade";
          // @ts-ignore
          e.onUpdate = "Restrict";
        });
        field.attributes.hasOne?.forEach((e) => {
          // @ts-ignore
          e.onDelete = "Cascade";
          // @ts-ignore
          e.onUpdate = "Restrict";
        });
      });
    }
  });
  Object.entries(schema.tables).forEach(([name, model]) => {
    model.fieldsArray.forEach((field) => {
      if (field.name.endsWith("GroupChild")) {
        //@ts-ignore
        model.attributes.hasGroup = [{}];
      }
    });
  });
  const skips = new Set();
  [...Object.entries(schema.records), ...Object.entries(schema.tables)].forEach(([tableName, model]) => {

    const { isTable, hasContactInfo, isPaymentInfo, hasPayments: hasPaymentDetails, hasStorageAgreement } = doFieldChecks(new Map(Object.entries(model.fields)));
    const isGroup = tableName.endsWith("Group");
    const isGroupChild = tableName.endsWith("GroupChild");
    if (isGroup || isGroupChild) return;
    const isLedger = tableName.endsWith("Ledger");
    if (tableName.endsWith("PaymentInfo")) return;

    if (model.type === "table" && !isTable)
      throw new Error("table without table fields");
    if (isLedger && !model.attributes.ledger?.length)
      throw new Error("ledger table without ledger attributes");
    if (isTable)
      (model.attributes as AttributesExtended).isTable = [{}];
    if (isPaymentInfo)
      (model.attributes as AttributesExtended).isPaymentInfo = [{}];
    if (hasContactInfo)
      (model.attributes as AttributesExtended).hasContactInfo = [{}];
    if (hasPaymentDetails)
      (model.attributes as AttributesExtended).hasPaymentDetails = [{}];
    if (hasStorageAgreement)
      (model.attributes as AttributesExtended).hasStorageAgreement = [{}];
    if (model.type === "table")
      printAttributes(model.attributes, "model");
    const modelType = isLedger ? "LedgerType" : model.type === "table" ? "TableType" : "RecordType";
    writer.log(`export class ${tableName} extends ${modelType} {`);
    writer.log(`  $type = "${tableName}" as const;`);
    writer.log();
    model.fieldsArray.forEach((field) => {

      const fieldName = field.key;
      if (OPTIMIZE && model.type === "table" && fieldChecks.isTable.includes(fieldName)) return;
      if (OPTIMIZE && isPaymentInfo && fieldChecks.isPaymentInfo.includes(fieldName)) return;
      if (OPTIMIZE && hasContactInfo && fieldChecks.hasContactInfo.includes(fieldName)) return;
      if (OPTIMIZE && hasPaymentDetails && fieldChecks.hasPayments.includes(fieldName)) return;
      if (OPTIMIZE && hasStorageAgreement && fieldChecks.hasStorageAgreement.includes(fieldName)) return;
      if (OPTIMIZE && isLedger && ["line", "Amount"].includes(fieldName)) return;
      if (OPTIMIZE && field.name.endsWith("GroupChild")) return;
      if (field.attributes.field?.some(e => e.clientSideOnly)) return;

      printAttributes(field.attributes, "field");
      if (fieldName !== field.key) throw new Error("key mismatch " + fieldName + " " + field.key);
      const modifier = field.isArray ? "array" : field.isRequired ? "required" : "optional";
      const fieldOptions: string[] = [];
      if (Object.keys(field.fieldOptions).length) {
        if (field.isName("ScalarDate") && field.fieldOptions.format === "yyyy-MM-dd") { }
        else if (field.isName("ScalarDateTime") && field.fieldOptions.format === "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") { }
        else fieldOptions.push(j(field.fieldOptions));
      }
      writer.log(`  ${fieldName} = this.addField(${[
        field.name,
        j(modifier),
        ...fieldOptions,
      ].join(", ")})`);
      writer.log();
    });
    writer.log("}");
    writer.log();
  });
  writer.log();
  fieldAttrs.forEach(attr => {
    writer.log(`// export const ${attr} = makeFieldDecorator("${attr}");`);
  });
  writer.log()
  modelAttrs.forEach(attr => {
    writer.log(`// export const ${attr} = makeClassDecorator("${attr}");`);
  });
  writer.log();
  // we need this so we can set attrs before the instance members (if that works)
  // writer.log(`export class RootBase {}`);
  writer.log(`export class RootScals {`)
  Object.entries(schema.scalars).forEach(([name, s]) => {
    writer.log(`  ${name} = new ${name}();`);
  });
  writer.log("}");
  writer.log();
  writer.log(`export class RootEnums {`)
  Object.entries(schema.enums).forEach(([name, e]) => {
    writer.log(`  ${name} = new ${name}();`);
  });
  writer.log("}");
  writer.log();
  writer.log(`export class RootRecords {`)
  Object.entries(schema.records).forEach(([name, model]) => {
    writer.log(`  ${name} = new ${name}();`);
  });
  writer.log("}");
  writer.log();
  writer.log(`export class RootTables {`)
  Object.entries(schema.tables).forEach(([name, model]) => {
    const isGroup = name.endsWith("Group");
    const isGroupChild = name.endsWith("GroupChild");
    if (isGroup || isGroupChild) return;
    if (name.endsWith("PaymentInfo")) return;
    writer.log(`  ${name} = new ${name}();`);
  });
  writer.log("}");
  writer.log();
  writer.log(`export const scalars = new RootScals();`);
  writer.log(`export const enums = new RootEnums();`);
  writer.log(`export const records = new RootRecords();`);
  writer.log(`export const tables = new RootTables();`);
  writer.log();
  writer.log();
  newSchema.end();
}


export function doFieldChecks(fields: Map<string, unknown>): { [K in keyof typeof fieldChecks]: boolean } {
  return Object.entries(fieldChecks).reduce((acc, [k, v]) => {
    acc[k] = v.every((f) => fields.has(f));
    return acc;
  }, {} as any);
}

function checkFieldRelations(field: FieldClass) {

  field.attributes.hasMany?.forEach((attr) => {
    if (!field.isType("table"))
      throw `hasMany attribute can only be used on tables ${field.key}`
    if (!field.isArray)
      throw `hasMany attribute can only be used on array fields ${field.key}`;

    const name = `Query${field.name}_${attr.remote}`;

    checkChildBelongsTo(field, attr, false);

  });
  field.attributes.hasOne?.forEach((attr) => {
    if (!field.isType("table"))
      throw `hasOne attribute can only be used on tables\n${field.key}`;
    if (field.isArray)
      throw `hasOne attribute cannot be used on array fields\n${field.key}`

    const name = `Query${field.name}_${attr.remote}`;

    checkChildBelongsTo(field, attr, true);
  });
}

function checkChildBelongsTo(field: FieldClass, attr: { remote?: string; }, hasOne: boolean) {
  if (!attr.remote) throw `Remote field not defined\n${field.key}`;
  const remoteTable = schema.tables[field.name];
  if (!remoteTable)
    throw `Remote table ${field.name} not found\n${field.key}`;
  const remoteField = remoteTable.fields[attr.remote];
  if (!remoteField)
    throw `Remote field ${attr.remote} not found\n${field.key}`;

  // if (field.modifier !== "array") 
  if (remoteField.attributes.belongsToRoot && remoteField.attributes.belongsToRoot.length > 1) {
    console.log(remoteField.attributes.belongsToRoot);
    throw `Remote field ${attr.remote} has multiple belongsToRoot attributes\n${remoteField.key}`;
  }
  if (remoteField.attributes.belongsTo && remoteField.attributes.belongsTo.length > 1) {
    console.log(remoteField.attributes.belongsTo);
    throw `Remote field ${attr.remote} has multiple belongsTo attributes\n${remoteField.key}`;
  }

  if (remoteField.attributes.belongsToRoot?.length) {

    remoteField.attributes.belongsToRoot.forEach((attr) => {
      if (!attr.rootFor) throw `RootFor attribute not defined\n${remoteField.key}`;
      const remoteField2 = remoteTable.fields[attr.rootFor];
      if (!remoteField2) throw `Remote field ${attr.rootFor} not found\n${remoteField.key}`;
      if (!remoteField2.attributes.belongsTo?.length) {
        // console.log(`Remote field ${attr.rootFor} has no belongsTo attribute\n${remoteField2.key}`);
        // remoteField2.attributes.belongsTo?.push({});
      } else {
        const belongsTo = remoteField2.attributes.belongsTo[0]
        belongsTo.parentFieldName = field.key;
        if (belongsTo.onDelete !== "Restrict") {
          //@ts-ignore
          if (hasOne) field.attributes.hasOne[0].onDelete = belongsTo.onDelete;
          //@ts-ignore
          else field.attributes.hasMany[0].onDelete = belongsTo.onDelete;
        }
        if (belongsTo.onUpdate !== "Restrict") {
          //@ts-ignore
          if (hasOne) field.attributes.hasOne[0].onUpdate = belongsTo.onUpdate;
          //@ts-ignore
          else field.attributes.hasMany[0].onUpdate = belongsTo.onUpdate;
        }

      }
    });

  } else if (remoteField.isType("scalar")) {
    throw `Remote field ${attr.remote} is a scalar field\n${remoteField.key} without belongsToRoot attribute\n${remoteField.key}`;
  } else if (!remoteField.attributes.belongsTo?.length) {
    // console.log(`Remote field ${attr.remote} has no belongsTo attribute\n${remoteField.key}`);
    // remoteField.attributes.belongsTo?.push({});
  } else {
    remoteField.attributes.belongsTo[0].parentFieldName = field.key;
  }
}