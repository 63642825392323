import { BlockStack, Box, Button, ButtonGroup, Labelled, Listbox, Spinner } from "@shopify/polaris";
import React, { PropsWithChildren, useCallback, useContext, useId, useMemo, useState } from "react";
import { SelectCustom, SelectCustomProps } from "./SelectCustom";
import styles from '@shopify/polaris/components/Select/Select.scss';
import textstyles from "@shopify/polaris/components/Listbox/components/TextOption/TextOption.scss";
import { is, ok } from "common";
import { useComboBoxState } from "./TextFieldDropdown";

interface StrictOption {
  /** Machine value of the option; this is the value passed to `onChange` */
  value: string;
  /** Human-readable text for the option */
  label: string | React.JSX.Element;
  /** Option will be visible, but not selectable */
  disabled?: boolean;
  /** Element to display to the left of the option label. Does not show in the dropdown. */
  prefix?: React.ReactNode;
  /** Unique key applied to the option element. Defaults to option value prop when undefined. */
  key?: string;
}

interface HideableStrictOption extends StrictOption {
  hidden?: boolean;
}

interface StrictGroup {
  /** Title for the group */
  title: string;
  /** List of options */
  options: StrictOption[];
}

export type SelectOption = string | StrictOption;

export interface SelectGroup {
  title: string;
  options: SelectOption[];
}

export interface SelectProps extends Omit<SelectCustomProps, "open" | "focus" | "setState"> {
  /** List of options or option groups to choose from */
  options: (SelectOption | SelectGroup)[];
  /** Example text to display as placeholder */
  placeholder?: string;
  /** Display a loading notice in the dropdown */
  optionsLoading?: boolean;
  loading?: boolean;
  allowClear?: boolean;
}


const EMPTY_ARRAY: never[] = [];


export function SelectButtons({
  options,
  label,
  value,
  onChange,
  allowClear,
  error,
  labelAction,
  labelHidden: labelHiddenProp,
  labelInline,
  helpText,
  requiredIndicator,
  disabled,
  id = useId(),
}: SelectProps) {
  let normalizedOptions: (HideableStrictOption | StrictGroup)[] = useMemo(() => {
    const opts = options.map(normalizeOption)
    if (allowClear) opts.unshift({ value: "", label: "None selected", });
    return opts;
  }, [options, allowClear]);

  return (
    <Labelled
      id={id}
      label={label}
      error={error}
      action={labelAction}
      labelHidden={labelInline ? true : labelHiddenProp}
      helpText={helpText}
      requiredIndicator={requiredIndicator}
      disabled={disabled}
    >
      <ButtonGroup>
        {normalizedOptions.map((opt, i) => is<StrictGroup>(opt, "options" in opt) ? (
          <ButtonGroup >
            {opt.options.map((opt, i) => (
              <Button
                key={i}
                disabled={opt.disabled}
                onClick={() => onChange?.(opt.value)}
                variant={value === opt.value ? "primary" : "secondary"}

              >{opt.label as string}</Button>
            ))}
          </ButtonGroup>
        ) : (
          <Button
            key={i}
            disabled={opt.disabled}
            onClick={() => onChange?.(opt.value)}
            variant={value === opt.value ? "primary" : "secondary"}
          >{opt.label as string}</Button>
        ))}
      </ButtonGroup>
    </Labelled>
  )
}

export function SelectDropdown({
  options,
  optionsLoading,
  placeholder,
  loading,
  value,
  allowClear,
  onChange,
  onFocus: onFocusProp,
  onBlur: onBlurProp,
  ...rest
}: SelectProps) {

  let normalizedOptions: (HideableStrictOption | StrictGroup)[] = useMemo(() => {
    const opts = options.map(normalizeOption)
    if (allowClear) opts.unshift({ value: "", label: "None selected", });
    return opts;
  }, [options, allowClear]);

  const { focus, open, onFocus: onFocusInner, onBlur: onBlurInner, setState } = useComboBoxState(onBlurProp);

  const onSelect = useCallback((value: string) => {
    setState({ open: false, focus: false });
    onChange?.(value);
  }, [onChange, setState]);

  const onFocus = useCallback(() => {
    console.log("onFocus");
    onFocusProp?.();
    onFocusInner();
  }, [onFocusProp, onFocusInner]);

  const onBlur = useCallback(() => {
    console.log("onBlur");
    onBlurInner();
  }, [onBlurProp, onBlurInner]);

  const loader = <Spinner accessibilityLabel="Loading field options" size="small" />;

  return (
    <SelectCustom
      selectedMarkup={(
        <SelectDropdown.Selected options={normalizedOptions} selected={value} onSelect={onSelect}>
          {loading ? loader : placeholder}
        </SelectDropdown.Selected>
      )}
      optionsMarkup={(
        <SelectDropdown.Dropdown options={normalizedOptions} selected={value} onSelect={onSelect}>
          <Box paddingBlock="200">
            <Listbox >
              <Listbox.Option key="" value="" disabled={true} selected={false}>
                Nothing found
              </Listbox.Option>
            </Listbox>
          </Box>
        </SelectDropdown.Dropdown>
      )}
      {...{ open, focus, setState, onFocus, onBlur, options, placeholder, ...rest }}
    />
  );
}
type ChildCompProps = PropsWithChildren<{
  options: (HideableStrictOption | StrictGroup)[];
  selected: string | undefined;
  onSelect?: (value: string) => void;
}>;
/** displays children if no option is selected */
SelectDropdown.Selected = function ({ options, selected, children }: ChildCompProps) {
  // const { selected } = useContext(SelectCustom.SelectedContext);

  const selectedOption = flattenOptions(options).find((option) => selected === option.value);

  if (!selectedOption) return <>{children}</>;

  return <>
    {selectedOption.prefix && (
      <div className={styles.Prefix}>{selectedOption.prefix}</div>
    )}
    <span className={styles.SelectedOption}>{selectedOption.label}</span>
  </>;

}
/** displays children if options is empty */
SelectDropdown.Dropdown = function ({ options, selected, onSelect, children }: ChildCompProps) {
  // const { selected, onSelect } = useContext(SelectCustom.SelectedContext);
  ok(onSelect);
  if (!options.length) return <>{children}</>

  const renderSingleOption = (option: HideableStrictOption) => {
    const { value, label, prefix: _prefix, key, disabled, hidden } = option;
    return hidden ? null : (
      <Listbox.Option key={key ?? value} value={value} disabled={disabled} selected={value === selected} >
        <div className={textstyles.TextOption}><div className={textstyles.Content}>{label}</div></div>
      </Listbox.Option>
    );
  };

  return (
    <Box paddingBlock="100">
      <Listbox onSelect={onSelect} >
        <BlockStack gap="200">
          {options.map(e => isGroup(e) ? (
            <Listbox.Section key={e.title} title={e.title} divider>
              {e.options.map(renderSingleOption)}
            </Listbox.Section>
          ) : renderSingleOption(e))}
        </BlockStack>
      </Listbox>
    </Box>
  );
}


/**
 * Ungroups an options array
 */
function flattenOptions(
  options: (HideableStrictOption | StrictGroup)[],
): HideableStrictOption[] {
  let flatOptions: HideableStrictOption[] = [];

  options.forEach((optionOrGroup) => {
    if (isGroup(optionOrGroup)) {
      flatOptions = flatOptions.concat(optionOrGroup.options);
    } else {
      flatOptions.push(optionOrGroup);
    }
  });

  return flatOptions;
}




function isGroup(option: SelectOption | SelectGroup): option is SelectGroup {
  return (
    typeof option === 'object' && 'options' in option && option.options != null
  );
}


/**
 * Converts a string option (and each string option in a Group) into
 * an Option object.
 */
export function normalizeOption(
  option: SelectOption | SelectGroup,
): HideableStrictOption | StrictGroup {
  if (isString(option)) {
    return normalizeStringOption(option);
  } else if (isGroup(option)) {
    const { title, options } = option;
    return {
      title,
      options: options.map((option) => {
        return isString(option) ? normalizeStringOption(option) : option;
      }),
    };
  }

  return option;

  function isString(option: SelectOption | SelectGroup): option is string {
    return typeof option === 'string';
  }

  function normalizeStringOption(option: string): StrictOption {
    return {
      label: option,
      value: option,
    };
  }
}
