import { FGCR, FGCV, QuestionBase, QuestionGroup, QuestionGroupButton } from '../utils';
import React, { PropsWithChildren } from 'react';
import { MemberKeys, ConfirmType, Modes } from 'common';
import { QuestionItem } from './QuestionItem';
import { ButtonAwait, useObservable } from 'react-utils';
import { Banner, BlockStack, Box, ButtonGroup, Card, Collapsible, Divider, FormLayout, InlineStack, Text } from '@shopify/polaris';

export function QuestionForm<C extends FGCR>({ title = "", extra = {}, group, mode, subpanel, children }: PropsWithChildren<{
  title?: string | undefined;
  group: QuestionGroup<any, C>;
  mode: MemberKeys<ConfirmType>;
  // subform?: string | null | undefined;
  extra?: Record<string, QuestionBase<any, any>>;
  subpanel?: boolean;
}>) {
  
  useObservable(group.form.valueChanges, group.form.value);

  return <Card>
    <BlockStack>
      {title ? <Box paddingBlockEnd="300">
        <Text as="h2" variant="headingMd">{title}</Text>
      </Box> : null}
      <ButtonGroup>{group.buttons.map((b, index) =>
        renderGroupButton(index, b, group.form.dirty)
      )}</ButtonGroup>
      {renderGroupControls<C>(group, mode)}
      {children}
    </BlockStack>
  </Card>

}

export function renderGroupButton(index: number, b: QuestionGroupButton, dirty: boolean) {
  return <ButtonAwait
    key={index}
    disabled={b.onlyClean && dirty || false}
    onClick={async () => await b.onClick()}
    icon={b.icon}
  >{b.title}</ButtonAwait>;
}

export function renderGroupControls<C extends FGCR>(group: QuestionGroup<any, C>, mode: Modes) {
  return Object.entries(group.controls)
    .sort((a, b) => a[1].order - b[1].order)
    .map(([key, q]) => renderQuestionItem(q, mode, !group.noHiddenAnimation));
}

export function renderQuestionItem<V extends FGCV>(q: V, mode: Modes, animateHidden: boolean) {
  return <QuestionItem
    animateHidden={animateHidden}
    key={q.key + q.controlType}
    q={q} mode={mode}
    _fieldClass=""

  />;
}

